import React, { useState } from "react";
import { Button, Image, Tag } from "antd";
import { getTagColor } from "../../../recommend/RecommendationList";
import InspectionImageEditModal from "../modals/InspectionImageEditModal";

interface ProfileDisplayProps {
  representationImages: string[];
  profileImage: string[];
  videos: string[];
  videoThumbnails: string[];
  appearanceRating: string;
  userId: string;
  editImages: string[];
  handleResultChange: (
    userId: string,
    result: "확인 전" | "요청 승인" | "요청 반려"
  ) => void;
}

// 등급 변경 x 사진만 수정하는 컴포넌트
const ProfileInspectionImageUpdate: React.FC<ProfileDisplayProps> = ({
  representationImages,
  profileImage,
  videos,
  videoThumbnails,
  appearanceRating,
  userId,
  editImages,
  handleResultChange,
}) => {
  // 모달의 표시 상태를 관리하는 state를 추가합니다
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewAllClick = () => {
    setIsModalOpen(true); // 모달을 열어줍니다
  };

  // 모달 닫기 핸들러를 정의합니다
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // 전체 레이아웃과 스타일을 정의합니다
  const styles = {
    ratingTag: {
      display: "flex",
      padding: "4px 8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      fontSize: "14px",
    },
  };

  return (
    <div className="profile-display" style={{ position: "relative" }}>
      {/* 이미지 섹션 */}
      <Image
        src={
          representationImages.length > 0
            ? representationImages[0]
            : profileImage[0]
        }
        height={200}
        width={200}
        style={{
          objectFit: "cover",
          display: "block", // 이미지 하단의 여백을 제거합니다
        }}
        preview={{
          width: "auto",
          height: "auto",
          mask: <div>클릭하여 크게보기</div>,
        }}
      />

      {/* 버튼 컨테이너를 이미지 위에 위치시킵니다 */}
      <div
        style={{
          position: "absolute", // 절대 위치를 사용합니다
          bottom: "20px", // 이미지 하단에서의 거리를 설정합니다
          left: "0",
          right: "0",
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          padding: "0 10px", // 좌우 여백을 추가합니다
        }}
      >
        <Button type="primary" onClick={handleViewAllClick}>
          수정 보기
        </Button>

        <Tag
          color={getTagColor(appearanceRating, "APPEARANCE_RATING")}
          style={styles.ratingTag}
        >
          외모 {appearanceRating}
        </Tag>

        <InspectionImageEditModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          representationImages={representationImages}
          images={profileImage}
          editImages={editImages}
          videos={videos}
          videoThumbnails={videoThumbnails}
          userId={userId}
          handleResultChange={handleResultChange}
        />
      </div>
    </div>
  );
};

export default ProfileInspectionImageUpdate;
