import React, {useState} from 'react';
import {Button} from 'antd';
import {InspectionBadgeDisplayModal} from "./modals/InspectionBadgeDisplayModal";

// 메인 컴포넌트의 props 타입 정의
interface InspectionStatusProps {
    label: string;
    inspectionId: string | null;
    inspectionImages: string[] | null;
    onApprove?: () => void;
    onReject?: () => void;
}

const InspectionLink: React.FC<InspectionStatusProps> = ({
                                                             label,
                                                             inspectionId,
                                                             inspectionImages,
                                                             onApprove,
                                                             onReject
                                                         }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <>
            {inspectionId && (
                <Button
                    type="link"
                    style={{
                        padding: 0,
                        height: 'auto',
                        minWidth: '28px'
                    }}
                    onClick={() => setIsModalOpen(true)}
                >
                    인증 보기
                </Button>
            )}


            <InspectionBadgeDisplayModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onApprove={onApprove}
                onReject={onReject}
                label={label}
                inspectionImages={inspectionImages}
            />
        </>
    );
};

export default InspectionLink;