import { Pagination } from "../pagination";
import apiClient from "../apiClient";

export interface ImageInspectionUsers {
  id: string;
  name: string;
  gender: "남자" | "여자";
  age: number;
  height: number;
  partnerHeightRange: String[];
  partnerUnderAge: number;
  partnerUpperAge: number;
  userProfileImages: string[];
  userRepresentativeImages: string[];
  appearanceRating: string;
  overallSocialRating: string;
  careerDetail: string;
  careerSubGroup: string;
  careerType: string;
  univRating: string;
  univName: string;
  preferenceType: string;
  income: number;
  religion: string;
  smokingStatus: string;
  drinkingStatus: string;
  mbti: string;
  appealPoint: string;
  partnerSmokingStatus: string;
  partnerDrinkingStatus: string;
  partnerReligion: string[];
  userVideos: string[];
  userVideoThumbnails: string[];
  appearanceInspectionId: string;
  appearanceInspectionBasicImages: string[];
  appearanceInspectionRepresentativeImages: string[];
  appearanceInspectionStatus: string;
  careerInspectionId: string;
  careerInspectionImages: string[];
  careerInspectionStatus: string;
  univInspectionId: string;
  univInspectionImages: string[];
  univInspectionStatus: string;
  incomeInspectionId: string;
  incomeInspectionImages: string[];
  incomeInspectionStatus: string;
  requestTime: string;
}

interface GetImageInspectionUsersParameter {
  gender?: string;
  name?: string;
  page: number;
  size: number;
  overallSocialRating?: string;
  appearanceRating?: string;
  univRating?: string;
  preferenceType?: string;
  userId?: string;
}

export interface GetImageInspectionUsersResponse {
  status: string;
  data: {
    pagination: Pagination;
    contents: ImageInspectionUsers[];
  };
}

export const getImageInspectionUsers = async (
  params: GetImageInspectionUsersParameter
): Promise<GetImageInspectionUsersResponse> => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const response = await apiClient.get<GetImageInspectionUsersResponse>(
    `/v1/admin/inspections/images`,
    {
      params: filteredParams,
    }
  );

  return response.data;
};

export interface UpdateImageInspectionMypageUserBody {
  representationImageUrl: string[];
  newImages: string[];
  newVideos: string[];
  newVideoThumbnails: string[];
}

interface UpdateImageInspectionMypageUserResponse {
  status: string;
  data: {
    message: string;
  };
}
export const updateImageInspectionMypageUser = async (
  userId: string,
  body: UpdateImageInspectionMypageUserBody
) => {
  const response =
    await apiClient.patch<UpdateImageInspectionMypageUserResponse>(
      `/v1/admin/inspections/users/${userId}/images/mypage`,
      body
    );

  return response.data;
};

export const rejectImageInspectionUser = async (userId: string) => {
  const response = await apiClient.patch(
    `/v1/admin/inspections/users/${userId}/images/mypage/reject`
  );

  return response.data;
};
