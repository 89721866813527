import React, {PropsWithChildren} from "react";
import {Layout} from "antd";

const {Content, Footer} = Layout;


const AdminLayout = ({children}: PropsWithChildren) => {
    return (
        <Layout className="layout">
            <Content>
                <div className="site-layout-content" style={{background: "white"}}>
                    {children}
                </div>
            </Content>
            <Footer style={{textAlign: "center"}}>주선왕 ©2024</Footer>
        </Layout>
    );
};

export default AdminLayout;
