import { EventManager } from "./EventManager";

export enum ModalEvent {
  OPEN = "OPEN_MODAL",
  CLOSE = "CLOSE_MODAL",
}

export interface RenderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ModalEventManager {
  on(
    event: ModalEvent.OPEN,
    listener: (
      id: string,
      renderModal: (props: RenderModalProps) => JSX.Element
    ) => void
  ): ModalEventManager;
  on(
    event: ModalEvent.CLOSE,
    callback: (id: string) => void
  ): ModalEventManager;

  emit(
    event: ModalEvent.OPEN,
    id: string,
    renderModal: (props: RenderModalProps) => JSX.Element
  ): void;
  emit(event: ModalEvent.CLOSE, id: string): void;

  off(event: ModalEvent.OPEN): ModalEventManager;
}
let modalId = 0;
const generateModalId = () => {
  return `modal-${modalId++}`;
};

export const modalEventManager: ModalEventManager = new EventManager();

export const openModal = (
  renderModal: (props: RenderModalProps) => JSX.Element
) => {
  const id = generateModalId();
  modalEventManager.emit(ModalEvent.OPEN, id, renderModal);
};

export const closeModal = (id: string) => {
  modalEventManager.emit(ModalEvent.CLOSE, id);
};
