import React, {useState} from 'react';
import {Button, Image, Tag} from 'antd';
import {getTagColor} from "../RecommendationList";
import ImageModal from "./ImageModal";

interface ProfileDisplayProps {
    representationImages: string[];
    profileImage: string[];
    videos: string[];
    videoThumbnails: string[];
    appearanceRating: string;
}

const ProfileDisplay: React.FC<ProfileDisplayProps> = ({
                                                           representationImages,
                                                           profileImage,
                                                           videos,
                                                           videoThumbnails,
                                                           appearanceRating
                                                       }) => {
    // 모달의 표시 상태를 관리하는 state를 추가합니다
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleViewAllClick = () => {
        setIsModalOpen(true);  // 모달을 열어줍니다
    };

    // 모달 닫기 핸들러를 정의합니다
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    // 전체 레이아웃과 스타일을 정의합니다
    const styles = {
        ratingTag: {
            display: 'flex',
            padding: '4px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            fontSize: '14px'
        }
    };

    return (
        <div className="profile-display"
             style={{position: 'relative'}}>
            {/* 이미지 섹션 */}
            <Image
                src={representationImages.length > 0 ? representationImages[0] : profileImage[0]}
                height={200}
                width={200}
                style={{
                    objectFit: 'cover',
                    display: 'block' // 이미지 하단의 여백을 제거합니다
                }}
                preview={{
                    width: 'auto',
                    height: 'auto',
                    mask: <div>클릭하여 크게보기</div>,
                }}
            />

            {/* 버튼 컨테이너를 이미지 위에 위치시킵니다 */}
            <div style={{
                position: 'absolute', // 절대 위치를 사용합니다
                bottom: '20px', // 이미지 하단에서의 거리를 설정합니다
                left: '0',
                right: '0',
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                padding: '0 10px' // 좌우 여백을 추가합니다
            }}>
                <Button
                    type="primary"
                    onClick={handleViewAllClick}
                >
                    모두 보기
                </Button>

                <Tag
                    color={getTagColor(appearanceRating, 'APPEARANCE_RATING')}
                    style={styles.ratingTag}
                >
                    외모 {appearanceRating}
                </Tag>

                <ImageModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    representationImages={representationImages}
                    images={profileImage}
                    videos={videos}
                    videoThumbnails={videoThumbnails}
                />
            </div>
        </div>
    );
};

export default ProfileDisplay;