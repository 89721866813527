// modals/InspectionModal.tsx 파일 수정
import React from "react";
import { Button, Image, Modal, Space } from "antd";

interface InspectionBadgeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove?: () => void;
  onReject?: () => void;
  label: string;
  inspectionImages: string[] | null;
}

export const InspectionBadgeProcessModal: React.FC<
  InspectionBadgeModalProps
> = ({ isOpen, onClose, onApprove, onReject, label, inspectionImages }) => {
  return (
    <Modal
      title={`${label} 인증 확인`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        // 닫기 버튼
        <Button key="close" onClick={onClose}>
          취소
        </Button>,
        // 반려 버튼
        onReject && (
          <Button
            key="reject"
            type="primary"
            danger
            onClick={() => {
              if (onReject) {
                onReject();
                onClose(); // 모달 닫기
              }
            }}
          >
            반려
          </Button>
        ),
        // 승인 버튼
        onApprove && (
          <Button
            key="approve"
            type="primary"
            onClick={() => {
              if (onApprove) {
                onApprove();
                onClose(); // 모달 닫기
              }
            }}
          >
            승인
          </Button>
        ),
      ]}
      width={800}
    >
      <div style={{ marginBottom: 16 }}>
        <h3>
          인증 정보를 승인할까요?
          <br /> * 심사 결과는 결과 발송 시 알림톡으로 발송됩니다.
        </h3>
      </div>
      <Space size={[8, 16]} wrap>
        {inspectionImages && inspectionImages.length > 0 ? (
          inspectionImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={imageUrl}
              alt={`${label} 인증 이미지 ${index + 1}`}
              width={`100%`}
              style={{ objectFit: "cover" }}
            />
          ))
        ) : (
          <div>인증 이미지가 없습니다.</div>
        )}
      </Space>
    </Modal>
  );
};
