// VideoPlayerModal.tsx
import React from 'react';
import {Modal} from 'antd';

interface VideoPlayerModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoUrl: string;
}

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               videoUrl
                                                           }) => {
    return (
        <Modal
            title={null}
            open={isOpen}
            footer={null}
            onCancel={onClose}
            width={800}
            centered
            destroyOnClose
        >
            <div style={{
                position: 'relative',
                paddingTop: '56.25%', // 16:9 비율
                width: '100%'
            }}>
                <video
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                    controls
                    autoPlay
                    src={videoUrl}
                />
            </div>
        </Modal>
    );
};

export default VideoPlayerModal;