import {useState} from "react";
import {Button, Card, Descriptions, Flex, Image, Radio, RadioChangeEvent, Typography,} from "antd";
import {ExposureStatus, putUnivInspection, Rating, UnivInjection,} from "../../../utils/api/user";
import styled from "@emotion/styled";
import {CheckCircleOutlined} from "@ant-design/icons";
import {useMutation, useQueryClient} from "@tanstack/react-query";

interface Props {
  univData: UnivInjection;
  openNotificationWithIcon: (name: string) => void;
  onChangeLoading: (loading: boolean) => void;
}

const UnivInspectionCard = ({
  univData,
  openNotificationWithIcon,
  onChangeLoading,
}: Props) => {
  const [univLevel, setUnivLevel] = useState<Rating | null>(null);
  const [exposure, setExposure] = useState<ExposureStatus | null>(null);
  const onChangeExposure = (e: RadioChangeEvent) => {
    setExposure(e.target.value);
  };

  const onChangeUnivLevel = (e: RadioChangeEvent) => {
    setUnivLevel(e.target.value);
  };
  const qc = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: () =>
      putUnivInspection({
        univInspectionId: univData.univInspectionId,
        univRating: univLevel ?? "FIFTH_GRADE",
        univNameExposureStatus: exposure as "VISIBLE" | "HIDDEN",
      }),
    onSuccess: () => {
      openNotificationWithIcon(univData.userProfileData.userProfileName);
      qc.invalidateQueries({ queryKey: ["univ"] });
      setTimeout(() => {
        onChangeLoading(false);
      }, 300);
    },
    onError: () => {
      setTimeout(() => {
        onChangeLoading(false);
      }, 300);
    },
    onMutate: () => {
      onChangeLoading(true);
    },
  });

  const convertGradeToKorean = (grade: string | null) => {
    switch (grade) {
      case null:
        return "";
      case "FIRST_GRADE":
        return "상";
      case "SECOND_GRADE":
        return "중상";
      case "THIRD_GRADE":
        return "중";
      case "FOURTH_GRADE":
        return "중하";
      case "FIFTH_GRADE":
        return "하";
      case "UN_RANK":
        return "등급제외";
      default:
        return "상";
    }
  };
  const {
    univName,
    userGender,
    userProfileData,
    proofImages,
    status,
    univCertificationStatus,
  } = univData;
  return (
    <Card>
      <Flex gap="middle">
        <Descriptions column={1}>
          <Descriptions.Item label="유저 id">
            {userProfileData.userProfileId}
          </Descriptions.Item>

          <Descriptions.Item label="이름">
            {userProfileData.userProfileName}
          </Descriptions.Item>
          <Descriptions.Item label="상태">{status}</Descriptions.Item>
          <Descriptions.Item label="성별">{userGender}</Descriptions.Item>
          <Descriptions.Item label="학교명">{univName}</Descriptions.Item>
          <Descriptions.Item label="학교 인증">
            {univCertificationStatus}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item>
            {proofImages.map((imgSrc) => (
              <Image src={imgSrc} width={300} height={300} />
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
      <Radio.Group
        size="large"
        buttonStyle="solid"
        onChange={onChangeUnivLevel}
        value={univLevel}
      >
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FIRST_GRADE"}
        >
          상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"SECOND_GRADE"}
        >
          중상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"THIRD_GRADE"}
        >
          중
        </Radio.Button>{" "}
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FOURTH_GRADE"}
        >
          중하
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FIFTH_GRADE"}
        >
          하
        </Radio.Button>
        <Radio.Button
          style={{
            width: 100,
            textAlign: "center",
          }}
          value={"UN_RANK"}
        >
          등급제외
        </Radio.Button>
      </Radio.Group>
      <Typography.Title level={5}>대학 노출 여부</Typography.Title>
      <Flex
        style={{
          marginTop: 20,
        }}
      >
        <Radio.Group onChange={onChangeExposure} value={exposure}>
          <Radio value={"VISIBLE"}>공개</Radio>
          <Radio value={"HIDDEN"}>비공개</Radio>
        </Radio.Group>
      </Flex>

      <SubmitContainer>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          disabled={univLevel === null || exposure === null}
          size="large"
          onClick={() => mutate()}
        >
          <b>{convertGradeToKorean(univLevel)}</b>(으)로 분류
        </Button>
      </SubmitContainer>
    </Card>
  );
};

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
`;

export default UnivInspectionCard;
