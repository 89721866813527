import {nonUserApiClient} from "./apiClient";

interface GoogleLoginResponse {
  accessToken: string;
}

interface GoogleLoginRequest {
  code: string;
  redirectUrl: string;
}

export const googleLoginAPI = async (
  googleLoginRequest: GoogleLoginRequest
) => {
  const response = await nonUserApiClient.post<GoogleLoginResponse>(
    `/v1/admin/google`,
    googleLoginRequest
  );
  return response.data;
};
