import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Modal, Select, Space, Table, Tooltip} from "antd";
import type {ColumnsType} from "antd/es/table";
import styled from "@emotion/styled";
import {useNavigate, useSearchParams} from "react-router-dom";
import RecommendationGlobalTableFilter from "../recommend/components/RecommendationGlobalTableFilter";
import {
    calculateColumnsTotalWidth,
    FILTER_OPTIONS,
    formatKoreanMoney,
    PreferenceOption,
} from "../recommend/RecommendationList";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    getSignUpInspectionUsers,
    GetSignUpInspectionUsersResponse,
    InspectionBadgeQuery,
    InspectionStatus,
    ProcessSignupInspectionParams,
    SignUpInspectionUsers,
    SignUpInspectionUsersQueryResult,
} from "../../utils/api/inspection/signUpUsersInspection";
import {ApiError} from "../../utils/api/apiError";
import apiClient from "../../utils/api/apiClient";
import ProfileInspectionRatingChangeDisplay from "./components/images/ProfileInspectionRatingChangeDisplay";
import SignUpApprovalModal from "./components/modals/SignUpApprovalModal";
import {SignUpInspectionModalState} from "../../utils/types/inspection/inspectionModal";
import SignUpRejectModal from "./components/modals/SignUpRejectModal";
import {InspectionBadgeStatusUpdate, InspectStatus,} from "./components/badge/InspectionBadgeStatusUpdate";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import InspectionStatusCheckUpModal from "./components/modals/InspectionStatusCheckUpModal";
import AdminLayout from "../../common/components/AdminLayout";

export type RatingType = "univ" | "appearance" | "overallSocial" | "income";

/**
 * 소득 숫자 값을 enum 문자열로 변환하는 함수
 * @param income 숫자 형태의 소득 (원 단위)
 * @returns INCOME enum의 label 문자열
 */
export const convertIncomeNumberToString = (income: number): string => {
    // 연 소득 단위로 변환 (필요한 경우)
    const yearlyIncome = income; // 이미 연 소득이면 그대로 사용
    if (yearlyIncome == null) return FILTER_OPTIONS.INCOME.BEFORE_RANK.label;
    if (yearlyIncome < 30000000) return FILTER_OPTIONS.INCOME.UNDER_30M.label;
    if (yearlyIncome < 40000000) return FILTER_OPTIONS.INCOME.OVER_30M.label;
    if (yearlyIncome < 50000000) return FILTER_OPTIONS.INCOME.OVER_40M.label;
    if (yearlyIncome < 60000000) return FILTER_OPTIONS.INCOME.OVER_50M.label;
    if (yearlyIncome < 70000000) return FILTER_OPTIONS.INCOME.OVER_60M.label;
    if (yearlyIncome < 80000000) return FILTER_OPTIONS.INCOME.OVER_70M.label;
    if (yearlyIncome < 90000000) return FILTER_OPTIONS.INCOME.OVER_80M.label;
    if (yearlyIncome < 100000000) return FILTER_OPTIONS.INCOME.OVER_90M.label;
    if (yearlyIncome < 200000000) return FILTER_OPTIONS.INCOME.OVER_1B.label;
    if (yearlyIncome < 300000000) return FILTER_OPTIONS.INCOME.OVER_2B.label;
    if (yearlyIncome < 500000000) return FILTER_OPTIONS.INCOME.OVER_3B.label;
    if (yearlyIncome < 1000000000) return FILTER_OPTIONS.INCOME.OVER_5B.label;

    return FILTER_OPTIONS.INCOME.OVER_10B.label;
};

const InspectionsSignUp: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();

    // URL에서 초기 필터 값을 가져오는 함수
    const getInitialFilter = useCallback(() => {
        return {
            gender: searchParams.get("gender") || "",
            name: searchParams.get("name") || "",
            page: parseInt(searchParams.get("page") || "0"),
            size: parseInt(searchParams.get("size") || "10"),
            overallSocialRating: searchParams.get("overallSocialRating") || "",
            appearanceRating: searchParams.get("appearanceRating") || "",
            univRating: searchParams.get("univRating") || "",
            preferenceType: searchParams.get("preferenceType") || "",
            userId: searchParams.get("userId") || "",
        };
    }, [searchParams]);

    const [filter, setFilter] = useState(getInitialFilter());

    // 필터 변경시 URL 업데이트
    const updateURLParameters = (newFilter: typeof filter) => {
        const params = new URLSearchParams();
        Object.entries(newFilter).forEach(([key, value]) => {
            if (value !== "") {
                params.set(key, value.toString());
            }
        });
        setSearchParams(params);
    };

    const handleFilterChange = (values: Partial<typeof filter>) => {
        const newFilter = {
            ...filter,
            ...values,
            page: 0, // 필터 변경 시 첫 페이지로 이동
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        const newFilter = {
            ...filter,
            page: page - 1,
            size: pageSize || filter.size,
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    // 가입 승인 모달의 상태를 관리합니다
    const [signUpApprovalModalState, setSignUpApprovalModalState] =
        useState<SignUpInspectionModalState>({
            isOpen: false,
            selectedUser: null,
        });

    // 모달을 여는 함수입니다
    const handleOpenApprovalModal = (userId: string, userName: string) => {
        setSignUpApprovalModalState({
            isOpen: true,
            selectedUser: {
                id: userId,
                name: userName,
            },
        });
    };

    // 모달을 닫는 함수입니다
    const handleCloseApprovalModal = () => {
        setSignUpApprovalModalState({
            isOpen: false,
            selectedUser: null,
        });
    };

    // 가입 거절 모달의 상태를 관리합니다
    const [signUpRejectModalState, setSignUpRejectModalState] =
        useState<SignUpInspectionModalState>({
            isOpen: false,
            selectedUser: null,
        });

    // 모달을 여는 함수입니다
    const handleOpenRejectModal = (userId: string, userName: string) => {
        setSignUpRejectModalState({
            isOpen: true,
            selectedUser: {
                id: userId,
                name: userName,
            },
        });
    };

    // 모달을 닫는 함수입니다
    const handleCloseRejectModal = () => {
        setSignUpRejectModalState({
            isOpen: false,
            selectedUser: null,
        });
    };

    // 인증 상태를 관리할 상태 객체 정의
    const [inspectionStatuses, setInspectionStatuses] = useState<
        Record<string, Record<string, string | null>>
    >({});

    // 인증 상태 변경 핸들러
    const handleInspectionStatusChange = (
        userId: string,
        inspectionType: string,
        newStatus: string
    ) => {
        setInspectionStatuses((prev) => ({
            ...prev,
            [userId]: {
                ...prev[userId],
                [inspectionType]: newStatus,
            },
        }));
    };

    // 모든 인증이 승인 또는 거절되었는지 확인하는 함수
    const checkAllInspectionsProcessed = (
        userId: string,
        record: SignUpInspectionUsers
    ) => {
        // 사용자의 인증 상태를 가져옴 (변경이 없다면 원래 레코드 값 사용)
        const userStatuses = inspectionStatuses[userId] || {};

        // 인증이 필요한 항목 개수 (인증 ID가 있는 경우만 해당)
        let requiredInspectionsCount = 0;
        let processedInspectionsCount = 0;

        // 경력 인증 확인
        if (record.careerInspectionId) {
            requiredInspectionsCount++;
            const careerStatus =
                userStatuses["career"] || record.careerInspectionStatus;
            if (
                careerStatus === InspectStatus.INSPECT_COMPLETE ||
                careerStatus === InspectStatus.REJECT ||
                (careerStatus === InspectStatus.BEFORE_INSPECT &&
                    record.careerInspectionImages?.length === 0)
            ) {
                processedInspectionsCount++;
            }
        }

        // 대학 인증 확인
        if (record.univInspectionId) {
            requiredInspectionsCount++;
            const univStatus = userStatuses["univ"] || record.univInspectionStatus;
            if (
                univStatus === InspectStatus.INSPECT_COMPLETE ||
                univStatus === InspectStatus.REJECT ||
                (univStatus === InspectStatus.BEFORE_INSPECT &&
                    record.univInspectionImages?.length === 0)
            ) {
                processedInspectionsCount++;
            }
        }

        // 소득 인증 확인
        if (record.incomeInspectionId) {
            requiredInspectionsCount++;
            const incomeStatus =
                userStatuses["income"] || record.incomeInspectionStatus;
            if (
                incomeStatus === InspectStatus.INSPECT_COMPLETE ||
                incomeStatus === InspectStatus.REJECT ||
                (incomeStatus === InspectStatus.BEFORE_INSPECT &&
                    record.incomeInspectionImages?.length === 0)
            ) {
                processedInspectionsCount++;
            }
        }


        // 필요한 인증이 있는 경우에만 모든 인증이 처리되었는지 확인
        return (
            requiredInspectionsCount === 0 ||
            requiredInspectionsCount === processedInspectionsCount
        );
    };

    // BEFORE_RANK 상태인 등급이 있는지 확인하는 함수
    const checkAllRatingsAssigned = (record: SignUpInspectionUsers) => {
        // 로컬 상태와 원본 데이터 모두 확인
        const userId = record.id;
        const localRatings = userRatings[userId] || {};

        // 각 등급 확인 (로컬 상태 우선, 없으면 원본 데이터 사용)
        const appearanceRating = localRatings.appearance || record.appearanceRating;
        const overallSocialRating =
            localRatings.overallSocial || record.overallSocialRating;
        const univRating = localRatings.univ || record.univRating;

        // BEFORE_RANK의 값들을 정의 (label과 value 모두 확인해야 함)
        const appearanceBeforeRanks = [
            FILTER_OPTIONS.APPEARANCE_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.APPEARANCE_RATING.BEFORE_RANK.label,
            "등급 분류 전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const overallSocialBeforeRanks = [
            FILTER_OPTIONS.OVERALL_SOCIAL_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.OVERALL_SOCIAL_RATING.BEFORE_RANK.label,
            "심사 이전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const univBeforeRanks = [
            FILTER_OPTIONS.UNIV_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.UNIV_RATING.BEFORE_RANK.label,
            "등급 분류 전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const incomeBeforeRanks = [
            FILTER_OPTIONS.INCOME.BEFORE_RANK.value,
            FILTER_OPTIONS.INCOME.BEFORE_RANK.label,
            "등급 분류 전"
        ];

        // 각 등급이 BEFORE_RANK가 아닌지 확인 (배열에 없으면 OK)
        const isAppearanceRated = !appearanceBeforeRanks.includes(appearanceRating);
        const isOverallSocialRated =
            !overallSocialBeforeRanks.includes(overallSocialRating);
        const isUnivRated = !univBeforeRanks.includes(univRating);

        // 소득 등급 확인
        let isIncomeRated = false;

        if (localRatings.income) {
            // 로컬 상태의 소득 등급이 있는 경우
            isIncomeRated = !incomeBeforeRanks.includes(localRatings.income);
        } else {
            // 원본 데이터를 사용하는 경우
            const incomeString = convertIncomeNumberToString(record.income);
            isIncomeRated = !incomeBeforeRanks.includes(incomeString);
        }

        // 모든 등급이 할당되어야 함 (모두 true여야 함)
        return (
            isAppearanceRated && isOverallSocialRated && isUnivRated && isIncomeRated
        );
    };

    // 유저 등급 변경 관리 상태
    const [userRatings, setUserRatings] = useState<
        Record<string, Record<string, string>>
    >({});

    // 상태 체크 모달을 위한 상태 정의
    const [statusCheckModalState, setStatusCheckModalState] = useState({
        isOpen: false,
        userId: "",
        userName: "",
        missingItems: {
            ratings: {
                appearance: false,
                overallSocial: false,
                univ: false,
                income: false,
            },
            inspections: {
                career: false,
                univ: false,
                income: false,
            },
        },
    });

    //  미설정된 등급 확인 함수
    const checkMissingRatings = (record: SignUpInspectionUsers) => {
        // checkAllRatingsAssigned 함수의 로직을 활용
        const userId = record.id;
        const localRatings = userRatings[userId] || {};

        // 각 등급 확인 (로컬 상태 우선, 없으면 원본 데이터 사용)
        const appearanceRating = localRatings.appearance || record.appearanceRating;
        const overallSocialRating =
            localRatings.overallSocial || record.overallSocialRating;
        const univRating = localRatings.univ || record.univRating;

        // BEFORE_RANK의 값들을 정의 (checkAllRatingsAssigned와 동일한 배열 사용)
        const appearanceBeforeRanks = [
            FILTER_OPTIONS.APPEARANCE_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.APPEARANCE_RATING.BEFORE_RANK.label,
            "등급 분류 전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const overallSocialBeforeRanks = [
            FILTER_OPTIONS.OVERALL_SOCIAL_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.OVERALL_SOCIAL_RATING.BEFORE_RANK.label,
            "심사 이전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const univBeforeRanks = [
            FILTER_OPTIONS.UNIV_RATING.BEFORE_RANK.value,
            FILTER_OPTIONS.UNIV_RATING.BEFORE_RANK.label,
            "등급 분류 전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        const incomeBeforeRanks = [
            FILTER_OPTIONS.INCOME.BEFORE_RANK.value,
            FILTER_OPTIONS.INCOME.BEFORE_RANK.label,
            "등급 분류 전", // 실제 데이터에 있는 텍스트가 이것인지 확인
        ];

        // checkAllRatingsAssigned에서는 !includes를 사용했으므로, 여기서는 includes를 사용
        const missingRatings = {
            appearance: appearanceBeforeRanks.includes(appearanceRating),
            overallSocial: overallSocialBeforeRanks.includes(overallSocialRating),
            univ: univBeforeRanks.includes(univRating),
            income: false,
        };

        // 소득 등급 확인
        if (localRatings.income) {
            // 로컬 상태의 소득 등급이 있는 경우
            missingRatings.income = incomeBeforeRanks.includes(localRatings.income);
        } else {
            // 원본 데이터를 사용하는 경우
            const incomeString = convertIncomeNumberToString(record.income);
            missingRatings.income = incomeBeforeRanks.includes(incomeString);
        }

        return missingRatings;
    };

    // 미처리된 인증 확인 함수
    const checkMissingInspections = (
        userId: string,
        record: SignUpInspectionUsers
    ) => {
        // 사용자의 인증 상태를 가져옴 (변경이 없다면 원래 레코드 값 사용)
        const userStatuses = inspectionStatuses[userId] || {};

        const missingInspections = {
            career: false,
            univ: false,
            income: false,
        };

        // 각 인증 유형이 존재하는 경우에만 확인 (inspectionId가 있는 경우)
        if (record.careerInspectionId) {
            const careerStatus =
                userStatuses["career"] || record.careerInspectionStatus;
            // 문자열 비교를 위해 String()으로 타입 변환 추가
            const status = String(careerStatus);
            const completeStatus = String(InspectStatus.INSPECT_COMPLETE);
            const rejectStatus = String(InspectStatus.REJECT);
            const beforeSubmitStatus = String(InspectStatus.BEFORE_INSPECT);

            // 더 정확한 비교를 위해 포함 관계 검사 추가
            const isProcessed =
                status === completeStatus ||
                status === rejectStatus ||
                (status === beforeSubmitStatus &&
                    record.careerInspectionImages?.length === 0) ||
                status.includes(completeStatus) ||
                status.includes(rejectStatus) ||
                (status.includes(beforeSubmitStatus) &&
                    record.careerInspectionImages?.length === 0);

            missingInspections.career = !isProcessed;
        }

        if (record.univInspectionId) {
            const univStatus = userStatuses["univ"] || record.univInspectionStatus;
            // 문자열 비교를 위해 String()으로 타입 변환 추가
            const status = String(univStatus);
            const completeStatus = String(InspectStatus.INSPECT_COMPLETE);
            const rejectStatus = String(InspectStatus.REJECT);
            const beforeSubmitStatus = String(InspectStatus.BEFORE_INSPECT);
            // 더 정확한 비교를 위해 포함 관계 검사 추가
            const isProcessed =
                status === completeStatus ||
                status === rejectStatus ||
                (status === beforeSubmitStatus &&
                    record.univInspectionImages?.length === 0) ||
                status.includes(completeStatus) ||
                status.includes(rejectStatus) ||
                (status.includes(beforeSubmitStatus) &&
                    record.univInspectionImages?.length === 0);

            missingInspections.univ = !isProcessed;
        }

        if (record.incomeInspectionId) {
            const incomeStatus =
                userStatuses["income"] || record.incomeInspectionStatus;
            // 문자열 비교를 위해 String()으로 타입 변환 추가
            const status = String(incomeStatus);
            const completeStatus = String(InspectStatus.INSPECT_COMPLETE);
            const rejectStatus = String(InspectStatus.REJECT);
            const beforeSubmitStatus = String(InspectStatus.BEFORE_INSPECT);
            // 더 정확한 비교를 위해 포함 관계 검사 추가
            const isProcessed =
                status === completeStatus ||
                status === rejectStatus ||
                (status === beforeSubmitStatus &&
                    record.incomeInspectionImages?.length === 0) ||
                status.includes(completeStatus) ||
                status.includes(rejectStatus) ||
                (status.includes(beforeSubmitStatus) &&
                    record.incomeInspectionImages?.length === 0);

            missingInspections.income = !isProcessed;
        }

        return missingInspections;
    };

    // 상태 체크 모달을 여는 함수
    const openStatusCheckModal = (
        userId: string,
        userName: string,
        record: SignUpInspectionUsers
    ) => {
        const missingRatings = checkMissingRatings(record);
        const missingInspections = checkMissingInspections(userId, record);

        setStatusCheckModalState({
            isOpen: true,
            userId,
            userName,
            missingItems: {
                ratings: missingRatings,
                inspections: missingInspections,
            },
        });
    };

    // 6. 상태 체크 모달을 닫는 함수
    const closeStatusCheckModal = () => {
        setStatusCheckModalState((prev) => ({
            ...prev,
            isOpen: false,
        }));
    };

    /**
     * 다양한 등급 타입에 대한 레이블을 반환하는 함수
     * @param value 등급 값(value 속성)
     * @param ratingType 등급 타입(옵션) - 지정하지 않으면 모든 등급 타입에서 검색
     * @returns 해당 등급 값에 대응하는 레이블
     */
    const getRatingLabel = (value: string, ratingType?: RatingType): string => {
        // 등급 타입이 지정된 경우 해당 등급에서만 검색
        if (ratingType) {
            switch (ratingType) {
                case "univ":
                    return (
                        Object.values(FILTER_OPTIONS.UNIV_RATING).find(
                            (rating) => rating.value === value
                        )?.label || ""
                    );
                case "income":
                    return (
                        Object.values(FILTER_OPTIONS.INCOME).find(
                            (rating) => rating.value === value
                        )?.label || ""
                    );
                case "appearance":
                    return (
                        Object.values(FILTER_OPTIONS.APPEARANCE_RATING).find(
                            (rating) => rating.value === value
                        )?.label || ""
                    );
                case "overallSocial":
                    return (
                        Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).find(
                            (rating) => rating.value === value
                        )?.label || ""
                    );
                default:
                    return "";
            }
        }

        // 등급 타입이 지정되지 않은 경우 모든 등급에서 검색
        // 연봉 등급 검색
        const incomeLabel = Object.values(FILTER_OPTIONS.INCOME).find(
            (rating) => rating.value === value
        )?.label;
        if (incomeLabel) return incomeLabel;

        // 대학 등급 검색
        const univLabel = Object.values(FILTER_OPTIONS.UNIV_RATING).find(
            (rating) => rating.value === value
        )?.label;
        if (univLabel) return univLabel;

        // 외모 등급 검색
        const appearanceLabel = Object.values(
            FILTER_OPTIONS.APPEARANCE_RATING
        ).find((rating) => rating.value === value)?.label;
        if (appearanceLabel) return appearanceLabel;

        // 능력 등급 검색
        const overallSocialLabel = Object.values(
            FILTER_OPTIONS.OVERALL_SOCIAL_RATING
        ).find((rating) => rating.value === value)?.label;
        if (overallSocialLabel) return overallSocialLabel;

        // 일치하는 값이 없으면 원래 값을 반환
        return value || "";
    };

    type RatingParams = {
        userId: string;
        appearanceRating?: string;
        overallSocialRating?: string;
        univRating?: string;
        income?: number;
    };

    // 유저 등급 변경 mutation -> [todo] 전체 심사 완료시에 호출하도록 변경
    const updateUserRatingsMutation = useMutation({
        mutationFn: (params: Omit<RatingParams, "userId"> & { userId: string }) => {
            // URL 파라미터를 구성합니다
            const queryParams = new URLSearchParams();

            // 존재하는 파라미터만 URL에 추가합니다
            if (params.appearanceRating) {
                queryParams.append("appearanceRating", params.appearanceRating);
            }
            if (params.overallSocialRating) {
                queryParams.append("overallSocialRating", params.overallSocialRating);
            }
            if (params.univRating) {
                queryParams.append("univRating", params.univRating);
            }
            if (params.income) {
                queryParams.append("income", params.income.toString());
            }

            // API 호출을 수행합니다
            return apiClient.patch(
                `/v1/admin/inspections/users/${params.userId}?${queryParams.toString()}`
            );
        },
        onSuccess: () => {
            // 성공 시 테이블 데이터를 리프레시합니다
            queryClient.invalidateQueries({queryKey: ["signup-inspections"]});
        },
        onError: (error) => {
            // 에러 처리를 수행합니다
            console.error("Rating update failed:", error);
        },
    });

    // 상태 타입 정의
    interface UpdateStateModalState {
        isOpen: boolean;
        selectedRecord: {
            id: string;
            name: string;
            currentValue: string;
            newValue: string;
            ratingType: RatingType;
        } | null;
    }

    // 등급 타입별 레이블을 정의하는 객체를 만듭니다
    const ratingTypeLabels: Record<RatingType, string> = {
        univ: "학력",
        appearance: "외모",
        overallSocial: "능력",
        income: "연봉",
    };

    // 등급 변경 핸들러를 수정합니다
    const handleRatingChange = (
        newValue: string,
        currentValue: string,
        recordId: string,
        recordName: string,
        ratingType: RatingType
    ) => {
        setUpdateStateModalState({
            isOpen: true,
            selectedRecord: {
                id: recordId,
                name: recordName,
                currentValue: currentValue,
                newValue: newValue,
                ratingType: ratingType,
            },
        });
    };

    const [updateStateModalState, setUpdateStateModalState] =
        useState<UpdateStateModalState>({
            isOpen: false,
            selectedRecord: null,
        });

    const handleReset = () => {
        const resetFilter = {
            gender: "",
            name: "",
            page: 0,
            size: 10,
            overallSocialRating: "",
            appearanceRating: "",
            univRating: "",
            preferenceType: "",
            userId: "",
        };
        setFilter(resetFilter);
        // URL 파라미터 초기화
        setSearchParams({});
    };

    // URL 변경 감지하여 필터 상태 업데이트
    useEffect(() => {
        const currentFilter = getInitialFilter();
        setFilter(currentFilter);
    }, [getInitialFilter]);

    // 쿼리 훅 정의
    const {data: useSignUpInspectionQueryResult} = useQuery<
        GetSignUpInspectionUsersResponse,
        ApiError,
        SignUpInspectionUsersQueryResult
    >({
        // 쿼리 키를 객체 형태로 구조화
        queryKey: ["signup-inspections", filter],
        queryFn: () =>
            getSignUpInspectionUsers({
                ...filter, // 객체로 전달
            }),
        // 데이터 변환
        select: (response) => ({
            contents: response.data.contents,
            pagination: response.data.pagination,
        }),
    });

    // 가입 승인/거절을 처리하는 mutation을 정의합니다
    const processSignupInspectionMutation = useMutation({
        mutationFn: async (params: ProcessSignupInspectionParams) => {
            // API 호출을 수행합니다
            return await apiClient.patch(
                `/v1/admin/inspections/signup/${params.userId}`,
                {
                    // 본문에 데이터를 포함시킵니다
                    signUpInspectStatus: params.inspectStatus,
                    rejectionReason: params.rejectionReason,
                    badgeQueries: params.badgeQueries || [] // 필요한 경우 배지 쿼리도 추가
                }
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["signup-inspections"]});
        },
    });

    /* 가입 승인을 처리하는 함수를 정의합니다
      1. (유저 등급 수정한게 있다면) 유저 등급 수정 API 호출 : updateUserRatingsMutation
      2. (유더 심사 뱃지 수정한게 있다면) 유저 심사 뱃지 정보 추가 후 가입 승인 API 호출 : updateBadgeMutation
       */
    // 가입 승인을 처리하는 함수
    const handleApproveSignUp = async (userId: string): Promise<boolean> => {
        try {
            // 1. 유저 등급 수정 (변경된 사항이 있는 경우)
            const userRatingChanges = userRatings[userId];
            if (userRatingChanges && Object.keys(userRatingChanges).length > 0) {
                // 파라미터 객체 초기화
                const ratingParams: RatingParams = {userId};

                // 각 등급 유형에 따라 파라미터 설정
                if (userRatingChanges.appearance) {
                    ratingParams.appearanceRating = userRatingChanges.appearance;
                }
                if (userRatingChanges.overallSocial) {
                    ratingParams.overallSocialRating = userRatingChanges.overallSocial;
                }
                if (userRatingChanges.univ) {
                    ratingParams.univRating = userRatingChanges.univ;
                }
                if (userRatingChanges.income) {
                    ratingParams.income = Number(userRatingChanges.income);
                }

                // 변경할 등급이 하나라도 있으면 API 호출
                if (Object.keys(ratingParams).length >= 1) {
                    // userId 외에 하나 이상 파라미터가 있으면
                    await updateUserRatingsMutation.mutateAsync(ratingParams);
                }
            }

            const userBadgeChanges = inspectionStatuses[userId];
            const badgeQueries: InspectionBadgeQuery[] = [];

            // 변경된 각 뱃지 유형에 대해 쿼리 생성
            if (userBadgeChanges && Object.keys(userBadgeChanges).length > 0) {
                // inspectStatus 값이 올바른 InspectionStatus 타입인지 확인하고 변환
                if (userBadgeChanges.career) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.career as InspectionStatus,
                        ratingType: "OVERALL_SOCIAL", // 서버 enum에 맞게 수정
                        rejectionReason: "",
                    });
                }
                if (userBadgeChanges.univ) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.univ as InspectionStatus,
                        ratingType: "UNIV",
                        rejectionReason: "",
                    });
                }
                if (userBadgeChanges.income) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.income as InspectionStatus,
                        ratingType: "INCOME",
                        rejectionReason: "",
                    });
                }
            }

            // 3. 가입 승인 API 호출 (뱃지 심사 쿼리와 함께)
            await processSignupInspectionMutation.mutateAsync({
                userId,
                inspectStatus: "INSPECT_COMPLETE",
                badgeQueries: badgeQueries
            });

            return true; // 모든 처리가 성공적으로 완료됨
        } catch (error) {
            console.error("가입 승인 처리 실패:", error);
            return false; // 실패 시 false를 반환
        }
    };

    /* 가입 거절을 처리하는 함수를 정의합니다
    1. (유저 등급 수정한게 있다면) 유저 등급 수정 API 호출 : updateUserRatingsMutation
    2. 유저 뱃지 심사 + 가입 승인/거절 동시 처리 API 호출 : processSignupInspectionMutation
    */
    const handleRejectSignUp = async (userId: string, rejectionReason: string = ""): Promise<boolean> => {
        try {
            // 1. 유저 등급 수정 (변경된 사항이 있는 경우)
            const userRatingChanges = userRatings[userId];
            if (userRatingChanges && Object.keys(userRatingChanges).length > 0) {
                // 파라미터 객체 초기화
                const ratingParams: RatingParams = {userId};

                // 각 등급 유형에 따라 파라미터 설정
                if (userRatingChanges.appearance) {
                    ratingParams.appearanceRating = userRatingChanges.appearance;
                }
                if (userRatingChanges.overallSocial) {
                    ratingParams.overallSocialRating = userRatingChanges.overallSocial;
                }
                if (userRatingChanges.univ) {
                    ratingParams.univRating = userRatingChanges.univ;
                }
                if (userRatingChanges.income) {
                    ratingParams.income = Number(userRatingChanges.income);
                }

                // 변경할 등급이 하나라도 있으면 API 호출
                if (Object.keys(ratingParams).length >= 1) {
                    // userId 외에 하나 이상 파라미터가 있으면
                    await updateUserRatingsMutation.mutateAsync(ratingParams);
                }
            }

            // 2. 유저 심사 뱃지 수정 (변경된 사항이 있는 경우)
            const userBadgeChanges = inspectionStatuses[userId];
            const badgeQueries: InspectionBadgeQuery[] = [];

            // 변경된 각 뱃지 유형에 대해 쿼리 생성
            if (userBadgeChanges && Object.keys(userBadgeChanges).length > 0) {
                // inspectStatus 값이 올바른 InspectionStatus 타입인지 확인하고 변환
                if (userBadgeChanges.career) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.career as InspectionStatus,
                        ratingType: "OVERALL_SOCIAL", // 서버 enum에 맞게 수정
                        rejectionReason: "",
                    });
                }
                if (userBadgeChanges.univ) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.univ as InspectionStatus,
                        ratingType: "UNIV",
                        rejectionReason: "",
                    });
                }
                if (userBadgeChanges.income) {
                    badgeQueries.push({
                        inspectStatus: userBadgeChanges.income as InspectionStatus,
                        ratingType: "INCOME",
                        rejectionReason: "",
                    });
                }
            }

            // 3. 가입 거절 API 호출 (뱃지 심사 쿼리와 함께)
            await processSignupInspectionMutation.mutateAsync({
                userId,
                inspectStatus: "REJECT",
                rejectionReason: rejectionReason,
                badgeQueries: badgeQueries
            });

            return true; // 모든 처리가 성공적으로 완료됨
        } catch (error) {
            console.error("가입 거절 처리 실패:", error);
            return false; // 실패 시 false를 반환
        }
    };

    const handleRowClick = (record: SignUpInspectionUsers) => {
        navigate(`/users/${record.id}`);
    };

    // 쿼리 결과가 업데이트되면 로컬 상태 초기화
    useEffect(() => {
        if (useSignUpInspectionQueryResult?.contents) {
            setUserRatings({});
            setInspectionStatuses({});
        }
    }, [useSignUpInspectionQueryResult?.contents]);

    const columns: ColumnsType<SignUpInspectionUsers> = [
        {
            title: "매력 정보/기본 정보",
            align: "center",
            children: [
                {
                    title: () => (
                        <div>
                            <TitleWrapper>프로필/외모</TitleWrapper>
                            <Space>
                                <Select
                                    style={{width: 80}}
                                    size="small"
                                    value={filter.appearanceRating}
                                    onChange={(value) =>
                                        handleFilterChange({appearanceRating: value})
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                                        ({value, label}) => (
                                            <Select.Option key={value} value={value}>
                                                {label}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: "profile",
                    width: 240,
                    align: "center",
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <ProfileInspectionRatingChangeDisplay
                                representationImages={record.userRepresentativeImages}
                                profileImage={record.userProfileImages}
                                videos={record.userVideos}
                                videoThumbnails={record.userVideoThumbnails}
                                appearanceRating={
                                    userRatings[record.id]?.appearance || record.appearanceRating
                                }
                                userId={record.id}
                                userName={record.name}
                                onRatingChange={handleRatingChange}
                            />
                        </div>
                    ),
                },
                {
                    title: () => (
                        <div>
                            <TitleWrapper>이름 / 성별 / 나이 / 키</TitleWrapper>
                            <Space>
                                <Select
                                    style={{width: 80}}
                                    size="small"
                                    value={filter.gender}
                                    onChange={(value) => handleFilterChange({gender: value})}
                                >
                                    {Object.values(FILTER_OPTIONS.GENDER).map(
                                        ({value, label}) => (
                                            <Select.Option key={value} value={value}>
                                                {label}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: "basic info",
                    width: 160,
                    align: "center",
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>
                                {record.name}({record.gender === "남자" ? "남" : "여"})
                            </div>
                            <div>{record.age}세</div>
                            <div>{record.height}cm</div>
                        </Space>
                    ),
                },
            ],
        },
        {
            title: "능력 정보",
            align: "center",
            children: [
                {
                    title: () => (
                        <div>
                            <TitleWrapper>직업</TitleWrapper>
                            <Space>
                                <Select
                                    placeholder="능력"
                                    size="small"
                                    style={{width: 80}}
                                    value={filter.overallSocialRating}
                                    onChange={(value) =>
                                        handleFilterChange({overallSocialRating: value})
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                                        ({value, label, description}: PreferenceOption) => (
                                            <Select.Option key={value} value={value}>
                                                <div className="select-option-content">
                                                    {description ? (
                                                        <Tooltip title={description} placement="right">
                                                            <div>{label}</div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div>{label}</div>
                                                    )}
                                                </div>
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: "career",
                    width: 160,
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Space direction="vertical">
                                <Select
                                    placeholder="능력"
                                    size="small"
                                    style={{width: 128}}
                                    value={
                                        userRatings[record.id]?.overallSocial ||
                                        record.overallSocialRating
                                    }
                                    onChange={(value) =>
                                        handleRatingChange(
                                            value,
                                            record.overallSocialRating,
                                            record.id,
                                            record.name,
                                            "overallSocial"
                                        )
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING)
                                        .filter(
                                            (value) =>
                                                value !== FILTER_OPTIONS.OVERALL_SOCIAL_RATING.ALL
                                        )
                                        .map(({value, label, description}: PreferenceOption) => (
                                            <Select.Option key={value} value={value}>
                                                <div className="select-option-content">
                                                    {description ? (
                                                        <Tooltip title={description} placement="right">
                                                            <div>{label}</div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div>{label}</div>
                                                    )}
                                                </div>
                                            </Select.Option>
                                        ))}
                                </Select>
                                <div>{record.careerDetail}</div>
                                <div>{record.careerSubGroup}</div>
                            </Space>
                        </div>
                    ),
                    align: "center",
                },
                {
                    title: () => (
                        <div>
                            <div>연봉</div>
                        </div>
                    ),
                    key: "income",
                    width: 160,
                    align: "center",
                    render: (_, record) => {
                        // 현재 income 값을 문자열로 변환합니다
                        const currentIncomeValue =
                            userRatings[record.id]?.income ||
                            convertIncomeNumberToString(record.income);

                        return (
                            <div onClick={(e) => e.stopPropagation()}>
                                <Space direction="vertical">
                                    <Select
                                        placeholder="연봉"
                                        size="small"
                                        style={{width: 160}}
                                        value={currentIncomeValue}
                                        onChange={(value) => {
                                            // value 값이 INCOME enum에서 온 것인지 확인하고 적절히 처리합니다
                                            // 중요: handleRatingChange에는 레이블이 아닌 value 값을 전달합니다
                                            handleRatingChange(
                                                value,
                                                currentIncomeValue,
                                                record.id,
                                                record.name,
                                                "income"
                                            );
                                        }}
                                    >
                                        {Object.values(FILTER_OPTIONS.INCOME)
                                            .filter(
                                                (value) =>
                                                    value !== FILTER_OPTIONS.INCOME.ALL
                                            )
                                            .map(
                                                ({value, label, description}: PreferenceOption) => (
                                                    <Select.Option key={value} value={value}>
                                                        <div className="select-option-content">
                                                            {description ? (
                                                                <Tooltip title={description} placement="right">
                                                                    <div>{label}</div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div>{label}</div>
                                                            )}
                                                        </div>
                                                    </Select.Option>
                                                )
                                            )}
                                    </Select>
                                    <div>
                                        {record.income != null
                                            ? formatKoreanMoney(record.income)
                                            : "-"}
                                    </div>
                                </Space>
                            </div>
                        );
                    },
                },
                {
                    title: () => (
                        <div>
                            <div>학력</div>
                            <Space>
                                <Select
                                    placeholder="학력"
                                    size="small"
                                    style={{width: 80}}
                                    value={filter.univRating}
                                    onChange={(value) =>
                                        handleFilterChange({univRating: value})
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                                        ({value, label, description}: PreferenceOption) => (
                                            <Select.Option key={value} value={value}>
                                                <div className="select-option-content">
                                                    {description ? (
                                                        <Tooltip title={description} placement="right">
                                                            <div>{label}</div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div>{label}</div>
                                                    )}
                                                </div>
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: "univ",
                    width: 160,
                    align: "center",
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Space direction="vertical">
                                <Select
                                    placeholder="학력"
                                    size="small"
                                    style={{width: 128}}
                                    value={userRatings[record.id]?.univ || record.univRating}
                                    onChange={(value) =>
                                        handleRatingChange(
                                            value,
                                            record.univRating,
                                            record.id,
                                            record.name,
                                            "univ"
                                        )
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.UNIV_RATING)
                                        .filter(
                                            (value) =>
                                                value !== FILTER_OPTIONS.UNIV_RATING.ALL
                                        )
                                        .map(({value, label, description}: PreferenceOption) => (
                                            <Select.Option key={value} value={value}>
                                                <div className="select-option-content">
                                                    {description ? (
                                                        <Tooltip title={description} placement="right">
                                                            <div>{label}</div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div>{label}</div>
                                                    )}
                                                </div>
                                            </Select.Option>
                                        ))}
                                </Select>
                                <div>{record.univName ?
                                        record.univName :
                                        (record.finalAcademic ? record.finalAcademic : "-")}</div>
                            </Space>
                        </div>
                    ),
                },
                {
                    title: () => (
                        <div>
                            <div>인증 정보</div>
                        </div>
                    ),
                    key: "career",
                    width: 216,
                    align: "center",
                    render: (_, record) => {
                        // 현재 사용자의 인증 뱃지 상태를 가져옴 (없으면 빈 객체)
                        const userStatuses = inspectionStatuses[record.id] || {};

                        return (
                            <div onClick={(e) => e.stopPropagation()}>
                                <Space direction="vertical">
                                    <InspectionBadgeStatusUpdate
                                        label="직업"
                                        inspectionId={record.careerInspectionId}
                                        status={
                                            userStatuses["career"] || record.careerInspectionStatus
                                        }
                                        inspectionImages={record.careerInspectionImages}
                                        onStatusChange={(newStatus) =>
                                            handleInspectionStatusChange(
                                                record.id,
                                                "career",
                                                newStatus
                                            )
                                        }
                                    />
                                    <InspectionBadgeStatusUpdate
                                        label="학력"
                                        inspectionId={record.univInspectionId}
                                        status={userStatuses["univ"] || record.univInspectionStatus}
                                        inspectionImages={record.univInspectionImages}
                                        onStatusChange={(newStatus) =>
                                            handleInspectionStatusChange(record.id, "univ", newStatus)
                                        }
                                    />
                                    <InspectionBadgeStatusUpdate
                                        label="연봉"
                                        inspectionId={record.incomeInspectionId}
                                        status={
                                            userStatuses["income"] || record.incomeInspectionStatus
                                        }
                                        inspectionImages={record.incomeInspectionImages}
                                        onStatusChange={(newStatus) =>
                                            handleInspectionStatusChange(
                                                record.id,
                                                "income",
                                                newStatus
                                            )
                                        }
                                    />
                                </Space>
                            </div>
                        );
                    },
                },
            ],
        },
        {
            title: "심사 정보",
            align: "center",
            children: [
                {
                    title: () => (
                        <div>
                            <div>심사 결과</div>
                        </div>
                    ),
                    key: "inspectionResult",
                    align: "center",
                    width: 160,
                    render: (_, record) => {
                        // 두 가지 조건 확인:
                        const isAllInspectionsProcessed = checkAllInspectionsProcessed(
                            record.id,
                            record
                        );
                        const isAllRatingsAssigned = checkAllRatingsAssigned(record);

                        // 두 조건이 모두 충족되어야 사용자를 승인/거절할 수 있음
                        const canProcessUser =
                            isAllInspectionsProcessed && isAllRatingsAssigned;


                        return (
                            <div onClick={(e) => e.stopPropagation()}>
                                <Space
                                    direction="vertical"
                                    style={{
                                        gap: "10px",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        style={
                                            {
                                                borderRadius: "5px",
                                                background: "#7AC142",
                                                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                                                color: "white",
                                            } as React.CSSProperties
                                        }
                                        onClick={() => {
                                            if (canProcessUser) {
                                                handleOpenApprovalModal(record.id, record.name);
                                            } else {
                                                // 상태 체크 모달 열기
                                                openStatusCheckModal(record.id, record.name, record);
                                            }
                                        }}
                                    >
                                        가입 승인
                                    </Button>

                                    <Button
                                        style={{
                                            borderRadius: "5px",
                                            background: "#E15B56",
                                            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            handleOpenRejectModal(record.id, record.name);
                                        }}
                                    >
                                        가입 거절
                                    </Button>
                                </Space>
                            </div>
                        );
                    },
                },
                {
                    title: () => (
                        <div>
                            <div>요청 일시</div>
                        </div>
                    ),
                    key: "inspectionResult",
                    align: "center",
                    width: 160,
                    render: (_, record) => {
                        const formatDateTime = (dateString: string) => {
                            try {
                                const date = new Date(dateString);

                                if (isNaN(date.getTime())) {
                                    return "-";
                                }

                                // 날짜와 시간을 별도로 포맷팅합니다
                                const dateStr = date
                                    .toLocaleDateString("ko-KR", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                    })
                                    .replace(/\. /g, "-")
                                    .slice(0, -1);

                                const timeStr = date.toLocaleTimeString("ko-KR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                });

                                // 두 줄로 된 날짜와 시간을 반환합니다
                                return (
                                    <div className="text-center">
                                        <div>{dateStr}</div>
                                        <div>{timeStr}</div>
                                    </div>
                                );
                            } catch (error) {
                                console.error("날짜 포맷팅 오류:", error);
                                return "-";
                            }
                        };

                        return (
                            <Space direction="vertical">
                                <div>{formatDateTime(record.requestTime)}</div>
                            </Space>
                        );
                    },
                },
            ],
        },
    ];
    return (
        <AdminLayout>
            <StyledCard
                title={
                    <Space
                        size="middle"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 16,
                        }}
                    >
                        <Space
                            size="small"
                            style={{display: "flex", alignItems: "center"}}
                        >
                            <span style={{fontSize: "22px"}}>가입 승인 여부 심사하기</span>
                            <span style={{fontSize: "22px", color: "#618DEF"}}>
                현재
                                {useSignUpInspectionQueryResult?.pagination.totalElements || 0}
                                명
              </span>
                        </Space>
                        <RecommendationGlobalTableFilter
                            filter={filter}
                            onFilterChange={handleFilterChange}
                            onReset={handleReset}
                        />
                    </Space>
                }
            >
                <Table
                    rowKey={(record) => record.id}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record), // 클릭 이벤트
                        style: {cursor: "pointer"}, // 커서 스타일
                    })}
                    columns={columns}
                    dataSource={useSignUpInspectionQueryResult?.contents || []}
                    scroll={{
                        x: calculateColumnsTotalWidth(columns),
                        scrollToFirstRowOnChange: true,
                    }}
                    pagination={{
                        current: filter.page + 1,
                        pageSize: filter.size,
                        total:
                            useSignUpInspectionQueryResult?.pagination.totalElements || 0,
                        onChange: (page, pageSize) => handlePageChange(page, pageSize),
                        showSizeChanger: true,
                        showQuickJumper: true,
                        style: {margin: "16px 0"}, // 페이지네이션 여백 추가
                    }}
                    bordered={true}
                    style={
                        {
                            "--ant-table-border-color": "#f0f0f0", // 테두리 색상 변경
                        } as React.CSSProperties
                    }
                />

                <Modal
                    // ratingType에 따라 타이틀이 동적으로 변경됩니다
                    title={`${
                        updateStateModalState.selectedRecord
                            ? ratingTypeLabels[
                                updateStateModalState.selectedRecord.ratingType
                                ]
                            : ""
                    } 등급 변경`}
                    open={updateStateModalState.isOpen}
                    onCancel={() =>
                        setUpdateStateModalState((prev) => ({...prev, isOpen: false}))
                    }
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() =>
                                setUpdateStateModalState((prev) => ({...prev, isOpen: false}))
                            }
                        >
                            취소
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={async () => {
                                if (!updateStateModalState.selectedRecord) return;

                                const {
                                    id: userId,
                                    ratingType,
                                    newValue,
                                } = updateStateModalState.selectedRecord;

                                // 로컬 상태 업데이트 - UI 즉시 반영을 위함
                                setUserRatings((prev) => ({
                                    ...prev,
                                    [userId]: {
                                        ...prev[userId],
                                        [ratingType]: newValue,
                                    },
                                }));

                                // API 요청 파라미터 생성
                                const params: RatingParams = {userId};

                                // 각 등급 유형에 따라 적절한 파라미터 설정
                                switch (ratingType) {
                                    case "appearance":
                                        params.appearanceRating = newValue;
                                        break;
                                    case "overallSocial":
                                        params.overallSocialRating = newValue;
                                        break;
                                    case "univ":
                                        params.univRating = newValue;
                                        break;
                                    case "income":
                                        params.income = Number(newValue);
                                        break;
                                }
                                setUpdateStateModalState((prev) => ({
                                    ...prev,
                                    isOpen: false,
                                }));
                            }}
                        >
                            변경
                        </Button>,
                    ]}
                    width={400}
                    centered
                    closable={true}
                    closeIcon={<CloseOutlined/>}
                >
                    {updateStateModalState.selectedRecord && (
                        <div>
                            {updateStateModalState.selectedRecord.name} 님의{" "}
                            {
                                ratingTypeLabels[
                                    updateStateModalState.selectedRecord.ratingType
                                    ]
                            }{" "}
                            등급을
                            <br/>'{updateStateModalState.selectedRecord.currentValue}' → '
                            {getRatingLabel(updateStateModalState.selectedRecord.newValue)}'
                            으로 변경할까요?
                        </div>
                    )}
                </Modal>

                <InspectionStatusCheckUpModal
                    isOpen={statusCheckModalState.isOpen}
                    userName={statusCheckModalState.userName}
                    missingItems={statusCheckModalState.missingItems}
                    onClose={closeStatusCheckModal}
                />

                <SignUpApprovalModal
                    isOpen={signUpApprovalModalState.isOpen}
                    selectedUser={signUpApprovalModalState.selectedUser}
                    onClose={handleCloseApprovalModal}
                    onApprove={handleApproveSignUp}
                />

                <SignUpRejectModal
                    isOpen={signUpRejectModalState.isOpen}
                    selectedUser={signUpRejectModalState.selectedUser}
                    onClose={handleCloseRejectModal}
                    onReject={handleRejectSignUp}
                />
            </StyledCard>
        </AdminLayout>
    );
};

const StyledCard = styled(Card)`
    .ant-table-thead > tr:first-child > th {
        background-color: #97b4f4 !important;
    }

    .ant-table-thead > tr:nth-child(2) > th {
        background-color: #cbdafa !important;
    }
`;

export const TitleWrapper = styled.div`
    text-align: center;
    margin-bottom: 8px;
`;

export default InspectionsSignUp;
