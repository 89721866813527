import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {Card} from 'antd';
import MatchLayout from "../../common/components/AdminLayout";
import {ApiError} from "../../utils/api/apiError";
import {Pagination} from "../../utils/api/pagination";
import AllUserProfileCard from "./components/AllUserProfileCard";
import {AllUsers, getAllUsers, GetAllUsersResponse} from "../../utils/api/users/users";


export interface AllUsersQueryResult {
    contents: AllUsers[];
    pagination: Pagination;
}

const UserDetail = () => {
    // URL 파라미터에서 id를 추출합니다.
    // TypeScript를 사용하여 파라미터의 타입을 명시적으로 정의합니다.
    const { id } = useParams<{ id: string }>();

    // useQuery를 사용하여 특정 사용자의 상세 정보를 조회합니다.
    // queryKey에 id를 포함시켜 id가 변경될 때마다 새로운 데이터를 조회하도록 합니다.
    const { data: inspectionDetail, isLoading } = useQuery<GetAllUsersResponse, ApiError, AllUsersQueryResult>({
        queryKey: ['user-detail', id], // id를 queryKey에 포함시켜 캐싱을 관리합니다
        queryFn: () => getAllUsers({
            userId: id,
            page: 0,    // 첫 페이지를 조회합니다
            size: 1     // 한 명의 사용자 정보만 필요하므로 size를 1로 설정합니다
        }),
        // API 응답 데이터를 원하는 형태로 변환합니다
        select: (response) => ({
            contents: response.data.contents,
            pagination: response.data.pagination
        }),
        // id가 없는 경우 쿼리를 비활성화합니다
        enabled: !!id
    });

    // 로딩 중인 경우를 처리합니다
    if (isLoading) {
        return (
            <MatchLayout>
                <Card>
                    <div>Loading...</div>
                </Card>
            </MatchLayout>
        );
    }

    // 데이터가 있는 경우, 첫 번째 항목을 표시합니다
    // contents 배열의 첫 번째 항목이 현재 조회하려는 사용자의 정보입니다
    const userDetail = inspectionDetail?.contents[0];
    return (
        <MatchLayout>
            <Card>
                {userDetail && <AllUserProfileCard data={userDetail} />}
            </Card>
        </MatchLayout>
    );
};

export default UserDetail;