import apiClient from "../apiClient";


// API 응답의 개별 주차 데이터를 나타내는 인터페이스
export interface WeekData {
    weekVale: string;  // 각 주차의 표시 문자열 (예: "25년 10월 1주차 (10.3~10.10)")
    weekId: string;
}

// 전체 API 응답 구조를 나타내는 인터페이스
export interface RecommendationWeeksResponse {
    status: 'success' | 'failure';  // API 응답 상태
    data: WeekData[];  // 주차 데이터 배열
}

interface GetRecommendationWeeksParams {
    startTime?: string;  // 시작 날짜
    endTime?: string;  // 종료 날짜
}

export const getRecommendationWeeks = async (
    params?: GetRecommendationWeeksParams
): Promise<RecommendationWeeksResponse> => {
    const response = await apiClient.get<RecommendationWeeksResponse>(
        '/v1/admin/recommendations/weeks',
        { params }
    );
    return response.data;
};


const dayjs = require('dayjs');

export const convertWeekInfoWithDayjs = (weekInfo : string | any) => {
    // 연도와 날짜 범위를 파싱
    const yearMatch = weekInfo.match(/(\d+)년/);
    const dateRangeMatch = weekInfo.match(/\((\d+\.\d+)~(\d+\.\d+)\)/);

    if (!yearMatch || !dateRangeMatch) {
        throw new Error('Invalid week info format');
    }

    const year = 2000 + parseInt(yearMatch[1]);

    // 시작 날짜와 끝 날짜의 월, 일을 분리하여 파싱
    const [startMonth, startDay] = dateRangeMatch[1].split('.').map(Number);
    const [endMonth, endDay] = dateRangeMatch[2].split('.').map(Number);

    // 각각의 날짜에 대해 완전한 날짜 문자열을 생성
    const startDateTime = dayjs(`${year}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')}`)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');

    const endDateTime = dayjs(`${year}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')}`)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');

    return { startDateTime, endDateTime };
}
