import React, { useState } from "react";
import { Button, Typography } from "antd";
import { InspectionBadgeDisplayModal } from "../modals/InspectionBadgeDisplayModal";

const { Text } = Typography;

// 검사 상태를 위한 열거형 정의
export enum InspectStatus {
  BEFORE_INSPECT = "BEFORE_INSPECT",
  BEFORE_RE_INSPECT = "BEFORE_RE_INSPECT",
  INSPECT_COMPLETE = "INSPECT_COMPLETE",
  REJECT = "REJECT",
}

// 문자열을 enum으로 변환할 때 사용할 타입 가드
function isInspectStatus(status: string): status is InspectStatus {
  return Object.values(InspectStatus).includes(status as InspectStatus);
}

// 메인 컴포넌트의 props 타입 정의
export interface InspectionStatusProps {
  label: string;
  inspectionId: string | null;
  status: string | null;
  inspectionImages: string[] | null;
}

// 각종 등급 상태를 표시하고 변경하는 컴포넌트
export const InspectionBadgeStatusDisplay: React.FC<InspectionStatusProps> = ({
  label,
  inspectionId,
  status,
  inspectionImages,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 문자열 상태값을 enum으로 안전하게 변환하는 함수
  const mapToInspectStatus = (status: string | null): InspectStatus | null => {
    if (!status) return null;
    return isInspectStatus(status) ? status : null;
  };

  // 상태에 따른 색상과 텍스트 스타일 설정
  const getStatusStyle = (status: string | null): { color: string } => {
    const mappedStatus = mapToInspectStatus(status);
    switch (mappedStatus) {
      case InspectStatus.INSPECT_COMPLETE:
        return { color: "#52c41a" };
      case InspectStatus.REJECT:
        return { color: "#ff4d4f" };
      default:
        return { color: "rgba(0, 0, 0, 0.85)" };
    }
  };

  // 상태 텍스트 변환
  const getStatusText = (status: string | null): string => {
    const mappedStatus = mapToInspectStatus(status);
    switch (mappedStatus) {
      case InspectStatus.BEFORE_INSPECT:
        if (inspectionImages && inspectionImages.length > 0) {
          return "제출 · 확인 전";
        }
        return "-";
      case InspectStatus.BEFORE_RE_INSPECT:
        return "재제출 · 확인 전";
      case InspectStatus.INSPECT_COMPLETE:
        return "제출 · 승인";
      case InspectStatus.REJECT:
        return "제출 · 반려";
      default:
        return "-";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "4px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "40px",
            marginRight: "8px",
            flexShrink: 0,
          }}
        >
          <Text strong>{label}</Text>
        </div>
        <div
          style={{
            width: "96px",
            marginRight: inspectionId ? "8px" : 0,
          }}
        >
          <Text style={inspectionId ? getStatusStyle(status) : undefined}>
            {getStatusText(status)}
          </Text>
        </div>
        {inspectionImages && inspectionImages.length > 0 && (
          <Button
            type="link"
            style={{
              padding: 0,
              height: "auto",
              minWidth: "28px",
            }}
            onClick={() => setIsModalOpen(true)}
          >
            보기
          </Button>
        )}
      </div>

      <InspectionBadgeDisplayModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        label={label}
        inspectionImages={inspectionImages}
      />
    </>
  );
};
