import React, { useState } from "react";
import { openModal } from "../../common/helper/modal";
import { TestModal } from "./TestModal";
import { Button } from "antd";

const handleOpenModal = () => {
  return new Promise((resolve) => {
    openModal(({ isOpen, onClose }) => {
      return (
        <div>
          <TestModal
            isOpen={isOpen}
            onClose={onClose}
            onApprove={(data) => {
              resolve(data);
            }}
            onReject={(data) => {
              resolve(data);
            }}
          />
        </div>
      );
    });
  });
};

const TestPage = () => {
  const [data, setData] = useState<string | null>(null);
  return (
    <div>
      <Button
        onClick={async () => {
          const res = await handleOpenModal();
          setData(res as string);
        }}
      >
        OPEN MODAL
      </Button>
      <div>{data}</div>
    </div>
  );
};

export default TestPage;
