import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdminLayout from "../../common/components/AdminLayout";
import RecommendationGlobalTableFilter from "../recommend/components/RecommendationGlobalTableFilter";
import {
  calculateColumnsTotalWidth,
  FILTER_OPTIONS,
  formatKoreanMoney,
  getTagColor,
  PreferenceOption,
} from "../recommend/RecommendationList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  InspectionImageResultSendApproveModalProps,
  SignUpInspectionModalState,
} from "../../utils/types/inspection/inspectionModal";
import { ApiError } from "../../utils/api/apiError";
import { InspectStatus } from "./components/badge/InspectionBadgeStatusUpdate";
import { InspectionBadgeStatusDisplay } from "./components/badge/InspectionBadgeStatusDisplay";
import ProfileInspectionImageUpdate from "./components/images/ProfileInspectionImageUpdate";
import {
  getImageInspectionUsers,
  GetImageInspectionUsersResponse,
  ImageInspectionUsers,
  rejectImageInspectionUser,
  updateImageInspectionMypageUser,
} from "../../utils/api/inspection/imageUsersInspection";
import { Pagination } from "../../utils/api/pagination";
import InspectionImageResultSendApproveModal from "./components/modals/InspectionImageResultSendApproveModal";
import InspectionImageResultSendRejectModal from "./components/modals/InspectionImageResultSendRejectModal";

export interface ImageInspectionUsersQueryResult {
  contents: ImageInspectionUsers[];
  pagination: Pagination;
}

type ExtendedImageInspectionUsers = ImageInspectionUsers & {
  result: "확인 전" | "요청 승인" | "요청 반려";
};

const InspectionsImages: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // URL에서 초기 필터 값을 가져오는 함수
  const getInitialFilter = useCallback(() => {
    return {
      gender: searchParams.get("gender") || "",
      name: searchParams.get("name") || "",
      page: parseInt(searchParams.get("page") || "0"),
      size: parseInt(searchParams.get("size") || "10"),
      overallSocialRating: searchParams.get("overallSocialRating") || "",
      appearanceRating: searchParams.get("appearanceRating") || "",
      univRating: searchParams.get("univRating") || "",
      preferenceType: searchParams.get("preferenceType") || "",
      userId: searchParams.get("userId") || "",
    };
  }, [searchParams]);

  const [filter, setFilter] = useState(getInitialFilter());

  // 필터 변경시 URL 업데이트
  const updateURLParameters = (newFilter: typeof filter) => {
    const params = new URLSearchParams();
    Object.entries(newFilter).forEach(([key, value]) => {
      if (value !== "") {
        params.set(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  const handleFilterChange = (values: Partial<typeof filter>) => {
    const newFilter = {
      ...filter,
      ...values,
      page: 0, // 필터 변경 시 첫 페이지로 이동
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const newFilter = {
      ...filter,
      page: page - 1,
      size: pageSize || filter.size,
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  // 가입 승인 모달의 상태를 관리합니다
  const [signUpApprovalModalState, setSignUpApprovalModalState] =
    useState<InspectionImageResultSendApproveModalProps>({
      isOpen: false,
      selectedUser: null,
    });

  // 가입 반려 모달의 상태를 관리합니다
  const [signUpRejectModalState, setSignUpRejectModalState] =
    useState<SignUpInspectionModalState>({
      isOpen: false,
      selectedUser: null,
    });

  // 모달을 여는 함수
  const handleOpenApprovalModal = (
    userId: string,
    userName: string,
    result: "요청 승인" | "요청 반려" | "확인 중",
    representationImageUrl: string[],
    newImages: string[],
    newVideos: string[],
    newVideoThumbnails: string[]
  ) => {
    setSignUpApprovalModalState({
      isOpen: true,
      selectedUser: {
        id: userId,
        name: userName,
        result,
        representationImageUrl,
        newImages,
        newVideos,
        newVideoThumbnails,
      },
    });
  };

  // 모달을 닫는 함수입니다
  const handleCloseApprovalModal = () => {
    setSignUpApprovalModalState({
      isOpen: false,
      selectedUser: null,
    });
  };

  const handleCloseRejectModal = () => {
    setSignUpRejectModalState({
      isOpen: false,
      selectedUser: null,
    });
  };

  // 인증 상태를 관리할 상태 객체 정의
  const [inspectionStatuses, setInspectionStatuses] = useState<
    Record<string, Record<string, string | null>>
  >({});

  // 모든 인증이 승인 또는 거절되었는지 확인하는 함수
  const checkAllInspectionsProcessed = (
    userId: string,
    record: ImageInspectionUsers
  ) => {
    // 사용자의 인증 상태를 가져옴 (변경이 없다면 원래 레코드 값 사용)
    const userStatuses = inspectionStatuses[userId] || {};

    // 인증이 필요한 항목 개수 (인증 ID가 있는 경우만 해당)
    let requiredInspectionsCount = 0;
    let processedInspectionsCount = 0;

    // 경력 인증 확인
    if (record.careerInspectionId) {
      requiredInspectionsCount++;
      const careerStatus =
        userStatuses["career"] || record.careerInspectionStatus;
      if (
        careerStatus === InspectStatus.INSPECT_COMPLETE ||
        careerStatus === InspectStatus.REJECT
      ) {
        processedInspectionsCount++;
      }
    }

    // 대학 인증 확인
    if (record.univInspectionId) {
      requiredInspectionsCount++;
      const univStatus = userStatuses["univ"] || record.univInspectionStatus;
      if (
        univStatus === InspectStatus.INSPECT_COMPLETE ||
        univStatus === InspectStatus.REJECT
      ) {
        processedInspectionsCount++;
      }
    }

    // 소득 인증 확인
    if (record.incomeInspectionId) {
      requiredInspectionsCount++;
      const incomeStatus =
        userStatuses["income"] || record.incomeInspectionStatus;
      if (
        incomeStatus === InspectStatus.INSPECT_COMPLETE ||
        incomeStatus === InspectStatus.REJECT
      ) {
        processedInspectionsCount++;
      }
    }

    // 필요한 인증이 있는 경우에만 모든 인증이 처리되었는지 확인
    return (
      requiredInspectionsCount === 0 ||
      requiredInspectionsCount === processedInspectionsCount
    );
  };

  // 유저 등급 변경 관리 상태
  const [userRatings, setUserRatings] = useState<
    Record<string, Record<string, string>>
  >({});

  const handleReset = () => {
    const resetFilter = {
      gender: "",
      name: "",
      page: 0,
      size: 10,
      overallSocialRating: "",
      appearanceRating: "",
      univRating: "",
      preferenceType: "",
      userId: "",
    };
    setFilter(resetFilter);
    // URL 파라미터 초기화
    setSearchParams({});
  };

  // URL 변경 감지하여 필터 상태 업데이트
  useEffect(() => {
    const currentFilter = getInitialFilter();
    setFilter(currentFilter);
  }, [getInitialFilter]);

  // 쿼리 훅 정의
  const { data: useImageInspectionQueryResult } = useQuery<
    GetImageInspectionUsersResponse,
    ApiError,
    ImageInspectionUsersQueryResult
  >({
    // 쿼리 키를 객체 형태로 구조화
    queryKey: ["image-inspections", filter],
    queryFn: () =>
      getImageInspectionUsers({
        ...filter, // 객체로 전달
      }),
    // 데이터 변환
    select: (response) => ({
      contents: response.data.contents,
      pagination: response.data.pagination,
    }),
  });

  const [localImageInspectionData, setLocalImageInspectionData] = useState<
    ExtendedImageInspectionUsers[]
  >([]);

  useEffect(() => {
    if (useImageInspectionQueryResult) {
      setLocalImageInspectionData(prevData => {
        // 기존 데이터를 매개변수로 받아 사용합니다
        return useImageInspectionQueryResult.contents.map((record) => {
          const existingRecord = prevData.find(
              (localRecord) => localRecord.id === record.id
          );
          return {
            ...record,
            allow: "default", // 초기값 설정
            result: existingRecord ? existingRecord.result : "확인 전", // 기존 값 유지 또는 초기값 설정
          };
        });
      });
    }
  }, [useImageInspectionQueryResult]); // localImageInspectionData는 의존성 배열에서 제거

  const handleResultChange = (
    userId: string,
    result: "확인 전" | "요청 승인" | "요청 반려"
  ) => {
    setLocalImageInspectionData((prev) =>
      prev.map((record) =>
        record.id === userId ? { ...record, result } : record
      )
    );
  };

  const queryClient = useQueryClient();

  // 인증 심사 승인을 처리하는 함수를 정의합니다
  const { mutate: approveImageInspectionMutate } = useMutation({
    mutationFn: ({
      userId,
      representationImageUrl,
      newImages,
      newVideos,
      newVideoThumbnails,
    }: {
      userId: string;
      representationImageUrl: string[];
      newImages: string[];
      newVideos: string[];
      newVideoThumbnails: string[];
    }) =>
      updateImageInspectionMypageUser(userId, {
        representationImageUrl,
        newImages,
        newVideos,
        newVideoThumbnails,
      }),
  });

  const handleApproveInspectionBadges = async (
    userId: string
  ): Promise<boolean> => {
    approveImageInspectionMutate(
      {
        userId,
        newImages: signUpApprovalModalState.selectedUser?.newImages || [],
        newVideos: signUpApprovalModalState.selectedUser?.newVideos || [],
        newVideoThumbnails:
          signUpApprovalModalState.selectedUser?.newVideoThumbnails || [],
        representationImageUrl:
          signUpApprovalModalState.selectedUser?.representationImageUrl || [],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["image-inspections", filter],
          });
        },
      }
    );
    return true;
  };

  // 인증 심사 반려를 처리하는 함수를 정의합니다
  const { mutate: rejectImageInspectionMutate } = useMutation({
    mutationFn: (userId: string) => rejectImageInspectionUser(userId),
  });
  const handleRejectInspectionBadges = async (
    userId: string
  ): Promise<boolean> => {
    rejectImageInspectionMutate(userId, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["image-inspections", filter],
        });
      },
    });
    return true;
  };

  const handleRowClick = (record: ImageInspectionUsers) => {
    navigate(`/users/${record.id}`);
  };

  // 쿼리 결과가 업데이트되면 로컬 상태 초기화
  useEffect(() => {
    if (useImageInspectionQueryResult?.contents) {
      setUserRatings({});
      setInspectionStatuses({});
    }
  }, [useImageInspectionQueryResult?.contents]);

  const columns: ColumnsType<ExtendedImageInspectionUsers> = [
    {
      title: "매력 정보/기본 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>프로필/외모</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    handleFilterChange({ appearanceRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "profile",
          width: 240,
          align: "center",
          render: (_, record) => (
            <div onClick={(e) => e.stopPropagation()}>
              <ProfileInspectionImageUpdate
                representationImages={record.userRepresentativeImages}
                profileImage={record.userProfileImages}
                editImages={[
                  ...record.appearanceInspectionRepresentativeImages,
                  ...record.appearanceInspectionBasicImages,
                ]}
                videos={record.userVideos}
                videoThumbnails={record.userVideoThumbnails}
                appearanceRating={
                  userRatings[record.id]?.appearance || record.appearanceRating
                }
                userId={record.id}
                handleResultChange={handleResultChange}
              />
            </div>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>이름 / 성별 / 나이 / 키</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.gender}
                  onChange={(value) => handleFilterChange({ gender: value })}
                >
                  {Object.values(FILTER_OPTIONS.GENDER).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "basic info",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.name}({record.gender === "남자" ? "남" : "여"})
              </div>
              <div>{record.age}세</div>
              <div>{record.height}cm</div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "능력 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>직업</TitleWrapper>
              <Space>
                <Select
                  placeholder="능력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.overallSocialRating}
                  onChange={(value) =>
                    handleFilterChange({ overallSocialRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "career",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag
                color={getTagColor(
                  record.overallSocialRating,
                  "OVERALL_SOCIAL_RATING"
                )}
              >
                능력 {record.overallSocialRating}
              </Tag>
              <div>{record.careerDetail}</div>
              <div>{record.careerSubGroup}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>연봉</div>
            </div>
          ),
          key: "income",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.income != null ? formatKoreanMoney(record.income) : "-"}
              </div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>학력</div>
              <Space>
                <Select
                  placeholder="학력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.univRating}
                  onChange={(value) =>
                    handleFilterChange({ univRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "univ",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag color={getTagColor(record.univRating, "UNIV_RATING")}>
                학력 {record.univRating}
              </Tag>
              <div>{record.univName !== "" ? record.univName : "-"}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>인증 정보</div>
            </div>
          ),
          key: "career",
          width: 216,
          align: "center",
          render: (_, record) => {
            // 현재 사용자의 인증 뱃지 상태를 가져옴 (없으면 빈 객체)
            const userStatuses = inspectionStatuses[record.id] || {};

            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Space direction="vertical">
                  <InspectionBadgeStatusDisplay
                    label="직업"
                    inspectionId={record.careerInspectionId}
                    status={
                      userStatuses["career"] || record.careerInspectionStatus
                    }
                    inspectionImages={record.careerInspectionImages}
                  />
                  <InspectionBadgeStatusDisplay
                    label="학력"
                    inspectionId={record.univInspectionId}
                    status={userStatuses["univ"] || record.univInspectionStatus}
                    inspectionImages={record.univInspectionImages}
                  />
                  <InspectionBadgeStatusDisplay
                    label="연봉"
                    inspectionId={record.incomeInspectionId}
                    status={
                      userStatuses["income"] || record.incomeInspectionStatus
                    }
                    inspectionImages={record.incomeInspectionImages}
                  />
                </Space>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "이상형 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>이상형 정보</div>
              <Select
                placeholder="유저 타입"
                size="small"
                style={{ width: 120 }}
                value={filter.preferenceType}
                onChange={(value) =>
                  handleFilterChange({ preferenceType: value })
                }
              >
                {Object.values(FILTER_OPTIONS.PREFERENCE_TYPE).map(
                  ({ value, label, description }: PreferenceOption) => (
                    <Select.Option key={value} value={value}>
                      <div className="select-option-content">
                        {description ? (
                          <Tooltip title={description} placement="right">
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          <div>{label}</div>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          ),
          key: "preferenceType",
          align: "center",
          width: 200,
          render: (_, record) => (
            <Space direction="vertical">
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>타입</b>
                </div>
                <Tag
                  color={getTagColor(record.preferenceType, "PREFERENCE_TYPE")}
                >
                  {record.preferenceType}
                </Tag>
              </div>
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>나이</b>
                </div>
                {Number(record.age) - Number(record.partnerUnderAge)} ~{" "}
                {Number(record.age) + Number(record.partnerUpperAge)}세
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>키</b>
                </div>
                {record.partnerHeightRange[0].includes("-")
                  ? `${record.partnerHeightRange[0].replace("-", "")}`
                  : `${record.partnerHeightRange[0]}`}{" "}
                ~{" "}
                {record.partnerHeightRange[1].includes("+")
                  ? `${record.partnerHeightRange[1].replace("+", "")}cm`
                  : `${record.partnerHeightRange[1]}cm`}
              </div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "심사 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>심사 결과</div>
            </div>
          ),
          key: "inspectionResult",
          align: "center",
          width: 160,
          render: (_, record) => (
            <Space direction="vertical" style={{ gap: "10px" }}>
              {/* 확인 전 검정, 요청 승인 초록, 요청 반려 빨강 */}
              <Typography.Text
                type={
                  record.result === "확인 전"
                    ? undefined
                    : record.result === "요청 승인"
                    ? "success"
                    : "danger"
                }
              >
                {record.result}
              </Typography.Text>
            </Space>
          ),
        },

        {
          title: () => (
            <div>
              <div>결과 발송</div>
            </div>
          ),
          key: "sendInspectionResult",
          align: "center",
          width: 160,
          render: (_, record) => {
            const isAllInspectionsProcessed = checkAllInspectionsProcessed(
              record.id,
              record
            );

            // 두 인증 확인이 모두 되어야 결과를 발송할수 있음
            const canProcessUser = isAllInspectionsProcessed;
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Space
                  direction="vertical"
                  style={{
                    gap: "10px",
                  }}
                >
                  <Button
                      type="primary"
                      disabled={record.result === "확인 전"}
                      style={
                        {
                          borderRadius: "5px",
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                          cursor: canProcessUser ? "pointer" : "not-allowed",
                          ":hover": {
                            backgroundColor: "#6DAF3B !important",
                            borderColor: "#6DAF3B !important",
                          },
                        } as React.CSSProperties
                      }
                      onClick={() => {
                        if (record.result === "확인 전") return;

                        // record.result에 따라 다른 처리를 합니다
                        if (record.result === "요청 승인") {
                          // 승인 처리를 위한 모달 열기
                          // appearanceInspectionBasicImages 중에서 userRepresentativeImages를 제외한 이미지들을 추출
                          const filteredImages =
                              record.appearanceInspectionBasicImages.filter(
                                  (image) =>
                                      !record.userRepresentativeImages.includes(image)
                              );
                          handleOpenApprovalModal(
                              record.id,
                              record.name,
                              record.result,
                              record.userRepresentativeImages,
                              filteredImages,
                              record.userVideos,
                              record.userVideoThumbnails
                          );
                        } else if (record.result === "요청 반려") {
                          // 반려 처리를 위한 모달 열기
                          setSignUpRejectModalState({
                            isOpen: true,
                            selectedUser: {
                              id: record.id,
                              name: record.name,
                            },
                          });
                        }
                      }}
                  >
                    결과 발송
                  </Button>
                </Space>
              </div>
            );
          },
        },
        {
          title: () => (
            <div>
              <div>요청 일시</div>
            </div>
          ),
          key: "inspectionResult",
          align: "center",
          width: 160,
          render: (_, record) => {
            const formatDateTime = (dateString: string) => {
              try {
                const date = new Date(dateString);

                if (isNaN(date.getTime())) {
                  return "-";
                }

                // 날짜와 시간을 별도로 포맷팅합니다
                const dateStr = date
                  .toLocaleDateString("ko-KR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\. /g, "-")
                  .slice(0, -1);

                const timeStr = date.toLocaleTimeString("ko-KR", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                });

                // 두 줄로 된 날짜와 시간을 반환합니다
                return (
                  <div className="text-center">
                    <div>{dateStr}</div>
                    <div>{timeStr}</div>
                  </div>
                );
              } catch (error) {
                console.error("날짜 포맷팅 오류:", error);
                return "-";
              }
            };

            return (
              <Space direction="vertical">
                <div>{formatDateTime(record.requestTime)}</div>
              </Space>
            );
          },
        },
      ],
    },
  ];
  return (
    <AdminLayout>
      <StyledCard
        title={
          <Space
            size="middle"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <Space
              size="small"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "22px" }}>사진 수정 요청 심사하기</span>
              <span style={{ fontSize: "22px", color: "#618DEF" }}>
                현재
                {useImageInspectionQueryResult?.pagination.totalElements || 0}명
              </span>
            </Space>
            <RecommendationGlobalTableFilter
              filter={filter}
              onFilterChange={handleFilterChange}
              onReset={handleReset}
            />
          </Space>
        }
      >
        <Table
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // 클릭 이벤트
            style: { cursor: "pointer" }, // 커서 스타일
          })}
          columns={columns}
          dataSource={localImageInspectionData}
          scroll={{
            x: calculateColumnsTotalWidth(columns),
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            current: filter.page + 1,
            pageSize: filter.size,
            total: useImageInspectionQueryResult?.pagination.totalElements || 0,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showSizeChanger: true,
            showQuickJumper: true,
            style: { margin: "16px 0" }, // 페이지네이션 여백 추가
          }}
          bordered={true}
          style={
            {
              "--ant-table-border-color": "#f0f0f0", // 테두리 색상 변경
            } as React.CSSProperties
          }
        />

        <InspectionImageResultSendApproveModal
          isOpen={signUpApprovalModalState.isOpen}
          selectedUser={signUpApprovalModalState.selectedUser}
          onClose={handleCloseApprovalModal}
          onApprove={handleApproveInspectionBadges}
        />
        <InspectionImageResultSendRejectModal
          isOpen={signUpRejectModalState.isOpen}
          selectedUser={signUpRejectModalState.selectedUser}
          onClose={handleCloseRejectModal}
          onApprove={handleRejectInspectionBadges}
        />
      </StyledCard>
    </AdminLayout>
  );
};

const StyledCard = styled(Card)`
  .ant-table-thead > tr:first-child > th {
    background-color: #97b4f4 !important;
  }

  .ant-table-thead > tr:nth-child(2) > th {
    background-color: #cbdafa !important;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default InspectionsImages;
