import "./App.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Link, Outlet, useLocation} from "react-router-dom";
import styled from "@emotion/styled";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {Button, Layout, Menu, MenuProps} from "antd";
import userStorage from "./utils/userStorage";
import React, {useEffect, useState} from "react";
import {Header} from "antd/es/layout/layout";
import { ModalContainer } from "./common/components/ModalContainer";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            refetchOnWindowFocus: false,
        },
    },
});

const handleLogin = () => {
    // 구글 로그인 화면으로 이동시키기
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI}&response_type=code&scope=email`;
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
    getItem(
        <Link to="/" style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <img
                src="https://jusunking.com/assets/logo_icon-xX3BbjHu.png"
                alt="로고 이미지"
                width={40}
                height={40}
                style={{
                    borderRadius: '8px',
                }}
            />
        </Link>,
        "/"
    ),
    getItem(<Link to="/recommendations">주차별 추천 조회</Link>, "/recommendations"),
    getItem(<Link to="/inspections">회원 승인·심사</Link>, "/inspections"),
    getItem(<Link to="/users">전체 회원 조회</Link>, "/users")
];

function App() {
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!!userStorage.get()) setIsLogin(true);
    }, [location.pathname]);

    const path = location.pathname;

    return (
        <QueryClientProvider client={queryClient}>
            {isLogin ? (
                <Layout>
                    <Header style={{ lineHeight: '64px', padding: '0 20px' }}>
                    <Menu
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={[path]}
                            items={items}
                            style={{
                                height: '64px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        />
                    </Header>
                    <Outlet/>
                </Layout>
            ) : (
                <NonLoginLayout>
                    <div>
                        <Button danger size="large" onClick={handleLogin}>
                            구글 로그인
                        </Button>
                    </div>
                    <Outlet/>
                </NonLoginLayout>
            )}
            <ModalContainer />
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    );
}

const NonLoginLayout = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export default App;
