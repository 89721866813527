import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UsersHome from "./pages/user/UsersHome";
import RecommendationList from "./pages/recommend/RecommendationList";
import Appearance from "./pages/user/Appearance";
import Ability from "./pages/user/Career";
import Univ from "./pages/user/Univ";
import RecommendationDetail from "./pages/recommend/RecommendationDetail";
import MatchingHome from "./pages/matching/MatchingHome";
import MatchingDetail from "./pages/matching/MatchingDetail";
import GoogleLogin from "./pages/oauth/GoogleLogin";
import AuthGuardLayout from "./pages/oauth/components/AuthGuardLayout";
import RecommendationStatistics from "./pages/recommend/RecommendationStatistics";
import RecommendationMain from "./pages/recommend/RecommendationMain";
import InspectionsMain from "./pages/inspection/InspectionsMain";
import InspectionsSignUp from "./pages/inspection/InspectionsSignUp";
import UserDetail from "./pages/user/UserDetail";
import InspectionsBadge from "./pages/inspection/InspectionsBadge";
import InspectionsImages from "./pages/inspection/InspectionsImages";
import TestPage from "./pages/test/TestPage";
import RecommendationUpperLevelManage from "./pages/recommend/RecommendationUpperLevelManage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuardLayout>
        <App />
      </AuthGuardLayout>
    ),
    children: [
      {
        path: "/oauth/google-redirect",
        element: <GoogleLogin />,
      },
      {
        path: "/users",
        element: <UsersHome />,
      },
      {
        path: "/users/appearance",
        element: <Appearance />,
      },
      {
        path: "/users/career",
        element: <Ability />,
      },
      {
        path: "/users/univ",
        element: <Univ />,
      },
      {
        path: "/recommendations",
        element: <RecommendationMain />,
      },
      {
        path: "/recommendations/:weekValue/weeks/:weekId",
        element: <RecommendationList />,
      },
      {
        path: "/recommendations/:weekValue/weeks/:weekId/users/:id",
        element: <RecommendationDetail />,
      },
      {
        path: "/recommendations/:weekValue/weeks/:weekId/users/:id/candidates/upperLevel",
        element: <RecommendationUpperLevelManage />,
      },
      {
        path: "/recommendations/statistics",
        element: <RecommendationStatistics />,
      },
      {
        path: "/inspections",
        element: <InspectionsMain />,
      },
      {
        path: "/inspections/signup",
        element: <InspectionsSignUp />,
      },
      {
        path: "/inspections/badges",
        element: <InspectionsBadge />,
      },
      {
        path: "/inspections/images",
        element: <InspectionsImages />,
      },
      {
        path: "/users/:id",
        element: <UserDetail />,
      },
      {
        path: "/matching",
        element: <MatchingHome />,
      },
      {
        path: "/matching/:id",
        element: <MatchingDetail />,
      },
      {
        path: "/test",
        element: <TestPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
