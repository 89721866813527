import React, { useEffect, useState } from "react";
import { useModal } from "../hooks/useModal";
import { RenderModalProps } from "../helper/modal";

export const ModalContainer = () => {
  const { renderModal, closeModal, isModalOpen } = useModal();
  return (
    <>
      {renderModal((id, render) => {
        return (
          <div key={id}>
            <RenderItem
              render={render}
              isOpen={isModalOpen(id)}
              onClose={() => closeModal(id)}
            />
          </div>
        );
      })}
    </>
  );
};

function RenderItem({
  render,
  isOpen,
  onClose,
}: {
  render: ({ isOpen, onClose }: RenderModalProps) => JSX.Element;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(isOpen);
    }, 100);
  }, [isOpen]);

  return <>{render({ isOpen: open, onClose })}</>;
}
