import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Modal, Select, Space, Table, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { SignUpInspectionModalState } from "../../utils/types/inspection/inspectionModal";
import {
  calculateColumnsTotalWidth,
  FILTER_OPTIONS,
  formatKoreanMoney,
  getTagColor,
  PreferenceOption,
} from "../recommend/RecommendationList";
import { ApiError } from "../../utils/api/apiError";
import RecommendationGlobalTableFilter from "../recommend/components/RecommendationGlobalTableFilter";
import AdminLayout from "../../common/components/AdminLayout";
import { InspectionBadgeStatusUpdate } from "./components/badge/InspectionBadgeStatusUpdate";

import ProfileDisplay from "../recommend/components/ProfileDisplay";
import InspectionResultSendModal from "./components/modals/InspectionResultSendModal";
import {
  BadgeInspectionUsers,
  getBadgeInspectionUsers,
  GetBadgeInspectionUsersResponse,
} from "../../utils/api/inspection/badgeUsersInspection";
import { Pagination } from "../../utils/api/pagination";
import { useUpdateInspectionBadgeMutation } from "../../utils/api/inspection/signUpUsersInspection";
import {convertIncomeNumberToString} from "./InspectionsSignUp";

export type RatingType = "univ" | "appearance" | "overallSocial" | "income";

export interface BadgeInspectionUsersQueryResult {
  contents: BadgeInspectionUsers[];
  pagination: Pagination;
}

const InspectionsBadge: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  // URL에서 초기 필터 값을 가져오는 함수
  const getInitialFilter = useCallback(() => {
    return {
      gender: searchParams.get("gender") || "",
      name: searchParams.get("name") || "",
      page: parseInt(searchParams.get("page") || "0"),
      size: parseInt(searchParams.get("size") || "10"),
      overallSocialRating: searchParams.get("overallSocialRating") || "",
      appearanceRating: searchParams.get("appearanceRating") || "",
      univRating: searchParams.get("univRating") || "",
      preferenceType: searchParams.get("preferenceType") || "",
      userId: searchParams.get("userId") || "",
    };
  }, [searchParams]);

  const [filter, setFilter] = useState(getInitialFilter());

  // 필터 변경시 URL 업데이트
  const updateURLParameters = (newFilter: typeof filter) => {
    const params = new URLSearchParams();
    Object.entries(newFilter).forEach(([key, value]) => {
      if (value !== "") {
        params.set(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  const handleFilterChange = (values: Partial<typeof filter>) => {
    const newFilter = {
      ...filter,
      ...values,
      page: 0, // 필터 변경 시 첫 페이지로 이동
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const newFilter = {
      ...filter,
      page: page - 1,
      size: pageSize || filter.size,
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  // 가입 승인 모달의 상태를 관리합니다
  const [signUpApprovalModalState, setSignUpApprovalModalState] =
    useState<SignUpInspectionModalState>({
      isOpen: false,
      selectedUser: null,
    });

  // 모달을 여는 함수입니다
  const handleOpenApprovalModal = (userId: string, userName: string) => {
    setSignUpApprovalModalState({
      isOpen: true,
      selectedUser: {
        id: userId,
        name: userName,
      },
    });
  };

  // 모달을 닫는 함수입니다
  const handleCloseApprovalModal = () => {
    setSignUpApprovalModalState({
      isOpen: false,
      selectedUser: null,
    });
  };

  // 인증 상태를 관리할 상태 객체 정의
  const [inspectionStatuses, setInspectionStatuses] = useState<
    Record<string, Record<string, string | null>>
  >({});

  // 인증 상태 변경 핸들러
  const handleInspectionStatusChange = (
    userId: string,
    inspectionType: string,
    newStatus: string
  ) => {
    setInspectionStatuses((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [inspectionType]: newStatus,
      },
    }));
  };

  // 모든 인증이 승인 또는 거절되었는지 확인하는 함수
  const checkAllInspectionsProcessed = (
    userId: string,
    record: BadgeInspectionUsers
  ) => {
    const userStatuses = inspectionStatuses[userId] || {};

    // Define the inspection types to check
    const inspectionTypes = [
      {
        id: record.careerInspectionId,
        status: "career",
        recordStatus: record.careerInspectionStatus,
      },
      {
        id: record.univInspectionId,
        status: "univ",
        recordStatus: record.univInspectionStatus,
      },
      {
        id: record.incomeInspectionId,
        status: "income",
        recordStatus: record.incomeInspectionStatus,
      },
    ];

    // Check if any inspection status is "BEFORE_RE_INSPECT"
    return inspectionTypes.some(({ id, status, recordStatus }) => {
      if (!id) return false; // Skip if no ID
      const currentStatus = userStatuses[status] || recordStatus;
      return currentStatus === "BEFORE_RE_INSPECT";
    });
  };

  /**
   * 다양한 등급 타입에 대한 레이블을 반환하는 함수
   * @param value 등급 값(value 속성)
   * @param ratingType 등급 타입(옵션) - 지정하지 않으면 모든 등급 타입에서 검색
   * @returns 해당 등급 값에 대응하는 레이블
   */
  const getRatingLabel = (value: string, ratingType?: RatingType): string => {
    // 등급 타입이 지정된 경우 해당 등급에서만 검색
    if (ratingType) {
      switch (ratingType) {
        case "univ":
          return (
            Object.values(FILTER_OPTIONS.UNIV_RATING).find(
              (rating) => rating.value === value
            )?.label || ""
          );
        case "income":
          return (
            Object.values(FILTER_OPTIONS.INCOME).find(
              (rating) => rating.value === value
            )?.label || ""
          );
        case "appearance":
          return (
            Object.values(FILTER_OPTIONS.APPEARANCE_RATING).find(
              (rating) => rating.value === value
            )?.label || ""
          );
        case "overallSocial":
          return (
            Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).find(
              (rating) => rating.value === value
            )?.label || ""
          );
        default:
          return "";
      }
    }

    // 등급 타입이 지정되지 않은 경우 모든 등급에서 검색
    // 연봉 등급 검색
    const incomeLabel = Object.values(FILTER_OPTIONS.INCOME).find(
      (rating) => rating.value === value
    )?.label;
    if (incomeLabel) return incomeLabel;

    // 대학 등급 검색
    const univLabel = Object.values(FILTER_OPTIONS.UNIV_RATING).find(
      (rating) => rating.value === value
    )?.label;
    if (univLabel) return univLabel;

    // 외모 등급 검색
    const appearanceLabel = Object.values(
      FILTER_OPTIONS.APPEARANCE_RATING
    ).find((rating) => rating.value === value)?.label;
    if (appearanceLabel) return appearanceLabel;

    // 능력 등급 검색
    const overallSocialLabel = Object.values(
      FILTER_OPTIONS.OVERALL_SOCIAL_RATING
    ).find((rating) => rating.value === value)?.label;
    if (overallSocialLabel) return overallSocialLabel;

    // 일치하는 값이 없으면 원래 값을 반환
    return value || "";
  };

  type RatingParams = {
    userId: string;
    appearanceRating?: string;
    overallSocialRating?: string;
    univRating?: string;
    incomeRating?: string;
  };

  // 상태 타입 정의
  interface UpdateStateModalState {
    isOpen: boolean;
    selectedRecord: {
      id: string;
      name: string;
      currentValue: string;
      newValue: string;
      ratingType: RatingType;
    } | null;
  }

  // 등급 타입별 레이블을 정의하는 객체를 만듭니다
  const ratingTypeLabels: Record<RatingType, string> = {
    univ: "학력",
    appearance: "외모",
    overallSocial: "능력",
    income: "연봉",
  };

  const [updateStateModalState, setUpdateStateModalState] =
    useState<UpdateStateModalState>({
      isOpen: false,
      selectedRecord: null,
    });

  const handleReset = () => {
    const resetFilter = {
      gender: "",
      name: "",
      page: 0,
      size: 10,
      overallSocialRating: "",
      appearanceRating: "",
      univRating: "",
      preferenceType: "",
      userId: "",
    };
    setFilter(resetFilter);
    // URL 파라미터 초기화
    setSearchParams({});
  };

  // URL 변경 감지하여 필터 상태 업데이트
  useEffect(() => {
    const currentFilter = getInitialFilter();
    setFilter(currentFilter);
  }, [getInitialFilter]);

  // 쿼리 훅 정의
  const { data: useBadgeInspectionUsersQueryResult } = useQuery<
    GetBadgeInspectionUsersResponse,
    ApiError,
    BadgeInspectionUsersQueryResult
  >({
    // 쿼리 키를 객체 형태로 구조화
    queryKey: ["badge-inspections", filter],
    queryFn: () =>
      getBadgeInspectionUsers({
        ...filter, // 객체로 전달
      }),
    // 데이터 변환
    select: (response) => ({
      contents: response.data.contents,
      pagination: response.data.pagination,
    }),
  });

  // 유저 심사 뱃지 등급 변경 mutation
  const updateBadgeMutation = useUpdateInspectionBadgeMutation({
    invalidateQueryKey: ["badge-inspections"],
  });

  // 뱃지 인증 승인을 처리하는 함수를 정의합니다
  const handleApproveInspectionBadges = async (
    userId: string
  ): Promise<boolean> => {
    const userBadgeChanges = inspectionStatuses[userId];
    if (userBadgeChanges && Object.keys(userBadgeChanges).length > 0) {
      // API 요청을 위한 쿼리 배열 생성
      const badgeQueries = [];

      // 변경된 각 뱃지 유형에 대해 쿼리 생성
      // career 인증은 OVERALL_SOCIAL 등급과 연관됨
      if (userBadgeChanges.career) {
        badgeQueries.push({
          inspectStatus: userBadgeChanges.career,
          ratingType: "OVERALL_SOCIAL", // 서버 enum에 맞게 수정
          rejectionReason: "",
        });
      }
      if (userBadgeChanges.univ) {
        badgeQueries.push({
          inspectStatus: userBadgeChanges.univ,
          ratingType: "UNIV",
          rejectionReason: "",
        });
      }
      if (userBadgeChanges.income) {
        badgeQueries.push({
          inspectStatus: userBadgeChanges.income,
          ratingType: "INCOME",
          rejectionReason: "",
        });
      }

      // 변경할 뱃지가 하나라도 있으면 API 호출
      if (badgeQueries.length > 0) {
        await updateBadgeMutation.mutateAsync(
          {
            userId,
            queries: badgeQueries,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ["badge-inspections"],
              });
            },
          }
        );
      }
    }

    return true;
  };

  const handleRowClick = (record: BadgeInspectionUsers) => {
    navigate(`/users/${record.id}`);
  };

  // 쿼리 결과가 업데이트되면 로컬 상태 초기화
  useEffect(() => {
    if (useBadgeInspectionUsersQueryResult?.contents) {
      setInspectionStatuses({});
    }
  }, [useBadgeInspectionUsersQueryResult?.contents]);

  const columns: ColumnsType<BadgeInspectionUsers> = [
    {
      title: "매력 정보/기본 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>프로필/외모</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    handleFilterChange({ appearanceRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "profile",
          width: 240,
          align: "center",
          render: (_, record) => (
            <div onClick={(e) => e.stopPropagation()}>
              <ProfileDisplay
                representationImages={record.userRepresentativeImages}
                profileImage={record.userProfileImages}
                videos={record.userVideos}
                videoThumbnails={record.userVideoThumbnails}
                appearanceRating={record.appearanceRating}
              />
            </div>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>이름 / 성별 / 나이 / 키</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.gender}
                  onChange={(value) => handleFilterChange({ gender: value })}
                >
                  {Object.values(FILTER_OPTIONS.GENDER).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "basic info",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.name}({record.gender === "남자" ? "남" : "여"})
              </div>
              <div>{record.age}세</div>
              <div>{record.height}cm</div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "능력 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>직업</TitleWrapper>
              <Space>
                <Select
                  placeholder="능력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.overallSocialRating}
                  onChange={(value) =>
                    handleFilterChange({ overallSocialRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "career",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag
                color={getTagColor(
                  record.overallSocialRating,
                  "OVERALL_SOCIAL_RATING"
                )}
              >
                능력 {record.overallSocialRating}
              </Tag>
              <div>{record.careerDetail}</div>
              <div>{record.careerSubGroup}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>연봉</div>
            </div>
          ),
          key: "income",
          width: 160,
          align: "center",
          render: (_, record) => {
            // 현재 income 값을 문자열로 변환합니다
            const currentIncomeValue =
                // userRatings[record.id]?.income ||
                convertIncomeNumberToString(record.income);

            return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Space direction="vertical">
                    <Select
                        placeholder="연봉"
                        size="small"
                        style={{width: 160}}
                        value={currentIncomeValue}
                        onChange={(value) => {
                          // value 값이 INCOME enum에서 온 것인지 확인하고 적절히 처리합니다
                          // 중요: handleRatingChange에는 레이블이 아닌 value 값을 전달합니다
                          // handleRatingChange(
                          //     value,
                          //     currentIncomeValue,
                          //     record.id,
                          //     record.name,
                          //     "income"
                          // );
                        }}
                    >
                      {Object.values(FILTER_OPTIONS.INCOME)
                          .filter(
                              (value) =>
                                  value !== FILTER_OPTIONS.INCOME.ALL
                          )
                          .map(
                              ({value, label, description}: PreferenceOption) => (
                                  <Select.Option key={value} value={value}>
                                    <div className="select-option-content">
                                      {description ? (
                                          <Tooltip title={description} placement="right">
                                            <div>{label}</div>
                                          </Tooltip>
                                      ) : (
                                          <div>{label}</div>
                                      )}
                                    </div>
                                  </Select.Option>
                              )
                          )}
                    </Select>
                    <div>
                      {record.income != null
                          ? formatKoreanMoney(record.income)
                          : "-"}
                    </div>
                  </Space>
                </div>
            );
          },
        },
        {
          title: () => (
            <div>
              <div>학력</div>
              <Space>
                <Select
                  placeholder="학력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.univRating}
                  onChange={(value) =>
                    handleFilterChange({ univRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "univ",
          width: 160,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag color={getTagColor(record.univRating, "UNIV_RATING")}>
                학력 {record.univRating}
              </Tag>
              <div>{record.univName ?
                    record.univName :
                    (record.finalAcademic ? record.finalAcademic : "-")}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>인증 정보</div>
            </div>
          ),
          key: "career",
          width: 216,
          align: "center",
          render: (_, record) => {
            // 현재 사용자의 인증 뱃지 상태를 가져옴 (없으면 빈 객체)
            const userStatuses = inspectionStatuses[record.id] || {};

            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Space direction="vertical">
                  <InspectionBadgeStatusUpdate
                    label="직업"
                    inspectionId={record.careerInspectionId}
                    status={
                      userStatuses["career"] || record.careerInspectionStatus
                    }
                    inspectionImages={record.careerInspectionImages}
                    onStatusChange={(newStatus) =>
                      handleInspectionStatusChange(
                        record.id,
                        "career",
                        newStatus
                      )
                    }
                  />
                  <InspectionBadgeStatusUpdate
                    label="학력"
                    inspectionId={record.univInspectionId}
                    status={userStatuses["univ"] || record.univInspectionStatus}
                    inspectionImages={record.univInspectionImages}
                    onStatusChange={(newStatus) =>
                      handleInspectionStatusChange(record.id, "univ", newStatus)
                    }
                  />
                  <InspectionBadgeStatusUpdate
                    label="연봉"
                    inspectionId={record.incomeInspectionId}
                    status={
                      userStatuses["income"] || record.incomeInspectionStatus
                    }
                    inspectionImages={record.incomeInspectionImages}
                    onStatusChange={(newStatus) =>
                      handleInspectionStatusChange(
                        record.id,
                        "income",
                        newStatus
                      )
                    }
                  />
                </Space>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "이상형 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>이상형 정보</div>
              <Select
                placeholder="유저 타입"
                size="small"
                style={{ width: 120 }}
                value={filter.preferenceType}
                onChange={(value) =>
                  handleFilterChange({ preferenceType: value })
                }
              >
                {Object.values(FILTER_OPTIONS.PREFERENCE_TYPE).map(
                  ({ value, label, description }: PreferenceOption) => (
                    <Select.Option key={value} value={value}>
                      <div className="select-option-content">
                        {description ? (
                          <Tooltip title={description} placement="right">
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          <div>{label}</div>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          ),
          key: "preferenceType",
          align: "center",
          width: 200,
          render: (_, record) => (
            <Space direction="vertical">
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>타입</b>
                </div>
                <Tag
                  color={getTagColor(record.preferenceType, "PREFERENCE_TYPE")}
                >
                  {record.preferenceType}
                </Tag>
              </div>
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>나이</b>
                </div>
                {Number(record.age) - Number(record.partnerUnderAge)} ~{" "}
                {Number(record.age) + Number(record.partnerUpperAge)}세
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>키</b>
                </div>
                {record.partnerHeightRange[0].includes("-")
                  ? `${record.partnerHeightRange[0].replace("-", "")}`
                  : `${record.partnerHeightRange[0]}`}{" "}
                ~{" "}
                {record.partnerHeightRange[1].includes("+")
                  ? `${record.partnerHeightRange[1].replace("+", "")}cm`
                  : `${record.partnerHeightRange[1]}cm`}
              </div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "심사 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>결과 발송</div>
            </div>
          ),
          key: "sendInspectionResult",
          align: "center",
          width: 160,
          render: (_, record) => {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Space
                  direction="vertical"
                  style={{
                    gap: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    disabled={checkAllInspectionsProcessed(record.id, record)}
                    style={
                      {
                        borderRadius: "5px",
                        boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
                        ":hover": {
                          backgroundColor: "#6DAF3B !important",
                          borderColor: "#6DAF3B !important",
                        },
                        ":disabled": {
                          backgroundColor: "#f0f0f0 !important",
                          borderColor: "#f0f0f0 !important",
                        },
                      } as React.CSSProperties
                    }
                    onClick={() => {
                      handleOpenApprovalModal(record.id, record.name);
                    }}
                  >
                    결과 발송
                  </Button>
                </Space>
              </div>
            );
          },
        },
        {
          title: () => (
            <div>
              <div>요청 일시</div>
            </div>
          ),
          key: "inspectionResult",
          align: "center",
          width: 160,
          render: (_, record) => {
            const formatDateTime = (dateString: string) => {
              try {
                const date = new Date(dateString);

                if (isNaN(date.getTime())) {
                  return "-";
                }

                // 날짜와 시간을 별도로 포맷팅합니다
                const dateStr = date
                  .toLocaleDateString("ko-KR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\. /g, "-")
                  .slice(0, -1);

                const timeStr = date.toLocaleTimeString("ko-KR", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                });

                // 두 줄로 된 날짜와 시간을 반환합니다
                return (
                  <div className="text-center">
                    <div>{dateStr}</div>
                    <div>{timeStr}</div>
                  </div>
                );
              } catch (error) {
                console.error("날짜 포맷팅 오류:", error);
                return "-";
              }
            };

            return (
              <Space direction="vertical">
                <div>{formatDateTime(record.requestTime)}</div>
              </Space>
            );
          },
        },
      ],
    },
  ];
  return (
    <AdminLayout>
      <StyledCard
        title={
          <Space
            size="middle"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <Space
              size="small"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "22px" }}>
                가입 후의 인증 뱃지 심사하기
              </span>
              <span style={{ fontSize: "22px", color: "#618DEF" }}>
                현재
                {useBadgeInspectionUsersQueryResult?.pagination.totalElements ||
                  0}
                명
              </span>
            </Space>
            <RecommendationGlobalTableFilter
              filter={filter}
              onFilterChange={handleFilterChange}
              onReset={handleReset}
            />
          </Space>
        }
      >
        <Table
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // 클릭 이벤트
            style: { cursor: "pointer" }, // 커서 스타일
          })}
          columns={columns}
          dataSource={useBadgeInspectionUsersQueryResult?.contents || []}
          scroll={{
            x: calculateColumnsTotalWidth(columns),
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            current: filter.page + 1,
            pageSize: filter.size,
            total:
              useBadgeInspectionUsersQueryResult?.pagination.totalElements || 0,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showSizeChanger: true,
            showQuickJumper: true,
            style: { margin: "16px 0" }, // 페이지네이션 여백 추가
          }}
          bordered={true}
          style={
            {
              "--ant-table-border-color": "#f0f0f0", // 테두리 색상 변경
            } as React.CSSProperties
          }
        />

        <Modal
          // ratingType에 따라 타이틀이 동적으로 변경됩니다
          title={`${
            updateStateModalState.selectedRecord
              ? ratingTypeLabels[
                  updateStateModalState.selectedRecord.ratingType
                ]
              : ""
          } 등급 변경`}
          open={updateStateModalState.isOpen}
          onCancel={() =>
            setUpdateStateModalState((prev) => ({ ...prev, isOpen: false }))
          }
          footer={[
            <Button
              key="cancel"
              onClick={() =>
                setUpdateStateModalState((prev) => ({ ...prev, isOpen: false }))
              }
            >
              취소
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={async () => {
                if (!updateStateModalState.selectedRecord) return;

                const {
                  id: userId,
                  ratingType,
                  newValue,
                } = updateStateModalState.selectedRecord;

                // API 요청 파라미터 생성
                const params: RatingParams = { userId };

                // 각 등급 유형에 따라 적절한 파라미터 설정
                switch (ratingType) {
                  case "appearance":
                    params.appearanceRating = newValue;
                    break;
                  case "overallSocial":
                    params.overallSocialRating = newValue;
                    break;
                  case "univ":
                    params.univRating = newValue;
                    break;
                  case "income":
                    params.incomeRating = newValue;
                    break;
                }
                setUpdateStateModalState((prev) => ({
                  ...prev,
                  isOpen: false,
                }));
              }}
            >
              변경
            </Button>,
          ]}
          width={400}
          centered
          closable={true}
          closeIcon={<CloseOutlined />}
        >
          {updateStateModalState.selectedRecord && (
            <div>
              {updateStateModalState.selectedRecord.name} 님의{" "}
              {
                ratingTypeLabels[
                  updateStateModalState.selectedRecord.ratingType
                ]
              }{" "}
              등급을
              <br />'{updateStateModalState.selectedRecord.currentValue}' → '
              {getRatingLabel(updateStateModalState.selectedRecord.newValue)}'
              으로 변경할까요?
            </div>
          )}
        </Modal>

        <InspectionResultSendModal
          isOpen={signUpApprovalModalState.isOpen}
          selectedUser={signUpApprovalModalState.selectedUser}
          onClose={handleCloseApprovalModal}
          onApprove={handleApproveInspectionBadges}
        />
      </StyledCard>
    </AdminLayout>
  );
};

const StyledCard = styled(Card)`
  .ant-table-thead > tr:first-child > th {
    background-color: #97b4f4 !important;
  }

  .ant-table-thead > tr:nth-child(2) > th {
    background-color: #cbdafa !important;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default InspectionsBadge;
