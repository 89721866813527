import React from 'react';
import {Button, Col, Image, Modal, Row, Typography} from 'antd';

const { Text } = Typography;

// 모달 컴포넌트의 props 타입 정의
interface InspectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onApprove?: () => void;
    onReject?: () => void;
    label: string;
    inspectionImages: string[] | null;
}

export const InspectionBadgeDisplayModal: React.FC<InspectionModalProps> = ({
                                                                    isOpen,
                                                                    onClose,
                                                                    label,
                                                                    inspectionImages
                                                                }) => {
    // 모달에서 사용할 푸터 버튼들
    const modalFooter = [
        <Button key="cancel" onClick={onClose}>
            닫기
        </Button>
    ];

    return (
        <Modal
            title={`${label} 인증`}
            open={isOpen}
            onCancel={onClose}
            footer={modalFooter}
            width="auto"
            centered
            style={{
                maxWidth: '90vw',
                minWidth: '300px'
            }}
        >
            <div>
                <Text>인증 정보 확인</Text>
                <Row gutter={[16, 16]} justify="center">
                    {(inspectionImages || []).map((image, index) => (
                        <Col
                            key={index}
                            span={24}
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Image
                                src={image}
                                alt={`이미지 ${index + 1}`}
                                style={{
                                    width: '400px',
                                    height: 'auto'
                                }}
                                preview={{
                                    width: 'auto',
                                    height: 'auto',
                                    mask: <div>클릭하여 크게보기</div>,
                                }}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </Modal>
    );
};