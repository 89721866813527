import {useCallback, useEffect, useRef, useState} from "react";
import {ModalEvent, modalEventManager, RenderModalProps,} from "../helper/modal";

export const useModal = () => {
  const [modalList, setModalList] = useState<string[]>([]);
  const modalToRender = useRef(
    new Map<string, (modalProps: RenderModalProps) => JSX.Element>()
  ).current;

  const isModalOpen = (id: string) => {
    return modalList.includes(id);
  };

  const openModal = useCallback(
    (
      id: string,
      renderModal: (modalProps: RenderModalProps) => JSX.Element
    ) => {
      modalToRender.set(id, renderModal);
      setModalList((prev) => [...prev, id]);
    },
    [modalToRender]
  );

  const closeModal = useCallback(
    (id: string) => {
      modalToRender.delete(id);
      setModalList((prev) => prev.filter((modalId) => modalId !== id));
    },
    [modalToRender]
  );

  useEffect(() => {
    modalEventManager.on(
      ModalEvent.OPEN,
      (
        id: string,
        renderModal: (modalProps: RenderModalProps) => JSX.Element
      ) => {
        openModal(id, renderModal);
      }
    );

    return () => {
      modalEventManager.off(ModalEvent.OPEN);
    };
  }, [openModal]);

  function renderModal<T>(
    cb: (
      id: string,
      renderModal: (modalProps: RenderModalProps) => JSX.Element
    ) => T
  ) {
    const toRender = Array.from(modalToRender.entries()).map(
      ([id, renderModal]) => cb(id, renderModal)
    );
    return toRender;
  }

  return { isModalOpen, closeModal, renderModal };
};
