import React, { useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Flex,
  Image,
  Radio,
  RadioChangeEvent,
} from "antd";
import {
  CareerInspection,
  putCareerInspection,
  Rating,
} from "../../../utils/api/user";
import styled from "@emotion/styled";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Props {
  careerData: CareerInspection;
  openNotificationWithIcon: (name: string) => void;
  onChangeLoading: (loading: boolean) => void;
}

const CareerInspectionCard = ({
  careerData,
  openNotificationWithIcon,
  onChangeLoading,
}: Props) => {
  const [careerLevel, setCareerLevel] = useState<Rating | null>(null);
  const onChangeCareerLevel = (e: RadioChangeEvent) => {
    setCareerLevel(e.target.value);
  };
  const qc = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: () =>
      putCareerInspection({
        careerInspectionId: careerData.careerInspectionId,
        careerRating: careerLevel ?? "FIFTH_GRADE",
      }),
    onSuccess: () => {
      openNotificationWithIcon(careerData.userProfileData.userProfileName);
      qc.invalidateQueries({ queryKey: ["career"] });
      setTimeout(() => {
        onChangeLoading(false);
      }, 800);
    },
    onError: () => {
      setTimeout(() => {
        onChangeLoading(false);
      }, 800);
    },
    onMutate: () => {
      onChangeLoading(true);
    },
  });

  const convertGradeToKorean = (grade: string | null) => {
    switch (grade) {
      case null:
        return "";
      case "FIRST_GRADE":
        return "상";
      case "SECOND_GRADE":
        return "중상";
      case "THIRD_GRADE":
        return "중";
      case "FOURTH_GRADE":
        return "중하";
      case "UN_RANK":
        return "등급 제외";
      default:
        return "상";
    }
  };
  const {
    careerDetailName,
    careerGroupName,
    userGender,
    userProfileData,
    age,
    careerCertificationStatus,
    careerSubGroupName,
    proofImages,
    status,
  } = careerData;
  return (
    <Card style={{ width: "100%" }}>
      <Flex gap="middle">
        <Descriptions column={1}>
          <Descriptions.Item label="유저 id">
            {userProfileData.userProfileId}
          </Descriptions.Item>
          <Descriptions.Item label="이름">
            {userProfileData.userProfileName}
          </Descriptions.Item>
          <Descriptions.Item label="상태">{status}</Descriptions.Item>
          <Descriptions.Item label="성별">{userGender}</Descriptions.Item>
          <Descriptions.Item label="나이">{age}</Descriptions.Item>
          <Descriptions.Item label="직업명">
            {careerSubGroupName}
          </Descriptions.Item>
          <Descriptions.Item label="직업명">
            {careerDetailName}
          </Descriptions.Item>
          <Descriptions.Item label="직업군">
            {careerGroupName}
          </Descriptions.Item>
          <Descriptions.Item label="">
            {careerCertificationStatus}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item>
            {proofImages.map((imgSrc) => (
              <Image src={imgSrc} width={300} height={300} />
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
      <Radio.Group
        size="large"
        buttonStyle="solid"
        onChange={onChangeCareerLevel}
        value={careerLevel}
      >
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FIRST_GRADE"}
        >
          상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"SECOND_GRADE"}
        >
          중상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"THIRD_GRADE"}
        >
          중
        </Radio.Button>{" "}
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FOURTH_GRADE"}
        >
          중하
        </Radio.Button>
        <Radio.Button
          style={{
            width: 100,
            textAlign: "center",
          }}
          value={"UN_RANK"}
        >
          등급제외
        </Radio.Button>
      </Radio.Group>
      <SubmitContainer>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          disabled={careerLevel === null}
          size="large"
          onClick={() => mutate()}
        >
          <b>{convertGradeToKorean(careerLevel)}</b>(으)로 분류
        </Button>
      </SubmitContainer>
    </Card>
  );
};

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
`;

export default CareerInspectionCard;
