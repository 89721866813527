import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Space, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import apiClient from "../../../../utils/api/apiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const { Title, Text } = Typography;

interface ImageSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  userId: string;
}

interface UpdateImageParams {
  userId: string;
  representationImageUrl: string;
}

const ImageSelectionModal: React.FC<ImageSelectionModalProps> = ({
  isOpen,
  onClose,
  images = [],
  userId,
}) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const queryClient = useQueryClient();

  const updateRepresentationImageMutation = useMutation({
    mutationFn: (params: UpdateImageParams) => {
      // URL 파라미터를 구성합니다
      const queryParams = new URLSearchParams();
      queryParams.append(
        "representationImageUrl",
        params.representationImageUrl
      );

      // API 호출을 수행합니다
      return apiClient.patch(
        `/v1/admin/inspections/users/${
          params.userId
        }/images?${queryParams.toString()}` // '/images' 경로 추가
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["signup-inspections"] });
      queryClient.invalidateQueries({ queryKey: ["image-inspections"] });
      onClose();
    },
    onError: (error) => {
      console.error("Image update failed:", error);
    },
  });

  useEffect(() => {
    if (isOpen && images.length > 0) {
      setSelectedImage(0);
    }
  }, [isOpen, images]);

  // 이미지 선택 함수를 단일 선택 방식으로 변경
  const handleImageSelection = (index: number) => {
    setSelectedImage(index);
  };

  // 선택 완료 핸들러
  const handleConfirm = () => {
    if (selectedImage !== null && images[selectedImage]) {
      updateRepresentationImageMutation.mutate({
        userId,
        representationImageUrl: images[selectedImage],
      });
    }
  };

  return (
    <Modal
      title={
        <Space
          direction="vertical"
          align="center"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Title level={5} style={{ margin: 0 }}>
            썸네일 사진 선택
          </Title>
          <Text type="secondary">
            썸네일에 메인으로 보일 프로필 사진을 1장 선택해주세요.
            <br />
            미선택 시 첫번째 사진으로 자동 선택됩니다.
          </Text>
        </Space>
      }
      open={isOpen}
      width={500}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          style={{
            borderRadius: "8px",
            width: "80px",
          }}
        >
          취소
        </Button>,
        <Button
          key="confirm"
          type="primary"
          style={{
            backgroundColor: "#6B7AFF",
            borderRadius: "8px",
            width: "120px",
          }}
          onClick={handleConfirm}
          // 이미지가 선택되지 않았을 때는 버튼 비활성화
          disabled={selectedImage === null}
        >
          썸네일 선택
        </Button>,
      ]}
      onCancel={onClose}
    >
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        {images.map((image, index) => (
          <div
            key={index}
            onClick={() => handleImageSelection(index)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
              padding: "8px",
            }}
          >
            {/* 선택 원형 버튼 */}
            <div
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                border: "1px solid #6B7AFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedImage === index ? "#6B7AFF" : "white",
                flexShrink: 0,
              }}
            >
              {selectedImage === index && (
                <CheckCircleOutlined
                  style={{
                    color: "white",
                    fontSize: "16px",
                  }}
                />
              )}
            </div>

            {/* 이미지 컨테이너 */}
            <div
              style={{
                flex: 1,
                borderRadius: "8px",
                overflow: "hidden",
                lineHeight: 0,
                fontSize: 0,
              }}
            >
              <Image
                src={image}
                alt={`썸네일 ${index + 1}`}
                style={{
                  width: "100%",
                  display: "block",
                }}
                preview={false}
                wrapperClassName="ant-image-no-background"
              />
            </div>
          </div>
        ))}
      </Space>
    </Modal>
  );
};

// 전역 스타일 유지
const style = document.createElement("style");
style.innerHTML = `
    .ant-image-no-background .ant-image-mask {
        background: none !important;
    }
    .ant-image {
        background: none !important;
    }
`;
document.head.appendChild(style);

export default ImageSelectionModal;
