/* eslint-disable @typescript-eslint/no-explicit-any */
type Callback = (...args: any[]) => void;

export class EventManager<T> {
  list: Map<T, Callback> = new Map();

  emit(event: T, ...args: Parameters<Callback>) {
    if (this.list.has(event)) {
      this.list.get(event)!(...args);
    }
  }

  off(event: T): EventManager<T> {
    if (this.list.has(event)) {
      this.list.delete(event);
    }
    return this;
  }

  on(event: T, callback?: Callback): EventManager<T> {
    if (!this.list.has(event)) {
      this.list.set(event, callback ?? (() => {}));
    }
    return this;
  }
}
