import React, { useCallback, useEffect, useState } from "react";
import { Card, Select, Space, Table, TableProps, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import {
  getRecommendedUsers,
  Recommendation,
  RecommendedUser,
  RecommendedUsersResponse,
} from "../../utils/api/recommendation/recommend";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MatchLayout from "../../common/components/AdminLayout";
import RecommendationGlobalTableFilter from "./components/RecommendationGlobalTableFilter";
import ProfileDisplay from "./components/ProfileDisplay";
import { Pagination } from "../../utils/api/pagination";

interface RecommendedUsersQueryResult {
  contents: RecommendedUser[];
  pagination: Pagination;
}

interface GroupedRecommendations {
  [key: string]: Recommendation[];
}

export interface PreferenceOption {
  value: string | number | null;
  label: string;
  description?: string | null;
}

export const FILTER_OPTIONS = {
  APPEARANCE_RATING: {
    ALL: { value: "", label: "전체" },
    FIRST_GRADE: { value: "FIRST_GRADE", label: "상" },
    SECOND_GRADE: { value: "SECOND_GRADE", label: "중상" },
    THIRD_GRADE: { value: "THIRD_GRADE", label: "중" },
    BEFORE_RANK: { value: "BEFORE_RANK", label: "등급 분류 전" },
  },
  OVERALL_SOCIAL_RATING: {
    ALL: { value: "", label: "전체" },
    FIRST_GRADE: { value: "FIRST_GRADE", label: "1등급" },
    SECOND_GRADE: { value: "SECOND_GRADE", label: "2등급" },
    THIRD_GRADE: { value: "THIRD_GRADE", label: "3등급" },
    FOURTH_GRADE: {
      value: "FOURTH_GRADE",
      label: "4등급",
      description: "여성 외모 상,중상의 경우 => 능력 4,5등급도 허용",
    },
    FIFTH_GRADE: {
      value: "FIFTH_GRADE",
      label: "5등급",
      description: "여성 외모 상,중상의 경우 => 능력 4,5등급도 허용",
    },
    BEFORE_RANK: { value: "BEFORE_RANK", label: "등급 분류 전" },
  },
  UNIV_RATING: {
    ALL: { value: "", label: "전체" },
    FIRST_GRADE: { value: "FIRST_GRADE", label: "1등급" },
    SECOND_GRADE: { value: "SECOND_GRADE", label: "2등급" },
    THIRD_GRADE: { value: "THIRD_GRADE", label: "3등급" },
    FOURTH_GRADE: { value: "FOURTH_GRADE", label: "4등급" },
    FIFTH_GRADE: { value: "FIFTH_GRADE", label: "5등급" },
    BEFORE_RANK: { value: "BEFORE_RANK", label: "등급 분류 전" },
  },
  PREFERENCE_TYPE: {
    ALL: { value: "", label: "전체" },
    NORMAL: {
      value: "NORMAL",
      label: "외모형/능력형",
      description: "남성: 외모 중심 선호 / 여성: 능력 중심 선호",
    },
    BALANCE: {
      value: "BALANCE",
      label: "밸런스형",
      description: "밸런스 중심(능력+학력+외모)",
    },
  },
  GENDER: {
    ALL: { value: "", label: "전체" },
    MALE: { value: "MALE", label: "남" },
    FEMALE: { value: "FEMALE", label: "여" },
  },
  INCOME: {
    ALL: { value: "", label: "전체" },
    NONE: { value: "0", label: "연봉 없음" },
    UNDER_30M: { value: 25000000, label: "연 3,000만원 미만" },
    OVER_30M: { value: 30000000, label: "연 3,000만원 이상" },
    OVER_40M: { value: 40000000, label: "연 4,000만원 이상" },
    OVER_50M: { value: 50000000, label: "연 5,000만원 이상" },
    OVER_60M: { value: 60000000, label: "연 6,000만원 이상" },
    OVER_70M: { value: 70000000, label: "연 7,000만원 이상" },
    OVER_80M: { value: 80000000, label: "연 8,000만원 이상" },
    OVER_90M: { value: 90000000, label: "연 9,000만원 이상" },
    OVER_1B: { value: 100000000, label: "연 1억원 이상" },
    OVER_2B: { value: 200000000, label: "연 2억원 이상" },
    OVER_3B: { value: 300000000, label: "연 3억원 이상" },
    OVER_5B: { value: 500000000, label: "연 5억원 이상" },
    OVER_10B: { value: 1000000000, label: "연 10억원 이상" },
    BEFORE_RANK: { value: "BEFORE_RANK", label: "등급 분류 전" },
  },
  RECOMMENDATION_STATUS: {
    ALL: { value: "", label: "전체" },
    SUCCESS: { value: "true", label: "성공" },
    FAIL: { value: "false", label: "실패" },
  },
  USER_STATUS: {
    ALL: { value: "", label: "전체", color: undefined },
    RECOMMENDATION_ACTIVE: {
      value: "RECOMMENDATION_ACTIVE",
      label: "활성화",
      color: "cyan",
    },
    RECOMMENDATION_PAUSE: {
      value: "RECOMMENDATION_PAUSE",
      label: "일시정지",
      color: undefined,
    },
    RECOMMENDATION_PAUSE_BEFORE_MEET_REVIEW: {
      value: "RECOMMENDATION_PAUSE_BEFORE_MEET_REVIEW",
      label: "후기작성 전 (일시정지)",
      color: "purple",
    },
    RECOMMENDATION_PAUSE_CAREER_CERTIFICATE_FAIL: {
      value: "RECOMMENDATION_PAUSE_CAREER_CERTIFICATE_FAIL",
      label: "직업인증 실패 (일시정지)",
      color: "red",
    },
    DORMANT: { value: "DORMANT", label: "휴면", color: undefined },
  },
};

export const getTagColor = (type: string, category: string) => {
  switch (category) {
    case "APPEARANCE_RATING":
      switch (type) {
        case "상":
          return "success";
        case "중상":
          return "lime";
        case "중":
          return "warning";
        default:
          return "default";
      }

    case "OVERALL_SOCIAL_RATING":
      switch (type) {
        case "1등급":
          return "success"; // 진한 초록색
        case "2등급":
          return "lime"; // 연두색
        case "3등급":
          return "warning"; // 노란색
        case "4등급":
          return "orange"; // 주황색
        case "5등급":
          return "error"; // 빨간색
        default:
          return "default";
      }

    case "UNIV_RATING":
      switch (type) {
        case "1등급":
          return "success"; // 진한 초록색
        case "2등급":
          return "lime"; // 연두색
        case "3등급":
          return "warning"; // 노란색
        case "4등급":
          return "orange"; // 주황색
        case "5등급":
          return "error"; // 빨간색
        default:
          return "default";
      }

    case "PREFERENCE_TYPE":
      switch (type) {
        case "외모형":
          return "gold";
        case "능력형":
          return "gold";
        case "밸런스형":
          return "processing";
        default:
          return "default";
      }

    default:
      return "default";
  }
};

export function formatKoreanMoney(amount: number) {
  // 금액이 0이거나 유효하지 않은 경우 처리
  if (!amount) return "0원";

  // 한국어 단위 정의
  const units = ["", "만", "억", "조"];
  const numbers = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];

  // 숫자를 문자열로 변환하고 절대값 사용
  const numStr = Math.abs(amount).toString();

  // 4자리씩 끊어서 배열로 변환
  const groups = [];
  for (let i = numStr.length; i > 0; i -= 4) {
    groups.unshift(numStr.slice(Math.max(0, i - 4), i));
  }

  let result = "";
  groups.forEach((group, index) => {
    const unit = units[groups.length - 1 - index];
    const groupNum = parseInt(group);

    if (groupNum !== 0) {
      // 1000 단위 처리
      if (groupNum >= 1000) {
        const thousand = Math.floor(groupNum / 1000);
        result += (thousand === 1 ? "" : numbers[thousand]) + "천";
      }

      // 100 단위 처리
      if (groupNum % 1000 >= 100) {
        const hundred = Math.floor((groupNum % 1000) / 100);
        result += (hundred === 1 ? "" : numbers[hundred]) + "백";
      }

      // 10 단위 처리
      if (groupNum % 100 >= 10) {
        const ten = Math.floor((groupNum % 100) / 10);
        result += (ten === 1 ? "" : numbers[ten]) + "십";
      }

      // 1 단위 처리
      if (groupNum % 10 > 0) {
        result += numbers[groupNum % 10];
      }

      result += unit;
    }
  });

  return result + "원";
}

type TableColumns = NonNullable<TableProps["columns"]>;

export const calculateColumnsTotalWidth = (columns: TableColumns): number => {
  const getColumnsWidth = (cols: TableColumns): number => {
    return cols.reduce((total: number, column) => {
      // title이 객체이고 children이 있는 경우 (그룹 컬럼)
      if (typeof column === "object" && "children" in column) {
        return total + getColumnsWidth(column.children);
      }
      // width가 없는 경우 기본값 100px 적용
      return total + (Number(column.width) || 100);
    }, 0);
  };

  return getColumnsWidth(columns);
};

const RecommendationList: React.FC = () => {
  const { weekValue, weekId } = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // URL에서 초기 필터 값을 가져오는 함수
  const getInitialFilter = useCallback(() => {
    return {
      gender: searchParams.get("gender") || "",
      name: searchParams.get("name") || "",
      page: parseInt(searchParams.get("page") || "0"),
      size: parseInt(searchParams.get("size") || "10"),
      overallSocialRating: searchParams.get("overallSocialRating") || "",
      appearanceRating: searchParams.get("appearanceRating") || "",
      univRating: searchParams.get("univRating") || "",
      preferenceType: searchParams.get("preferenceType") || "",
      userId: searchParams.get("userId") || "",
      isRecommendationSuccess:
        searchParams.get("isRecommendationSuccess") || "",
    };
  }, [searchParams]);

  const [filter, setFilter] = useState(getInitialFilter());

  // 필터 변경시 URL 업데이트
  const updateURLParameters = (newFilter: typeof filter) => {
    const params = new URLSearchParams();
    Object.entries(newFilter).forEach(([key, value]) => {
      if (value !== "") {
        params.set(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  const handleFilterChange = (values: Partial<typeof filter>) => {
    const newFilter = {
      ...filter,
      ...values,
      page: 0, // 필터 변경 시 첫 페이지로 이동
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const newFilter = {
      ...filter,
      page: page - 1,
      size: pageSize || filter.size,
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handleReset = () => {
    const resetFilter = {
      gender: "",
      name: "",
      page: 0,
      size: 10,
      overallSocialRating: "",
      appearanceRating: "",
      univRating: "",
      preferenceType: "",
      userId: "",
      isRecommendationSuccess: "",
    };
    setFilter(resetFilter);
    // URL 파라미터 초기화
    setSearchParams({});
  };

  // URL 변경 감지하여 필터 상태 업데이트
  useEffect(() => {
    const currentFilter = getInitialFilter();
    setFilter(currentFilter);
  }, [getInitialFilter]);

  const { data: recommendationUserQueryResult } = useQuery<
    RecommendedUsersResponse,
    Error,
    RecommendedUsersQueryResult
  >({
    queryKey: ["matching-users", filter],
    queryFn: () => {
      // TypeScript에게 weekId가 존재함을 확실히 알려줍니다
      if (!weekId) {
        throw new Error("WeekId is required");
      }

      return getRecommendedUsers({
        ...filter,
        weekId: weekId, // 이제 이 weekId는 확실히 string 타입입니다
      });
    },
    select: (data) => ({
      contents: data.data.contents,
      pagination: data.data.pagination,
    }),
  });

  const handleRowClick = (record: RecommendedUser) => {
    navigate(
      `/recommendations/${weekValue}/weeks/${weekId}/users/${record.id}`
    );
  };

  const columns: ColumnsType<RecommendedUser> = [
    {
      title: "추천 정보",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>주차</TitleWrapper>
            </div>
          ),
          key: "week",
          width: 100,
          align: "center",
          render: (_, record) => (
            <div>
              <Tag>{record.weeksInRecommendation}</Tag>
            </div>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>추천 완료</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.isRecommendationSuccess}
                  onChange={(value) =>
                    handleFilterChange({ isRecommendationSuccess: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.RECOMMENDATION_STATUS).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "isFirstRecommendationDone",
          width: 100,
          align: "center",
          render: (_, record) => (
            <div>
              {record.receivedRecommendations.length >= 8 ? (
                <Tag color="#87d068">성공</Tag>
              ) : (
                <Tag color="#f50">실패</Tag>
              )}
            </div>
          ),
        },
        {
          title: () => (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div>추천 현황</div>
              <div style={{ fontSize: "12px", color: "#808080" }}>
                추천 수 / 가능 수
              </div>
            </div>
          ),
          key: "Recommendations",
          width: 250,
          align: "center",
          render: (_, record) => {
            // 미끼 추천 정보를 레벨별로 그룹화
            const upperLevelGrouped =
              record.receivedRecommendations.reduce<GroupedRecommendations>(
                (acc, curr) => {
                  const level = curr.recommendationLevel;
                  acc[level] = acc[level] || [];
                  acc[level].push(curr);
                  return acc;
                },
                {}
              );

            // 추천 정보를 레벨별로 그룹화
            const sameLevelGrouped =
              record.receivedRecommendations.reduce<GroupedRecommendations>(
                (acc, curr) => {
                  const level = curr.recommendationLevel;
                  acc[level] = acc[level] || [];
                  acc[level].push(curr);
                  return acc;
                },
                {}
              );

            // 각 추천 타입별로 컴포넌트를 생성합니다
            const renderRecommendationRow = (
              label: string,
              count: number,
              range: string
            ) => (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "80px 1fr",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    color: "#000000", // 레이블 색상을 검정색으로 변경
                    fontWeight: "bold",
                  }}
                >
                  {label}
                </div>
                <div
                  style={{
                    textAlign: "left", // 값을 왼쪽 정렬로 변경
                  }}
                >
                  <b>{count}</b> / {range}
                </div>
              </div>
            );

            return (
              <Space direction="vertical" size={8}>
                {renderRecommendationRow(
                  "미끼",
                  upperLevelGrouped["미끼 추천"]?.length || 0,
                  "0~2"
                )}
                {renderRecommendationRow(
                  "동급 괜찮은",
                  sameLevelGrouped["동급 괜찮은 추천"]?.length || 0,
                  "3~4"
                )}
                {renderRecommendationRow(
                  "동급 아쉬운",
                  sameLevelGrouped["동급 아쉬운 추천"]?.length || 0,
                  "3~4"
                )}
              </Space>
            );
          },
        },
      ],
    },
    {
      title: "매력 정보/기본 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>프로필/외모</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    handleFilterChange({ appearanceRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "profile",
          width: 200,
          align: "center",
          render: (_, record) => (
            <div onClick={(e) => e.stopPropagation()}>
              <ProfileDisplay
                representationImages={record.userRepresentativeImages}
                profileImage={record.userProfileImages}
                videos={record.userVideos}
                videoThumbnails={record.userVideoThumbnails}
                appearanceRating={record.appearanceRating}
              />
            </div>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>이름 / 성별 / 나이 / 키</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.gender}
                  onChange={(value) => handleFilterChange({ gender: value })}
                >
                  {Object.values(FILTER_OPTIONS.GENDER).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "basic info",
          width: 200,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.name}({record.gender === "남자" ? "남" : "여"})
              </div>
              <div>{record.age}세</div>
              <div>{record.height}cm</div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "직업/학력 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>직업</TitleWrapper>
              <Space>
                <Select
                  placeholder="능력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.overallSocialRating}
                  onChange={(value) =>
                    handleFilterChange({ overallSocialRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "career",
          width: 180,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag
                color={getTagColor(
                  record.overallSocialRating,
                  "OVERALL_SOCIAL_RATING"
                )}
              >
                능력 {record.overallSocialRating}
              </Tag>
              <div>{record.careerDetail}</div>
              <div>{record.careerSubGroup}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>연봉</div>
            </div>
          ),
          key: "income",
          width: 150,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.income != null ? formatKoreanMoney(record.income) : "-"}
              </div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>학력</div>
              <Space>
                <Select
                  placeholder="학력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.univRating}
                  onChange={(value) =>
                    handleFilterChange({ univRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "univ",
          width: 180,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag color={getTagColor(record.univRating, "UNIV_RATING")}>
                학력 {record.univRating}
              </Tag>
              <div>{record.univName !== "" ? record.univName : "-"}</div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "성향 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>성향 정보</div>
            </div>
          ),
          key: "preference",
          width: 200,
          align: "center",
          render: (_, record) => (
            <Space
              direction="vertical"
              size={12}
              style={{ width: "100%", padding: "8px 0" }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ width: 60, fontWeight: "bold" }}>종교</div>
                <div>{record.religion ?? "-"}</div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ width: 60, fontWeight: "bold" }}>흡연</div>
                <div>{record.smokingStatus}</div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ width: 60, fontWeight: "bold" }}>음주</div>
                <div>{record.drinkingStatus}</div>
              </div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "이상형 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>이상형 정보</div>
              <Select
                placeholder="유저 타입"
                size="small"
                style={{ width: 120 }}
                value={filter.preferenceType}
                onChange={(value) =>
                  handleFilterChange({ preferenceType: value })
                }
              >
                {Object.values(FILTER_OPTIONS.PREFERENCE_TYPE).map(
                  ({ value, label, description }: PreferenceOption) => (
                    <Select.Option key={value} value={value}>
                      <div className="select-option-content">
                        {description ? (
                          <Tooltip title={description} placement="right">
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          <div>{label}</div>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          ),
          key: "preferenceType",
          align: "center",
          width: 200,
          render: (_, record) => (
            <Space direction="vertical">
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>타입</b>
                </div>
                <Tag
                  color={getTagColor(record.preferenceType, "PREFERENCE_TYPE")}
                >
                  {record.preferenceType}
                </Tag>
              </div>
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>나이</b>
                </div>
                {Number(record.age) - Number(record.partnerUnderAge)} ~{" "}
                {Number(record.age) + Number(record.partnerUpperAge)}세
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>키</b>
                </div>
                {record.partnerHeightRange[0].includes("-")
                  ? `${record.partnerHeightRange[0].replace("-", "")}`
                  : `${record.partnerHeightRange[0]}`}{" "}
                ~{" "}
                {record.partnerHeightRange[1].includes("+")
                  ? `${record.partnerHeightRange[1].replace("+", "")}cm`
                  : `${record.partnerHeightRange[1]}cm`}
              </div>
            </Space>
          ),
        },
      ],
    },
  ];
  function splitWeekText(text: string | any) {
    // '('를 기준으로 문자열을 나누고, 괄호 부분은 다시 추가합니다
    const [weekInfo, dateRange] = text.split("(");

    // dateRange에 '('를 다시 추가하여 원래 형식을 유지합니다
    return [weekInfo.trim(), `(${dateRange}`];
  }

  // 날짜 형식을 변환하는 함수
  const formatDateRange = (dateRange: string): string => {
    // 괄호 제거 및 날짜 분리
    const dates = dateRange.replace(/[()]/g, "").split("~");
    if (dates.length !== 2) return dateRange;

    const [start, end] = dates;

    // 현재 연도의 마지막 두 자리를 가져옵니다
    const year = new Date().getFullYear().toString().slice(-2);

    // 각 날짜에 연도를 추가하고 형식을 변경
    const formatDate = (date: string): string => {
      const [month, day] = date.split(".");
      return `${year}.${month.padStart(2, "0")}.${day.padStart(2, "0")}`;
    };

    return `(${formatDate(start)}-${formatDate(end)})`;
  };

  return (
    <MatchLayout>
      <StyledCard
        title={
          <Space
            size="middle"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <Space
              size="small"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "22px" }}>
                {splitWeekText(weekValue)[0]} 추천
                <span style={{ fontSize: "16px", color: "#666" }}>
                  {formatDateRange(splitWeekText(weekValue)[1])}
                </span>
              </span>
              <span style={{ fontSize: "22px", color: "#618DEF" }}>
                현재{" "}
                {recommendationUserQueryResult?.pagination.totalElements || 0}명
              </span>
            </Space>
            <RecommendationGlobalTableFilter
              filter={filter}
              onFilterChange={handleFilterChange}
              onReset={handleReset}
            />
          </Space>
        }
      >
        <Table
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // 클릭 이벤트
            style: { cursor: "pointer" }, // 커서 스타일
          })}
          columns={columns}
          dataSource={recommendationUserQueryResult?.contents || []}
          scroll={{
            x: calculateColumnsTotalWidth(columns),
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            current: filter.page + 1,
            pageSize: filter.size,
            total: recommendationUserQueryResult?.pagination.totalElements || 0,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showSizeChanger: true,
            showQuickJumper: true,
            style: { margin: "16px 0" }, // 페이지네이션 여백 추가
          }}
          bordered={true}
          style={
            {
              "--ant-table-border-color": "#f0f0f0", // 테두리 색상 변경
            } as React.CSSProperties
          }
        />
      </StyledCard>
    </MatchLayout>
  );
};

const StyledCard = styled(Card)`
  .ant-table-thead > tr:first-child > th {
    background-color: #97b4f4 !important;
  }

  .ant-table-thead > tr:nth-child(2) > th {
    background-color: #cbdafa !important;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default RecommendationList;
