import React from "react";
import { Button, Modal } from "antd";

interface InspectionStatusCheckUpModalProps {
  isOpen: boolean;
  userName: string;
  missingItems: {
    ratings: {
      appearance: boolean;
      overallSocial: boolean;
      univ: boolean;
      income: boolean;
    };
    inspections: {
      career: boolean;
      univ: boolean;
      income: boolean;
    };
  };
  onClose: () => void;
}

/**
 * 심사 승인/거절 전 필요한 설정을 확인하는 모달 컴포넌트
 *
 * @param isOpen 모달 표시 여부
 * @param userName 사용자 이름
 * @param missingItems 미설정된 항목 정보
 * @param onClose 모달 닫기 핸들러
 */
const InspectionStatusCheckUpModal: React.FC<
  InspectionStatusCheckUpModalProps
> = ({ isOpen, userName, missingItems, onClose }) => {
  const hasMissingRatings = Object.values(missingItems.ratings).some(Boolean);
  const hasMissingInspections = Object.values(missingItems.inspections).some(
    Boolean
  );

  // 미설정된 항목이 없으면 모달을 표시하지 않음
  if (!hasMissingRatings && !hasMissingInspections) return null;

  return (
    <Modal
      title="심사 승인/거절 불가"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="ok" type="primary" onClick={onClose}>
          확인
        </Button>,
      ]}
      centered
    >
      <div style={{ marginBottom: "20px" }}>
        <strong>{userName}</strong> 님의 가입 심사를 진행하기 위해 다음 항목을
        먼저 설정해주세요:
      </div>

      {hasMissingRatings && (
        <div style={{ marginBottom: "15px" }}>
          <h4 style={{ marginBottom: "8px" }}>미설정된 등급:</h4>
          <ul style={{ paddingLeft: "20px" }}>
            {missingItems.ratings.appearance && <li>외모 등급</li>}
            {missingItems.ratings.overallSocial && <li>능력 등급</li>}
            {missingItems.ratings.univ && <li>학력 등급</li>}
            {missingItems.ratings.income && <li>연봉 등급</li>}
          </ul>
        </div>
      )}

      {hasMissingInspections && (
        <div>
          <h4 style={{ marginBottom: "8px" }}>미처리된 인증:</h4>
          <ul style={{ paddingLeft: "20px" }}>
            {missingItems.inspections.career && <li>직업 인증</li>}
            {missingItems.inspections.univ && <li>학력 인증</li>}
            {missingItems.inspections.income && <li>연봉 인증</li>}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default InspectionStatusCheckUpModal;
