import React from "react";
import { Button, message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SignUpApprovalModalProps } from "../../../../utils/types/inspection/inspectionModal";

// 인증 심사 결과 전송 모달 컴포넌트
const InspectionImageResultSendApproveModal: React.FC<
  SignUpApprovalModalProps
> = ({ isOpen, selectedUser, onClose, onApprove }) => {
  const handleApprove = async () => {
    if (!selectedUser) {
      message.error("선택된 사용자 정보가 없습니다.");
      return;
    }

    try {
      const success = await onApprove(selectedUser.id); // 이제 TypeScript 오류가 발생하지 않습니다

      if (success) {
        message.success("인증 심사가 완료되었습니다.");
        onClose();
      } else {
        message.error("인증 심사 처리에 실패했습니다.");
      }
    } catch (error) {
      console.error("인증 심사처리 중 오류:", error);
      message.error("인증 심사 처리 중 오류가 발생했습니다.");
    }
  };

  return (
    <Modal
      title="사진 수정 요청 승인 반영"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={
            {
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              ":hover": {
                backgroundColor: "#6DAF3B !important", // 약간 어두운 녹색 (10% 정도 어둡게)
                borderColor: "#6DAF3B !important",
              },
            } as React.CSSProperties
          }
          onClick={handleApprove}
        >
          발송
        </Button>,
      ]}
      width={400}
      centered
      closable={true}
      closeIcon={<CloseOutlined />}
    >
      {selectedUser && (
        <div>
          {selectedUser.name}님의 사진을 프로필에 반영할까요?
          <br />
          승인 결과가 따로 알림톡으로 발송되지 않습니다
        </div>
      )}
    </Modal>
  );
};

export default InspectionImageResultSendApproveModal;
