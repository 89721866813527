import apiClient from "../apiClient";
import {PaginationResponse} from "../pagination";

// 추천 레벨 타입 Enum 정의
export enum RecommendationLevelType {
    SAME_LEVEL_PRIMARY = "SAME_LEVEL_PRIMARY",
    SAME_LEVEL_SECONDARY = "SAME_LEVEL_SECONDARY",
    UPPER_LEVEL = "UPPER_LEVEL",
    NONE = "NONE"
}

// 추천 레벨 타입별 한글 텍스트 매핑
export const RECOMMENDATION_LEVEL_TEXT: Record<RecommendationLevelType, string> = {
    [RecommendationLevelType.SAME_LEVEL_PRIMARY]: "동급 괜찮은",
    [RecommendationLevelType.SAME_LEVEL_SECONDARY]: "동급 아쉬운",
    [RecommendationLevelType.UPPER_LEVEL]: "미끼",
    [RecommendationLevelType.NONE]: "해당 없음"
};

export const getRecommendationLevelText = (levelType: string | RecommendationLevelType | null | undefined): string => {
    if (!levelType) {
        return RECOMMENDATION_LEVEL_TEXT[RecommendationLevelType.NONE];
    }

    // 문자열로 전달된 경우 Enum으로 변환
    const enumValue = (typeof levelType === 'string')
        ? levelType as keyof typeof RecommendationLevelType
        : levelType;

    return RECOMMENDATION_LEVEL_TEXT[enumValue] || RECOMMENDATION_LEVEL_TEXT[RecommendationLevelType.NONE];
};

export const getRecommendationTagColor = (recommendationLevel: RecommendationLevelType) => {
    switch (recommendationLevel) {
        case RecommendationLevelType.UPPER_LEVEL:
            return "error";
        case RecommendationLevelType.SAME_LEVEL_PRIMARY:
            return "success";
        case RecommendationLevelType.SAME_LEVEL_SECONDARY:
            return "#87E8DE";
        case RecommendationLevelType.NONE:
            return "default";
        default:
            return "default";
    }
};




export interface RecommendationCandidateUser {
    id: string;
    name: string;
    gender: string;
    userProfileImages: string[];
    userRepresentativeImages: string[];
    appearanceRating: string;
    overallSocialRating: string;
    careerDetail: string;
    careerSubGroup: string;
    careerType: string;
    univRating: string;
    univName: string;
    preferenceType: string;
    height: number;
    age: number;
    partnerHeightRange: string[];
    partnerUnderAge: string;
    partnerUpperAge: string;
    income: number;
    religion: string;
    smokingStatus: string;
    drinkingStatus: string;
    mbti: string;
    appealPoint: string;
    partnerSmokingStatus: string;
    partnerDrinkingStatus: string;
    partnerReligion: string[];
    weeksInRecommendation: string;
    userVideos: string[];
    userVideoThumbnails: string[];
    recommendationLevel: RecommendationLevelType;
    receivedRecommendations: ReceivedRecommendation[];
}

export interface ReceivedRecommendation {
    recommendationId: string;
    toUserId: string;
    partnerUserId: string;
    recommendationLevel: string;
    recommendationType: string;
}

interface GetRecommendationCandidatesParams {
    userId: string;
    page?: number;
    size?: number;
    name?: string;
    searchUserId?: string;
    overallSocialRating?: string;
    appearanceRating?: string;
    univRating?: string;
    preferenceType?: string;
    isRecommendationSuccess?: string;
    weekId: string;
}

export const getRecommendationCandidatesForUser = async (
    params: GetRecommendationCandidatesParams
) => {
    const { userId, ...queryParams } = params;

    const res = await apiClient.get<PaginationResponse<RecommendationCandidateUser>>(
        `/v1/admin/recommendations/users/${userId}/candidates`,
        {
            params: queryParams,
        }
    );

    return res.data;
};