import React, {useState} from 'react';
import {Button, Card, Col, Image, Modal, Row, Typography} from 'antd';
import {PlayCircleFilled} from '@ant-design/icons';
import VideoPlayerModal from "./VideoPlayerModal";

const {Title} = Typography;


interface ImageModalProps {
    isOpen: boolean;
    onClose: () => void;
    representationImages: string[];
    images: string[];
    videos: string[];
    videoThumbnails: string[];
}

const ImageModal: React.FC<ImageModalProps> = ({
                                                   isOpen,
                                                   onClose,
                                                   representationImages = [],
                                                   images = [],
                                                   videos = [],
                                                   videoThumbnails = []
                                               }) => {
    // 비디오 플레이어 모달 상태 관리
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState<string | null>(null);


    // 대표 이미지 배열 준비
    const gridRepresentationImages = representationImages.map((image, index) => ({
        id: index,
        image,
        style: {
            height: '150px',
            background: '#f0f0f0',
            overflow: 'hidden'
        }
    }));

    // 그리드에 표시할 이미지 배열을 준비합니다
    // null 값으로 8개를 채우되, 실제 이미지로 앞부분을 교체합니다
    const gridImages = Array(8).fill(null).map((_, index) => ({
        id: index,
        image: images && index < images.length ? images[index] : null,
        style: {
            height: '150px',
            background: '#f0f0f0',
            overflow: 'hidden'
        }
    }));

    // 동영상 배열을 준비합니다
    const gridVideos = videos
        .filter(video => video.trim() !== '')  // 빈 문자열 제거
        .map((video, index) => ({
            id: index,
            video,
            thumbnail: videoThumbnails[index],
            style: {
                height: '150px',
                background: '#f0f0f0',
                overflow: 'hidden'
            }
        }));

    // 동영상 클릭 핸들러
    const handleVideoClick = (videoUrl: string) => {
        setSelectedVideo(videoUrl);
        setIsVideoModalOpen(true);
    };

    // 비디오 모달 닫기 핸들러
    const handleVideoModalClose = () => {
        setIsVideoModalOpen(false);
        setSelectedVideo(null);
    };

    return (
        <>
            <Modal
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={5} style={{margin: 0}}>모두 보기</Title>
                    </div>
                }
                open={isOpen}
                footer={[
                    <Button
                        key="cancel"
                        onClick={onClose}
                        style={{borderRadius: '8px'}}
                    >
                        닫기
                    </Button>
                ]}
                width={1200}
                onCancel={onClose}
                bodyStyle={{padding: '24px'}}
            >
                {/* 사진 섹션 */}
                <Row gutter={[16, 16]}>
                    {gridRepresentationImages.map(item => (
                        <Col key={item.id} xs={12} sm={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{padding: 0, height: '100%'}}
                            >
                                <div style={{position: 'relative'}}>
                                    <Image
                                        src={item.image}
                                        alt={`대표 이미지 ${item.id + 1}`}
                                        width={272}
                                        style={{
                                            objectFit: 'cover',
                                            display: 'block'
                                        }}
                                        preview={{
                                            width: 'auto',
                                            height: 'auto',
                                            mask: <div>클릭하여 크게보기</div>,
                                        }}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        background: "rgba(255, 0, 0, 0.6)",
                                        color: 'white',
                                        padding: '8px',
                                        textAlign: 'center',
                                        fontSize: '14px'
                                    }}>
                                        대표사진
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                    {gridImages.map(item => (
                        <Col key={item.id} xs={12} sm={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{padding: 0, height: '100%'}}
                            >
                                {item.image && (
                                    <Image
                                        src={item.image}
                                        alt={`이미지 ${item.id + 1}`}
                                        width={272}
                                        style={{
                                            objectFit: 'cover',
                                            display: 'block' // 이미지 하단의 여백을 제거합니다
                                        }}
                                        preview={{
                                            width: 'auto',
                                            height: 'auto',
                                            mask: <div>클릭하여 크게보기</div>,
                                        }}
                                    />
                                )}
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* 하단 동영상 섹션 */}
                <Row gutter={[16, 16]} style={{marginTop: '16px'}}>
                    {gridVideos.map(item => (
                        <Col key={item.id} xs={12} sm={6}>
                            <Card
                                bordered={false}
                                bodyStyle={{padding: 0, height: '100%'}}
                                onClick={() => handleVideoClick(item.video)}
                                style={{cursor: 'pointer'}}
                            >
                                <div style={{
                                    position: 'relative',
                                    height: '150px',
                                    overflow: 'hidden'
                                }}>
                                    <Image
                                        src={item.thumbnail}
                                        alt={`비디오 ${item.id + 1}`}
                                        width={272}
                                        style={{
                                            objectFit: 'cover',
                                            display: 'block'
                                        }}
                                        preview={false}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        fontSize: '32px'
                                    }}>
                                        <PlayCircleFilled/>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Modal>
            {/* 비디오 플레이어 모달 */}
            {selectedVideo && (
                <VideoPlayerModal
                    isOpen={isVideoModalOpen}
                    onClose={handleVideoModalClose}
                    videoUrl={selectedVideo}
                />
            )}
        </>
    );
};

export default ImageModal;