import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Checkbox, Image, Select, Space, Table, Tag, Tooltip, Typography} from 'antd';
import styled from "@emotion/styled";
import MatchLayout from "../../common/components/AdminLayout";
import {
    calculateColumnsTotalWidth,
    FILTER_OPTIONS,
    formatKoreanMoney,
    getTagColor,
    PreferenceOption,
    TitleWrapper
} from "./RecommendationList";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {
    getRecommendationCandidatesForUser,
    getRecommendationLevelText,
    getRecommendationTagColor,
    RecommendationCandidateUser
} from "../../utils/api/recommendation/recommendationCandidate";
import RecommendationGlobalTableFilter, {FilterValues} from "./components/RecommendationGlobalTableFilter";
import type {ColumnsType} from "antd/es/table";
import {AllUsersQueryResult} from "../user/UserDetail";
import {getAllUsers, GetAllUsersResponse} from "../../utils/api/users/users";
import {ApiError} from "../../utils/api/apiError";
import AllUserProfileCard from "../user/components/AllUserProfileCard";


const RecommendationUpperLevelManage = () => {
    const {weekId, weekValue} = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams<{ id: string }>();

    // URL에서 초기 필터 값을 가져오는 함수
    const getInitialFilter = useCallback(() => {
        return {
            gender: searchParams.get("gender") || "",
            name: searchParams.get("name") || "",
            page: parseInt(searchParams.get("page") || "0"),
            size: parseInt(searchParams.get("size") || "10"),
            overallSocialRating: searchParams.get("overallSocialRating") || "",
            appearanceRating: searchParams.get("appearanceRating") || "",
            univRating: searchParams.get("univRating") || "",
            preferenceType: searchParams.get("preferenceType") || "",
            searchUserId: searchParams.get("searchUserId") || "",
            isRecommendationSuccess: searchParams.get("isRecommendationSuccess") || ""
        };
    }, [searchParams]);

    const [filter, setFilter] = useState(getInitialFilter());

    interface FilterType {
        gender: string;
        name: string;
        page: number;
        size: number;
        overallSocialRating: string;
        appearanceRating: string;
        univRating: string;
        preferenceType: string;
        searchUserId: string;
        isRecommendationSuccess: string;
    }


    // 필터 변경시 URL 업데이트
    const updateURLParameters = (newFilter: FilterType) => {
        const params = new URLSearchParams();
        Object.entries(newFilter).forEach(([key, value]) => {
            if (value !== "" && value !== null && value !== undefined) {
                params.set(key, value.toString());
            }
        });
        setSearchParams(params);
    };

    const handleFilterChange = (values: Partial<FilterType>) => {
        const newFilter = {
            ...filter,
            ...values,
            page: 0, // 필터 변경 시 첫 페이지로 이동
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        const newFilter = {
            ...filter,
            page: page - 1,
            size: pageSize || filter.size,
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    const handleReset = () => {
        const resetFilter = {
            gender: "",
            name: "",
            page: 0,
            size: 10,
            overallSocialRating: "",
            appearanceRating: "",
            univRating: "",
            preferenceType: "",
            searchUserId: "",
            isRecommendationSuccess: ""
        };
        setFilter(resetFilter);
        // URL 파라미터 초기화
        setSearchParams({});
    };

    // URL 변경 감지하여 필터 상태 업데이트
    useEffect(() => {
        const currentFilter = getInitialFilter();
        setFilter(currentFilter);
    }, [getInitialFilter]);

    const handleRowClick = (record: RecommendationCandidateUser) => {
        navigate(`/recommendations/${weekValue}/weeks/${weekId}/users/${record.id}`);
    };

    const {data: recommendationCandidatesForUserQueryResult} = useQuery({
        queryKey: ["recommendation-candidates", params.id, filter],
        queryFn: () => {
            // Validate required parameters
            if (!params.id) {
                throw new Error("User ID is required");
            }
            if (!weekId) {
                throw new Error("WeekId is required");
            }

            return getRecommendationCandidatesForUser({
                userId: params.id,
                page: filter.page,
                size: filter.size,
                name: filter.name || undefined,
                searchUserId: filter.searchUserId || undefined,
                overallSocialRating: filter.overallSocialRating || undefined,
                appearanceRating: filter.appearanceRating || undefined,
                univRating: filter.univRating || undefined,
                preferenceType: filter.preferenceType || undefined,
                isRecommendationSuccess: filter.isRecommendationSuccess || undefined,
                weekId: weekId
            });
        },
        select: (data) => ({
            contents: data.data.contents,
            pagination: data.data.pagination
        })
    });

    const [checkedUsers, setCheckedUsers] = useState({});

    const handleCheckboxChange = ( userId: string) => {
        setCheckedUsers({
            ...checkedUsers,
            // [userId]: e.target.checked
        });
    };


    const columns: ColumnsType<RecommendationCandidateUser> = [
        {
            title: "추천 정보",
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children:
                [
                    {
                        title: () => (
                            <div>
                                <TitleWrapper>선택</TitleWrapper>
                            </div>
                        ),
                        key: 'recommendationLevel',
                        width: 100,
                        align: 'center',
                        render: (_, record) => (
                            <div
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px 0'
                                }}
                            >
                                <Checkbox onChange={() => handleCheckboxChange(record.id)} />
                            </div>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <TitleWrapper>추천 분류</TitleWrapper>
                            </div>
                        ),
                        key: 'recommendationLevel',
                        width: 100,
                        align: 'center',
                        render: (_, record) => (
                            <div>
                                <Tag color={getRecommendationTagColor(record.recommendationLevel)}
                                >{getRecommendationLevelText(record.recommendationLevel)}</Tag>
                            </div>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <TitleWrapper>주차</TitleWrapper>
                            </div>
                        ),
                        key: 'week',
                        width: 100,
                        align: 'center',
                        render: (_, record) => (
                            <div>
                                <Tag>{record.weeksInRecommendation}</Tag>
                            </div>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <TitleWrapper>추천 완료</TitleWrapper>
                                <Space>
                                    <Select
                                        style={{width: 80}}
                                        size="small"
                                        value={filter.isRecommendationSuccess}
                                        onChange={(value) =>
                                            handleFilterChange({isRecommendationSuccess: value})
                                        }
                                    >
                                        {Object.values(FILTER_OPTIONS.RECOMMENDATION_STATUS).map(
                                            ({value, label}) => (
                                                <Select.Option key={value} value={value}>
                                                    {label}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Space>
                            </div>
                        ),
                        key: "isFirstRecommendationDone",
                        width: 100,
                        align: "center",
                        render: (_, record) => (
                            <div>
                                {record.receivedRecommendations.length >= 8 ? (
                                    <Tag color="#87d068">성공</Tag>
                                ) : (
                                    <Tag color="#f50">실패</Tag>
                                )}
                            </div>
                        ),
                    },
                    {
                        title: () => (
                            <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                <div>추천 현황</div>
                                <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                            </div>
                        ),
                        key: 'Recommendations',
                        width: 250,
                        align: 'center',
                        render: (_, record) => {
                            const receivedRecommendations = record?.receivedRecommendations;
                            const receivedUpperLevelRecommendations = receivedRecommendations?.filter(
                                (recommendation) => recommendation.recommendationLevel === "미끼 추천"
                            );
                            const receivedSameLevelPrimaryRecommendations = receivedRecommendations?.filter(
                                (recommendation) => recommendation.recommendationLevel === "동급 괜찮은 추천"
                            );
                            const receivedSameLevelSecondaryRecommendations = receivedRecommendations?.filter(
                                (recommendation) => recommendation.recommendationLevel === "동급 아쉬운 추천"
                            );

                            // 각 추천 타입별로 컴포넌트를 생성합니다
                            const renderRecommendationRow = (
                                label: string,
                                count: number,
                                range: string
                            ) => (
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: '80px 1fr',
                                    gap: '16px',
                                    width: '100%'
                                }}>
                                    <div style={{
                                        textAlign: 'left',
                                        color: '#000000',  // 레이블 색상을 검정색으로 변경
                                        fontWeight: 'bold'
                                    }}>
                                        {label}
                                    </div>
                                    <div style={{
                                        textAlign: 'left'  // 값을 왼쪽 정렬로 변경
                                    }}>
                                        <b>{count}</b> / {range}
                                    </div>
                                </div>
                            );

                            return (
                                <Space direction="vertical" size={8}>
                                    {renderRecommendationRow(
                                        "미끼",
                                        receivedUpperLevelRecommendations?.length || 0,
                                        "0~2"
                                    )}
                                    {renderRecommendationRow(
                                        "동급 괜찮은",
                                        receivedSameLevelPrimaryRecommendations?.length || 0,
                                        "3~4"
                                    )}
                                    {renderRecommendationRow(
                                        "동급 아쉬운",
                                        receivedSameLevelSecondaryRecommendations?.length || 0,
                                        "3~4"
                                    )}
                                </Space>
                            );
                        }
                    }
                ]
        },
        {
            title: '매력 정보',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children: [
                {
                    title: '프로필',
                    key: 'profile',
                    width: 200,
                    align: 'center',
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Image
                                src={record.userProfileImages[0]}
                                height={200}
                                width={200}
                                style={{objectFit: 'cover'}}
                                preview={{
                                    width: 'auto',  // 프리뷰 모달의 너비
                                    height: 'auto', // 프리뷰 모달의 높이
                                    mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                }}
                            />
                        </div>
                    )
                }
            ]
        },
        {
            title: '기본 정보',
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            children: [
                {
                    title: () => (
                        <div>
                            <TitleWrapper>외모</TitleWrapper>
                            <Space>
                                <Select
                                    style={{width: 80}}
                                    size="small"
                                    value={filter.appearanceRating}
                                    onChange={(value) =>
                                        handleFilterChange({appearanceRating: value})
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                                        ({value, label}) => (
                                            <Select.Option key={value} value={value}>
                                                {label}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'appearanceRating',
                    align: 'center',
                    width: 100,
                    render:
                        (_, record) => (
                            <Space direction="vertical">
                                <Tag
                                    color={getTagColor(record.appearanceRating, 'APPEARANCE_RATING')}>외모 {record.appearanceRating}</Tag>
                            </Space>
                        )
                },
                {
                    title: '이름 / 성별 / 나이 / 키',
                    key:
                        'basic info',
                    width: 200,
                    align: 'center',
                    render:
                        (_, record) => (
                            <Space direction="vertical">
                                <div>{record.name}({record.gender === '남자' ? '남' : '여'})</div>
                                <div>{record.age}세</div>
                                <div>{record.height}cm</div>
                            </Space>
                        )
                }
            ]
        },
        {
            title: '직업/학력 정보',
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            children:
                [{
                    title: () => (
                        <div>
                            <TitleWrapper>직업</TitleWrapper>
                            <Space>
                                <Select
                                    placeholder="능력"
                                    size="small"
                                    style={{width: 80}}
                                    value={filter.overallSocialRating}
                                    onChange={(value) =>
                                        handleFilterChange({overallSocialRating: value})
                                    }
                                >
                                    {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                                        ({value, label, description}: PreferenceOption) => (
                                            <Select.Option key={value} value={value}>
                                                <div className="select-option-content">
                                                    {description ? (
                                                        <Tooltip title={description} placement="right">
                                                            <div>{label}</div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div>{label}</div>
                                                    )}
                                                </div>
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'career',
                    width: 180,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.overallSocialRating, 'OVERALL_SOCIAL_RATING')}>능력 {record.overallSocialRating}</Tag>
                            <div>{record.careerDetail}</div>
                            <div>{record.careerSubGroup}</div>
                        </Space>
                    )
                },
                    {
                        title: '연봉',
                        key: 'career',
                        width: 150,
                        align: 'center',
                        render: (_, record) => (
                            <Space direction="vertical">
                                <div>{record.income != null ? formatKoreanMoney(record.income) : "-"}</div>
                            </Space>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <div>학력</div>
                                <Space>
                                    <Select
                                        placeholder="학력"
                                        size="small"
                                        style={{width: 80}}
                                        value={filter.univRating}
                                        onChange={(value) =>
                                            handleFilterChange({univRating: value})
                                        }
                                    >
                                        {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                                            ({value, label, description}: PreferenceOption) => (
                                                <Select.Option key={value} value={value}>
                                                    <div className="select-option-content">
                                                        {description ? (
                                                            <Tooltip title={description} placement="right">
                                                                <div>{label}</div>
                                                            </Tooltip>
                                                        ) : (
                                                            <div>{label}</div>
                                                        )}
                                                    </div>
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Space>
                            </div>
                        ),
                        key: 'univ',
                        width: 180,
                        align: 'center',
                        render: (_, record) => (
                            <Space direction="vertical">
                                <Tag color={getTagColor(record.univRating, 'UNIV_RATING')}>학력 {record.univRating}</Tag>
                                <div>{record.univName !== "" ? record.univName : "-"}</div>
                            </Space>
                        )
                    }
                ]
        },
        {
            title: '성향/이상형 정보',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>성향 정보</div>
                        </div>
                    ),
                    key: 'preference',
                    width: 200,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical" size={12} style={{width: '100%', padding: '8px 0'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>종교</div>
                                <div>{record.religion ?? "-"}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>흡연</div>
                                <div>{record.smokingStatus}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>음주</div>
                                <div>{record.drinkingStatus}</div>
                            </div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>이상형 정보</div>
                        </div>
                    ),
                    key: 'preferenceType',
                    align: 'center',
                    width: 180,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>타입</b>
                                </div>
                                <Tag
                                    color={getTagColor(record.preferenceType, 'PREFERENCE_TYPE')}>{record.preferenceType}</Tag>
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>나이</b>
                                </div>
                                {Number(record.age) - Number(record.partnerUnderAge)} ~ {Number(record.age) + Number(record.partnerUpperAge)}세
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>키</b>
                                </div>
                                {record.partnerHeightRange[0].includes('-')
                                    ? `${record.partnerHeightRange[0].replace('-', '')}`
                                    : `${record.partnerHeightRange[0]}`} ~ {
                                record.partnerHeightRange[1].includes('+')
                                    ? `${record.partnerHeightRange[1].replace('+', '')}cm`
                                    : `${record.partnerHeightRange[1]}cm`}
                            </div>
                        </Space>
                    )
                },
            ]
        }
    ];

    // 필터 어댑터 함수 추가
    const adaptFilterForComponent = (filter: FilterType) => {
        return {
            gender: filter.gender,
            name: filter.name,
            overallSocialRating: filter.overallSocialRating,
            appearanceRating: filter.appearanceRating,
            univRating: filter.univRating,
            preferenceType: filter.preferenceType,
            userId: filter.searchUserId, // searchUserId를 userId로 매핑
        };
    };

    // 컴포넌트에서 변경된 값을 다시 원래 필터 형식으로 변환
    const adaptComponentChanges = (changes: Partial<FilterValues>) => {
        const result: Partial<FilterType> = {...changes};

        // userId가 있으면 searchUserId로 변환
        if ('userId' in changes) {
            result.searchUserId = changes.userId;
        }

        return result;
    };

    // queryKey에 id를 포함시켜 id가 변경될 때마다 새로운 데이터를 조회하도록 합니다.
    const {data: inspectionDetail, isLoading} = useQuery<GetAllUsersResponse, ApiError, AllUsersQueryResult>({
        queryKey: ['user-detail', params.id], // id를 queryKey에 포함시켜 캐싱을 관리합니다
        queryFn: () => getAllUsers({
            userId: params.id,
            page: 0,    // 첫 페이지를 조회합니다
            size: 1     // 한 명의 사용자 정보만 필요하므로 size를 1로 설정합니다
        }),
        // API 응답 데이터를 원하는 형태로 변환합니다
        select: (response) => ({
            contents: response.data.contents,
            pagination: response.data.pagination
        }),
        // id가 없는 경우 쿼리를 비활성화합니다
        enabled: !!params.id
    });

    // 로딩 중인 경우를 처리합니다
    if (isLoading) {
        return (
            <MatchLayout>
                <Card>
                    <div>Loading...</div>
                </Card>
            </MatchLayout>
        );
    }

    // 데이터가 있는 경우, 첫 번째 항목을 표시합니다
    // contents 배열의 첫 번째 항목이 현재 조회하려는 사용자의 정보입니다
    const userDetail = inspectionDetail?.contents[0];

    return (
        <MatchLayout>
            <Card>
                <span style={{fontSize: '22px', fontWeight: 'bold'}}>{userDetail?.name || "사용자"}님의 미끼 추천 프로필 추가하기</span>
                <RecommendationGlobalTableFilter
                    filter={adaptFilterForComponent(filter)}
                    onFilterChange={(values) => handleFilterChange(adaptComponentChanges(values))}
                    onReset={handleReset}
                />
                {userDetail && <AllUserProfileCard data={userDetail}/>}
            </Card>

            <Card style={{marginBottom: '16px'}}>
                <Typography.Title level={2}>전체 회원 목록</Typography.Title>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={recommendationCandidatesForUserQueryResult?.contents || []}
                        rowKey={(record) => record.id}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={{
                            current: filter.page + 1,
                            pageSize: filter.size,
                            total: recommendationCandidatesForUserQueryResult?.pagination.totalElements || 0,
                            onChange: handlePageChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        style={{
                            '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                        } as React.CSSProperties}
                    />
                </SectionCard>

                <ButtonCard>
                    <StyledButton>미끼 추천 프로필 추가</StyledButton>
                </ButtonCard>
            </Card>

        </MatchLayout>
    );
}

const SectionCard = styled(Card)`
    .ant-table-thead > tr:first-child > th {
        background-color: #97B4F4 !important;
    }

    .ant-table-thead > tr:nth-child(2) > th {
        background-color: #CBDAFA !important;
    }

    margin-bottom: 24px;

    .ant-card-head-title {
        font-size: 20px; // 타이틀 폰트 크기를 20px로 설정
    }

    .ant-card-body {
        padding: 0; // Card 내부 패딩 제거
    }

    .ant-table-wrapper {
        margin: 0; // 테이블 래퍼의 마진 제거
    }
`;

const ButtonCard = styled(Card)`
    margin-bottom: 24px;

    // 내부 요소들을 중앙 정렬하기 위한 스타일

    .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .ant-card-head-title {
        font-size: 20px; // 타이틀 폰트 크기를 20px로 설정
    }

    .ant-card-body {
        padding: 0; // Card 내부 패딩 제거
    }

    .ant-table-wrapper {
        margin: 0; // 테이블 래퍼의 마진 제거
    }

`;

const StyledButton = styled(Button)`
    display: flex;
    width: 600px;
    height: 48px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: rgba(80, 119, 246, 0.80);
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    border: none;

    &:hover, &:focus {
        background: rgba(80, 119, 246, 0.90) !important; /* 더 진한 파란색으로 변경 */
        color: white !important; /* 글자색을 흰색으로 유지 */
        border-color: transparent !important; /* 테두리 제거 */
    }

    &:active {
        background: rgba(80, 119, 246, 1) !important; /* 클릭했을 때 완전 진한 파란색 */
        color: white !important;
    }

    span {
        color: white;
        font-size: 16px;
    }
`;

export default RecommendationUpperLevelManage;