import React, {useState} from 'react';
import {Button, Image, Select, Tooltip} from 'antd';
import {FILTER_OPTIONS, PreferenceOption} from "../../../recommend/RecommendationList";
import {RatingType} from "../../InspectionsSignUp";
import InspectionImageModal from "../modals/InspectionImageModal";

interface ProfileDisplayProps {
    representationImages: string[];
    profileImage: string[];
    videos: string[];
    videoThumbnails: string[];
    appearanceRating: string;
    userId: string;
    userName: string;
    onRatingChange: (newValue: string, currentValue: string, recordId: string, recordName: string, ratingType: RatingType) => void;
}

const ProfileInspectionRatingChangeDisplay: React.FC<ProfileDisplayProps> = ({
                                                                     representationImages,
                                                                     profileImage,
                                                                     videos,
                                                                     videoThumbnails,
                                                                     appearanceRating,
                                                                     userId,
                                                                     userName,
                                                                     onRatingChange
                                                                 }) => {
    // 모달의 표시 상태를 관리하는 state를 추가합니다
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleViewAllClick = () => {
        setIsModalOpen(true);  // 모달을 열어줍니다
    };

    // 모달 닫기 핸들러를 정의합니다
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="profile-display"
             style={{position: 'relative'}}>
            {/* 이미지 섹션 */}
            <Image
                src={representationImages.length > 0 ? representationImages[0] : profileImage[0]}
                height={200}
                width={200}
                style={{
                    objectFit: 'cover',
                    display: 'block' // 이미지 하단의 여백을 제거합니다
                }}
                preview={{
                    width: 'auto',
                    height: 'auto',
                    mask: <div>클릭하여 크게보기</div>,
                }}
            />

            {/* 버튼 컨테이너를 이미지 위에 위치시킵니다 */}
            <div style={{
                position: 'absolute', // 절대 위치를 사용합니다
                bottom: '20px', // 이미지 하단에서의 거리를 설정합니다
                left: '0',
                right: '0',
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                padding: '0 10px' // 좌우 여백을 추가합니다
            }}>
                <Button
                    type="primary"
                    onClick={handleViewAllClick}
                >
                    모두 보기
                </Button>

                <Select
                    placeholder="외모"
                    size="small"
                    style={{
                        width: 120,
                        height: 32
                    }}
                    value={appearanceRating}
                    onChange={(value) => onRatingChange(value, appearanceRating, userId, userName, 'appearance')}
                >
                    {Object.values(FILTER_OPTIONS.APPEARANCE_RATING)
                        .filter((value) =>
                            value !== FILTER_OPTIONS.APPEARANCE_RATING.ALL
                        )
                        .map(({
                                  value,
                                  label,
                                  description
                              }: PreferenceOption) => (
                            <Select.Option key={value} value={value}>
                                <div className="select-option-content">
                                    {description ? (
                                        <Tooltip title={description} placement="right">
                                            <div>{label}</div>
                                        </Tooltip>
                                    ) : (
                                        <div>{label}</div>
                                    )}
                                </div>
                            </Select.Option>
                        ))}
                </Select>

                <InspectionImageModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    representationImages={representationImages}
                    images={profileImage}
                    videos={videos}
                    videoThumbnails={videoThumbnails}
                    userId={userId}
                />
            </div>
        </div>
    );
};

export default ProfileInspectionRatingChangeDisplay;