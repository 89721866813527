import React from "react";
import { Button, message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SignUpRejectModalProps } from "../../../../utils/types/inspection/inspectionModal";

// 가입 승인 모달 컴포넌트를 정의합니다
const SignUpRejectModal: React.FC<SignUpRejectModalProps> = ({
  isOpen,
  selectedUser,
  onClose,
  onReject,
}) => {
  const handleApprove = async () => {
    if (!selectedUser) {
      message.error("선택된 사용자 정보가 없습니다.");
      return;
    }

    try {
      const success = await onReject(selectedUser.id); // 이제 TypeScript 오류가 발생하지 않습니다

      if (success) {
        message.success("가입이 거절 처리되었습니다.");
        onClose();
      } else {
        message.error("가입 거절 처리에 실패했습니다.");
      }
    } catch (error) {
      console.error("가입 거절 처리 중 오류:", error);
      message.error("가입 거절 처리 중 오류가 발생했습니다.");
    }
  };

  return (
    <Modal
      title="가입 거절"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={
            {
              borderRadius: "5px",
              background: "#E15B56",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              ":hover": {
                backgroundColor: "#6DAF3B !important",
                borderColor: "#6DAF3B !important",
              },
            } as React.CSSProperties
          }
          onClick={handleApprove}
        >
          가입 거절하기
        </Button>,
      ]}
      width={400}
      centered
      closable={true}
      closeIcon={<CloseOutlined />}
    >
      {selectedUser && (
        <div>
          {selectedUser.name} 님의 가입을 거절할까요?
          <br />* 가입 결과가 알림톡으로 발송됩니다.
        </div>
      )}
    </Modal>
  );
};

export default SignUpRejectModal;
