import styled from "@emotion/styled";
import {useQuery} from "@tanstack/react-query";
import {notification, Typography} from "antd";
import {useState} from "react";
import Loading from "../../common/components/Loading";
import {getAppearance} from "../../utils/api/user";
import AppearanceCard from "./components/AppearanceCard";
import UserLayout from "./components/UserLayout";

const Appearance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const onChangeLoading = (loading: boolean) => {
    setIsLoading(loading);
  };
  const { Title } = Typography;
  const {
    data: appearance,
    isLoading: isAppearanceLoading,
    isError: isAppearanceError,
  } = useQuery({
    queryKey: ["appearance"],
    queryFn: () => getAppearance(),
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (name: string) => {
    api["success"]({
      message: "분류 완료!",
      description: `${name}님의 외모 분류가 완료되었어요`,
      duration: 3,
    });
  };

  if (isAppearanceLoading || isLoading)
    return (
      <Paper>
        <Loading />
      </Paper>
    );
  if (isAppearanceError) return <></>;

  return (
    <UserLayout>
      <Paper>
        <Title level={2}>유저 외모 분류</Title>

        {!!appearance && (
          <>
            {appearance.map(
              ({ gender, name, userImages, userId, age, height }) => (
                <AppearanceCard
                  key={userId}
                  name={name}
                  userImages={userImages}
                  userId={userId}
                  gender={gender}
                  openNotificationWithIcon={openNotificationWithIcon}
                  onChangeLoading={onChangeLoading}
                  age={age}
                  height={height}
                />
              )
            )}
          </>
        )}
        {contextHolder}
      </Paper>
    </UserLayout>
  );
};

const Paper = styled.div`
  background-color: white;
  border-radius: 12px;
  width: 1600px;
  padding: 16px;
`;

export default Appearance;
