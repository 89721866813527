import React, {useMemo, useState} from 'react';
import {Card, DatePicker, Input, List, Space} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import styled from "@emotion/styled";
import MatchLayout from "../../common/components/AdminLayout";
import {useQuery} from "@tanstack/react-query";
import {
    getRecommendationWeeks,
    RecommendationWeeksResponse,
    WeekData
} from "../../utils/api/recommendation/recommendationWeeks";

// dayjs 설정: 한국어 로케일과 주차 계산을 위한 플러그인 설정
dayjs.locale('ko');
dayjs.extend(weekOfYear);

const RecommendationMain = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    // 날짜 범위 선택을 위한 상태 관리
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

    // API 호출을 위한 날짜 형식 변환 함수
    const formatDateForApi = (date: Dayjs | null) => {
        if (!date) return undefined;
        return date.format('YYYY-MM-DDTHH:mm:ss');
    };

    // API 데이터 조회
    const { data: recommendationWeeks, isLoading, error } = useQuery<RecommendationWeeksResponse>({
        queryKey: ['recommendation-weeks', dateRange],
        queryFn: () => getRecommendationWeeks({
            startTime: formatDateForApi(dateRange[0]),
            endTime: formatDateForApi(dateRange[1])
        })
    });

    // 검색어와 날짜 범위를 모두 고려한 데이터 필터링
    const filteredData = useMemo(() => {
        if (!recommendationWeeks?.data) return [];

        return recommendationWeeks.data.filter(week => {
            return week.weekVale.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [recommendationWeeks?.data, searchTerm]);

    // 날짜 범위 선택 핸들러
    const handleDateRangeChange = (
        dates: [Dayjs | null, Dayjs | null] | null,
        dateStrings: [string, string]
    ) => {
        if (dates) {
            setDateRange(dates);
        } else {
            setDateRange([null, null]);
        }
    };

    // 주차 항목 클릭 핸들러
    const handleWeekClick = (weekData: WeekData) => {
        let weekId = weekData.weekId;
        // 공백 제거
        const trimmed = weekData.weekVale.replace(/\s+/g, "");
        // encodeURIComponent를 사용하여 URL에 안전한 형태로 인코딩 및 년도 앞의 두글자 삭제
        const encodedValue = encodeURIComponent(trimmed).slice(2);
        navigate(`/recommendations/${encodedValue}/weeks/${weekId}`);
    };

    return (
        <MatchLayout>
            <StyledCard
                title={
                    <Space size="middle" style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '16px 24px'
                    }}>
                        <Space size="small" style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: '32px'}}>주차별 추천 조회</span>
                        </Space>
                    </Space>
                }
            >
                <div style={{ padding: '20px 24px' }}>
                    {/* 검색 필터 영역 */}
                    <Space size={12} style={{ marginBottom: '24px' }}>
                        <DatePicker.RangePicker
                            onChange={handleDateRangeChange}
                            style={{ width: '300px' }}
                        />
                        <Input
                            placeholder="주차를 검색하세요"
                            prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ width: '280px' }}
                        />
                    </Space>

                    {/* 데이터 로딩 상태 처리 */}
                    {isLoading && <div>데이터를 불러오는 중입니다...</div>}
                    {error && <div>데이터 로드 중 오류가 발생했습니다.</div>}

                    {/* 주차 목록 */}
                    {!isLoading && !error && (
                        <List
                            dataSource={filteredData}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.weekVale}
                                    onClick={() => handleWeekClick(item)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '12px 24px',
                                        margin: '4px 0',
                                        transition: 'background-color 0.3s',
                                        fontSize: '16px'
                                    }}
                                    className="hover:bg-gray-50"
                                >
                                    <span style={{ color: '#1890ff' }}>{item.weekVale}</span>
                                </List.Item>
                            )}
                            style={{
                                border: '1px solid #f0f0f0',
                                borderRadius: '8px'
                            }}
                        />
                    )}
                </div>
            </StyledCard>
        </MatchLayout>
    );
};

export default RecommendationMain;

const StyledCard = styled(Card)`
    width: 100%;
    min-height: 100vh;
`;