import apiClient from "../apiClient";
import { Pagination } from "../pagination";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface SignUpInspectionUsers {
  id: string;
  name: string;
  gender: "남자" | "여자";
  age: number;
  height: number;
  partnerHeightRange: String[];
  partnerUnderAge: number;
  partnerUpperAge: number;
  userProfileImages: string[];
  userRepresentativeImages: string[];
  appearanceRating: string;
  overallSocialRating: string;
  careerDetail: string;
  careerSubGroup: string;
  careerType: string;
  univRating: string;
  univName: string;
  preferenceType: string;
  income: number;
  religion: string;
  smokingStatus: string;
  drinkingStatus: string;
  mbti: string;
  appealPoint: string;
  partnerSmokingStatus: string;
  partnerDrinkingStatus: string;
  partnerReligion: string[];
  userVideos: string[];
  userVideoThumbnails: string[];
  careerInspectionId: string;
  careerInspectionImages: string[];
  careerInspectionStatus: string;
  univInspectionId: string;
  univInspectionImages: string[];
  univInspectionStatus: string;
  incomeInspectionId: string;
  incomeInspectionImages: string[];
  incomeInspectionStatus: string;
  requestTime: string;
  userStatus: string;
  finalAcademic: string;
}

interface GetSignUpInspectionUsersParameter {
  gender?: string;
  name?: string;
  page: number;
  size: number;
  overallSocialRating?: string;
  appearanceRating?: string;
  univRating?: string;
  preferenceType?: string;
  userId?: string;
}

export interface GetSignUpInspectionUsersResponse {
  status: string;
  data: {
    pagination: Pagination;
    contents: SignUpInspectionUsers[];
  };
}

export const getSignUpInspectionUsers = async (
  params: GetSignUpInspectionUsersParameter
): Promise<GetSignUpInspectionUsersResponse> => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const response = await apiClient.get<GetSignUpInspectionUsersResponse>(
    `/v1/admin/inspections/signup`,
    {
      params: filteredParams,
    }
  );

  return response.data;
};

export type InspectionStatus =
  | "BEFORE_INSPECT"
  | "BEFORE_RE_INSPECT"
  | "INSPECT_COMPLETE"
  | "REJECT";

export interface InspectionBadgeQuery {
  inspectStatus: InspectionStatus;
  ratingType: string;
  rejectionReason?: string;
}

export interface ProcessSignupInspectionParams {
  userId: string;
  inspectStatus: InspectionStatus;
  rejectionReason?: string;
  badgeQueries?: InspectionBadgeQuery[];
}

export interface SignUpInspectionUsersQueryResult {
  contents: SignUpInspectionUsers[];
  pagination: Pagination;
}

// 유저 심사 뱃지 등급 변경 mutation
export const useUpdateInspectionBadgeMutation = (options?: {
  // 사용자 정의 queryKey 배열 또는 문자열
  invalidateQueryKey?: string | string[] | unknown[];
  // 성공 시 추가 작업을 위한 콜백 함수
  onSuccessCallback?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      userId: string;
      queries: Array<{
        inspectStatus: string; // 'APPROVED', 'REJECTED' 등의 상태값
        ratingType: string; // 'APPEARANCE', 'OVERALL_SOCIAL', 'UNIV', 'INCOME' 등의 타입
        rejectionReason?: string; // 거절 사유 (선택 사항)
      }>;
    }) => {
      // 서버에 보낼 request body 객체 생성
      const badgeQueries = params.queries.map((query) => ({
        inspectStatus: query.inspectStatus,
        ratingType: query.ratingType,
        rejectionReason: query.rejectionReason || "", // 거절 사유가 없는 경우 빈 문자열 전송
      }));

      // API 호출을 수행합니다
      return apiClient.patch(
        `/v1/admin/inspections/badge/users/${params.userId}/status`,
        { badgeQueries } // request body로 badgeQueries 배열 전송
      );
    },
    onSuccess: () => {
      // 옵션으로 제공된 queryKey가 있으면 해당 쿼리를 무효화
      if (options?.invalidateQueryKey) {
        // 문자열인 경우 배열로 변환
        const queryKey =
          typeof options.invalidateQueryKey === "string"
            ? [options.invalidateQueryKey]
            : options.invalidateQueryKey;

        queryClient.invalidateQueries({ queryKey });
      } else {
        // 기본값으로 'signup-inspections' 쿼리 무효화
        queryClient.invalidateQueries({ queryKey: ["signup-inspections"] });
      }

      // 추가 성공 콜백이 제공된 경우 실행
      if (options?.onSuccessCallback) {
        options.onSuccessCallback();
      }
    },
    onError: (error) => {
      // 에러 처리를 수행합니다
      console.error("Rating update failed:", error);
      // 사용자에게 에러 메시지를 표시하는 로직을 추가할 수 있습니다
    },
  });
};
