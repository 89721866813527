import {useQuery} from "@tanstack/react-query";
import styled from "@emotion/styled";
import {useState} from "react";
import {Button, Descriptions, Flex, List, Modal} from "antd";
import {getUserMatchedPartners} from "../../../utils/api/matching";
import UserImageCarousel from "../../user/components/UserImageCarousel";

interface MatchingViewBtnProps {
    userName: string;
    userId: number;
}


const MatchingViewBtn = ({ userName, userId }: MatchingViewBtnProps) => {

    const { data: userMatchingPartners } = useQuery({
        queryKey: ["userMatchingPartners", userId],
        queryFn: () => getUserMatchedPartners(Number(userId)),
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                매칭된 유저 보기
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="닫기"
                width={1680}
                title={`${userName}님과 매칭된 유저 목록`}
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        닫기
                    </Button>,
                ]}
            >
                <List
                    dataSource={userMatchingPartners}
                    renderItem={({
                        name,
                        careerType,
                        careerDetail,
                        careerSubGroup,
                        income,
                        gender,
                        age,
                        height,
                        userImages,
                        careerRating,
                        univRating,
                        appearanceRating,
                        incomeRating,
                        matchingType
                    }) => (
                        <List.Item
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "12px",
                            }}
                        >
                            <FlexColumn>
                                <Flex>
                                    <Flex>
                                        <UserImageCarousel images={userImages} />
                                    </Flex>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="매칭 종류">{matchingType}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="이름">{name}</Descriptions.Item>
                                        <Descriptions.Item label="성별">{gender}</Descriptions.Item>
                                        <Descriptions.Item label="키">{height}</Descriptions.Item>
                                        <Descriptions.Item label="나이">{age}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="외모 등급">{appearanceRating}</Descriptions.Item>
                                        <Descriptions.Item label="직장">{careerDetail}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="능력 등급">
                                            {careerRating}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="세부 직업">
                                            {careerSubGroup}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="직업군">
                                            {careerType}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="연봉">
                                            {income || "입력 전"}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="연봉 레벨">
                                            {incomeRating}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="학교 레벨">
                                            {univRating}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Flex>
                            </FlexColumn>
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    )

}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

export default MatchingViewBtn;