import {Image, message, Space, Table, Tag, Typography} from "antd";
import {getTagColor} from "../../recommend/RecommendationList";
import {CopyOutlined} from "@ant-design/icons";
import styled from "@emotion/styled";
import React from "react";
import InspectionLink from "../../inspection/components/InspectionLink";
import {AllUsers} from "../../../utils/api/users/users";

const AllUserProfileCard = ({data}: { data: AllUsers }) => {

    // 매력 정보 테이블 설정 추가
    const charmColumns = [
        {
            title: '',
            dataIndex: 'label',
        },
        {
            title: '',
            dataIndex: 'value',
            render: (value: any) => {
                if (Array.isArray(value)) {
                    // 이미지 배열인 경우
                    return (
                        <ImageGridContainer>
                            {value.map((image, index) => (
                                <Image
                                    key={index}
                                    src={image}
                                    alt={`Image ${index}`}
                                    width={300}
                                    height={300}
                                    style={{
                                        objectFit: 'cover',
                                        borderRadius: '8px'
                                    }}
                                    preview={{
                                        width: 'auto',  // 프리뷰 모달의 너비
                                        height: 'auto', // 프리뷰 모달의 높이
                                        mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                    }}
                                />
                            ))}
                        </ImageGridContainer>
                    );
                }
                // 일반 텍스트인 경우
                return <div>{value}</div>;
            }
        }
    ];

    const charmDataSource = [
        {
            label: '사진&영상',
            value: data.userProfileImages
        },
        {
            label: '소개글',
            value: data.appealPoint
        }
    ];

    // 기본 정보 테이블 설정
    const basicColumns = [
        {
            title: '',
            dataIndex: 'label',
        },
        {
            title: '',
            dataIndex: 'value',
        }
    ];

    const basicDataSource = [
        {label: '나이', value: `${data.age}세`},
        {label: '키', value: `${data.height}cm`},
        {
            label: '외모', value: <Tag
                color={getTagColor(data.appearanceRating, 'APPEARANCE_RATING')}>외모 {data.appearanceRating}</Tag>
        },
        {
            label: '능력', value: <Tag
                color={getTagColor(data.overallSocialRating, 'OVERALL_SOCIAL_RATING')}>능력 {data.overallSocialRating}</Tag>
        },
        {label: '학력', value: <Tag color={getTagColor(data.univRating, 'UNIV_RATING')}>학력 {data.univRating}</Tag>},
        {label: '전화 번호', value: data.phoneNumber},
        {
            label: 'UID', value: <Space>
                {data.id}
                <Typography.Link
                    onClick={() => {
                        navigator.clipboard.writeText(data.id);
                        message.success('ID가 복사되었습니다');
                    }}
                >
                    <CopyOutlined/>
                </Typography.Link>
            </Space>
        },
    ];

    // 직업/학력 정보 테이블 설정
    const careerColumns = [
        {
            title: '',
            dataIndex: 'label',
        },
        {
            title: '',
            dataIndex: 'value',
        }
    ];

    const careerDataSource = [
        {
            label: '직업', value: (
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {data.careerSubGroup}
                    <div style={{
                        marginLeft: 'auto'  // 이 요소를 오른쪽 끝으로 밀어냅니다
                    }}
                    >
                        <InspectionLink
                            label="직업"
                            inspectionId={data.careerInspectionId}
                            inspectionImages={data.careerInspectionImages}
                        />
                    </div>
                </div>
            )
        },
        {label: '직장명', value: data.careerDetail},
        {
            label: '연봉', value:
                (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {data.income ?? "(연봉 미인증)"}
                        <div style={{
                            marginLeft: 'auto'  // 이 요소를 오른쪽 끝으로 밀어냅니다
                        }}
                        >
                            <InspectionLink
                                label="연봉"
                                inspectionId={data.incomeInspectionId}
                                inspectionImages={data.incomeInspectionImages}
                            />
                        </div>
                    </div>
                )
        },
        {
            label: '학교명', value: (
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {data.univName !== "" ? data.univName : "(학교 미인증)"}
                    <div style={{
                        marginLeft: 'auto'  // 이 요소를 오른쪽 끝으로 밀어냅니다
                    }}
                    >
                        <InspectionLink
                            label="학력"
                            inspectionId={data.univInspectionId}
                            inspectionImages={data.univInspectionImages}
                        />
                    </div>
                </div>
            )
        },
    ];

    // 성향 정보 테이블 설정
    const personalityColumns = [
        {
            title: '',
            dataIndex: 'label',
        },
        {
            title: '',
            dataIndex: 'value',
        }
    ];

    const personalityDataSource = [
        {label: '종교', value: data.religion},
        {label: '흡연', value: data.smokingStatus},
        {label: '음주', value: data.drinkingStatus},
    ];

    // 이상형 정보 테이블 설정
    const preferenceColumns = [
        {
            title: '',
            dataIndex: 'label',
        },
        {
            title: '',
            dataIndex: 'value',
        }
    ];

    const preferenceDataSource = [
        {
            label: '선호 타입', value: <Tag
                color={getTagColor(data.preferenceType, 'PREFERENCE_TYPE')}>{data.preferenceType}</Tag>
        },
        {
            label: '이성의 나이',
            value: `${Number(data?.age ?? 0) - Number(data?.partnerUnderAge ?? 0)}세 ~ ${Number(data?.age ?? 0) + Number(data?.partnerUpperAge ?? 0)}세`
        },
        {label: '이성의 키', value: `${data?.partnerHeightRange.at(0)}cm ~ ${data?.partnerHeightRange.at(-1)}cm`},
        {label: '이상의 종교', value: data.partnerReligion.length > 0 ? data.partnerReligion.join(', ') : '-'},
        {label: '이상의 음주', value: data.partnerDrinkingStatus ?? "-"},
        {label: '이성의 흡연', value: data.partnerSmokingStatus ?? "-"},
    ];

    return (
        <div>
            <Typography.Title level={2}>{data.name}</Typography.Title>

            {/* 매력 정보 테이블 */}
            <StyledTableWrapper style={{marginBottom: '16px'}}>
                <TableTitle>매력 정보</TableTitle>
                <InfoTable
                    columns={charmColumns}
                    dataSource={charmDataSource}
                    pagination={false}
                    showHeader={false}
                />
            </StyledTableWrapper>

            {/* 4개의 테이블을 그리드로 배치 */}
            <TableContainer>
                <StyledTableWrapper>
                    <TableTitle>기본 정보</TableTitle>
                    <InfoTable
                        columns={basicColumns}
                        dataSource={basicDataSource}
                        pagination={false}
                        showHeader={false}
                    />
                </StyledTableWrapper>
                <StyledTableWrapper>
                    <TableWrapper>
                        <TableTitle>직업/학력 정보</TableTitle>
                        <InfoTable
                            columns={careerColumns}
                            dataSource={careerDataSource}
                            pagination={false}
                            showHeader={false}
                        />
                    </TableWrapper>
                </StyledTableWrapper>
                <StyledTableWrapper>
                    <TableWrapper>
                        <TableTitle>성향 정보</TableTitle>
                        <InfoTable
                            columns={personalityColumns}
                            dataSource={personalityDataSource}
                            pagination={false}
                            showHeader={false}
                        />
                    </TableWrapper>
                </StyledTableWrapper>
                <StyledTableWrapper>
                    <TableWrapper>
                        <TableTitle>이상형 정보</TableTitle>
                        <InfoTable
                            columns={preferenceColumns}
                            dataSource={preferenceDataSource}
                            pagination={false}
                            showHeader={false}
                        />
                    </TableWrapper>
                </StyledTableWrapper>
            </TableContainer>
        </div>
    );
};

const ImageGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 16px;
    width: 100%;
`;


const TableWrapper = styled.div`
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const TableContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 16px;
`;

const StyledTableWrapper = styled.div`
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const TableTitle = styled.div`
    background-color: #6B7280; // 더 부드러운 회색으로 변경
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    height: 48px;
    margin: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
`;

const InfoTable = styled(Table)`
    .ant-table {
        background: transparent;
    }

    .ant-table-tbody {
        background: white;
    }

    .ant-table-tbody > tr > td {
        padding: 16px;
        border-bottom: 1px solid #F3F4F6; // 매우 연한 회색의 구분선 추가
        height: 52px;
        line-height: 1.5;
        font-size: 14px;
        background: white;
        vertical-align: middle;
    }

    .ant-table-tbody > tr:last-child > td {
        border-bottom: none; // 마지막 행의 구분선 제거
    }

    .ant-table-tbody > tr > td:first-child {
        color: #4B5563; // 왼쪽 레이블 색상
        width: 120px; // 레이블 영역 너비 확장
        padding-right: 16px;
        font-weight: normal;
        background: #F9FAFB; // 레이블 배경색 추가
    }

    .ant-table-tbody > tr > td:last-child {
        padding-left: 16px;
        color: #111827; // 값 텍스트 색상
    }
`;

export default AllUserProfileCard;