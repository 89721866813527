import apiClient from "../apiClient";
import { Pagination } from "../pagination";

export interface AllUsers {
  id: string;
  name: string;
  gender: "남자" | "여자";
  age: number;
  approvedAt: string;
  joinDateTime: string;
  height: number;
  partnerHeightRange: String[];
  partnerUnderAge: number;
  partnerUpperAge: number;
  userProfileImages: string[];
  userRepresentativeImages: string[];
  appearanceRating: string;
  overallSocialRating: string;
  careerDetail: string;
  careerSubGroup: string;
  careerType: string;
  univRating: string;
  univName: string;
  preferenceType: string;
  income: number;
  religion: string;
  smokingStatus: string;
  drinkingStatus: string;
  mbti: string;
  appealPoint: string;
  partnerSmokingStatus: string;
  partnerDrinkingStatus: string;
  partnerReligion: string[];
  userVideos: string[];
  userVideoThumbnails: string[];
  careerInspectionId: string;
  careerInspectionImages: string[];
  careerInspectionStatus: string;
  univInspectionId: string;
  univInspectionImages: string[];
  univInspectionStatus: string;
  incomeInspectionId: string;
  incomeInspectionImages: string[];
  incomeInspectionStatus: string;
  phoneNumber: string;
  requestTime: string;
  userStatus: string;
  finalAcademic: string;
}

export interface GetAllUsersResponse {
  status: string;
  data: {
    pagination: Pagination;
    contents: AllUsers[];
  };
}

interface GetAllUsersParameter {
  gender?: string;
  name?: string;
  page: number;
  size: number;
  overallSocialRating?: string;
  appearanceRating?: string;
  univRating?: string;
  preferenceType?: string;
  userId?: string;
  userStatus?: string;
}

export const getAllUsers = async (
  params: GetAllUsersParameter
): Promise<GetAllUsersResponse> => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const response = await apiClient.get<GetAllUsersResponse>(`/v1/admin/users`, {
    params: filteredParams,
  });

  return response.data;
};
