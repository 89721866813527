// modals/InspectionModal.tsx 파일 수정
import React from "react";
import { Button, Modal } from "antd";

interface TestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: (data: string) => void;
  onReject: (data: string) => void;
}

export const TestModal: React.FC<TestModalProps> = ({
  isOpen,
  onClose,
  onApprove,
  onReject,
}) => {
  return (
    <Modal
      title={`인증 확인`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        // 닫기 버튼
        <Button key="close" onClick={onClose}>
          취소
        </Button>,
        // 반려 버튼
        onReject && (
          <Button
            key="reject"
            type="primary"
            danger
            onClick={() => {
              if (onReject) {
                onReject("반려요~");
                onClose(); // 모달 닫기
              }
            }}
          >
            반려
          </Button>
        ),
        // 승인 버튼
        onApprove && (
          <Button
            key="approve"
            type="primary"
            onClick={() => {
              if (onApprove) {
                onApprove("승인이요~");
                onClose(); // 모달 닫기
              }
            }}
          >
            승인
          </Button>
        ),
      ]}
      width={800}
    >
      <div style={{ marginBottom: 16 }}></div>
    </Modal>
  );
};
