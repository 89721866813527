import React, { useCallback, useEffect, useState } from "react";
import { Card, Select, Space, Table, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import MatchLayout from "../../common/components/AdminLayout";
import ProfileDisplay from "../recommend/components/ProfileDisplay";
import RecommendationGlobalTableFilter from "../recommend/components/RecommendationGlobalTableFilter";
import {
  calculateColumnsTotalWidth,
  FILTER_OPTIONS,
  formatKoreanMoney,
  getTagColor,
  PreferenceOption,
} from "../recommend/RecommendationList";
import {
  AllUsers,
  getAllUsers,
  GetAllUsersResponse,
} from "../../utils/api/users/users";
import { Pagination } from "../../utils/api/pagination";
import { ApiError } from "../../utils/api/apiError";
import { useQuery } from "@tanstack/react-query";
import { InspectionBadgeStatusDisplay } from "../inspection/components/badge/InspectionBadgeStatusDisplay";

export interface AllUsersQueryResult {
  contents: AllUsers[];
  pagination: Pagination;
}

const UsersHome: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // URL에서 초기 필터 값을 가져오는 함수
  const getInitialFilter = useCallback(() => {
    return {
      gender: searchParams.get("gender") || "",
      name: searchParams.get("name") || "",
      page: parseInt(searchParams.get("page") || "0"),
      size: parseInt(searchParams.get("size") || "10"),
      overallSocialRating: searchParams.get("overallSocialRating") || "",
      appearanceRating: searchParams.get("appearanceRating") || "",
      univRating: searchParams.get("univRating") || "",
      preferenceType: searchParams.get("preferenceType") || "",
      userId: searchParams.get("userId") || "",
      userStatus: searchParams.get("userStatus") || "",
    };
  }, [searchParams]);

  const [filter, setFilter] = useState(getInitialFilter());

  // 필터 변경시 URL 업데이트
  const updateURLParameters = (newFilter: typeof filter) => {
    const params = new URLSearchParams();
    Object.entries(newFilter).forEach(([key, value]) => {
      if (value !== "") {
        params.set(key, value.toString());
      }
    });
    setSearchParams(params);
  };

  const handleFilterChange = (values: Partial<typeof filter>) => {
    const newFilter = {
      ...filter,
      ...values,
      page: 0, // 필터 변경 시 첫 페이지로 이동
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const newFilter = {
      ...filter,
      page: page - 1,
      size: pageSize || filter.size,
    };
    setFilter(newFilter);
    updateURLParameters(newFilter);
  };

  const handleReset = () => {
    const resetFilter = {
      gender: "",
      name: "",
      page: 0,
      size: 10,
      overallSocialRating: "",
      appearanceRating: "",
      univRating: "",
      preferenceType: "",
      userId: "",
      userStatus: "",
    };
    setFilter(resetFilter);
    // URL 파라미터 초기화
    setSearchParams({});
  };

  // 인증 상태를 관리할 상태 객체 정의
  const [inspectionStatuses] = useState<
    Record<string, Record<string, string | null>>
  >({});

  // URL 변경 감지하여 필터 상태 업데이트
  useEffect(() => {
    const currentFilter = getInitialFilter();
    setFilter(currentFilter);
  }, [getInitialFilter]);

  // 쿼리 훅 정의
  const { data: allUsersQueryResult } = useQuery<
    GetAllUsersResponse,
    ApiError,
    AllUsersQueryResult
  >({
    // 쿼리 키를 객체 형태로 구조화
    queryKey: ["all-inspections", filter],
    queryFn: () =>
      getAllUsers({
        ...filter, // 객체로 전달
      }),
    // 데이터 변환
    select: (response) => ({
      contents: response.data.contents,
      pagination: response.data.pagination,
    }),
  });

  const handleRowClick = (record: AllUsers) => {
    navigate(`/users/${record.id}`);
  };

  // // 2025.02.13  18:32 형식으로 변환
  // const parseDateTime = (dateString: string) => {
  //   // 2025-01-21T17:37:25.000
  //   const year = dateString.substring(0, 4);
  //   const month = dateString.substring(5, 7);
  //   const day = dateString.substring(8, 10);
  //   const hour = dateString.substring(11, 13);
  //   const minute = dateString.substring(14, 16);
  //
  //   return `${year}.${month}.${day} ${hour}:${minute}`;
  // };
  // 2025.02.13  18:32 형식으로 변환
  const parseDateTime = (dateString: string | null | undefined) => {
    // 날짜가 null이거나 undefined인 경우 처리
    if (!dateString) {
      return '날짜 정보 없음'; // 또는 다른 기본값 반환
    }

    try {
      // 2025-01-21T17:37:25.000
      const year = dateString.substring(0, 4);
      const month = dateString.substring(5, 7);
      const day = dateString.substring(8, 10);
      const hour = dateString.substring(11, 13);
      const minute = dateString.substring(14, 16);

      return `${year}.${month}.${day} ${hour}:${minute}`;
    } catch (error) {
      console.error('날짜 파싱 오류:', error, '입력값:', dateString);
      return '날짜 형식 오류'; // 또는 다른 오류 메시지 반환
    }
  };

  const columns: ColumnsType<AllUsers> = [
    {
      title: "회원 / 가입 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>회원 상태</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 120 }}
                  size="small"
                  value={filter.userStatus}
                  onChange={(value) =>
                    handleFilterChange({ userStatus: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.USER_STATUS).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "profile",
          width: 100,
          align: "center",
          render: (_, record) => (
            <Tag
              // 컬러는  FILTER_OPTIONS.USER_STATUS 마다 다르게
              color={
                FILTER_OPTIONS.USER_STATUS[
                  record.userStatus as keyof typeof FILTER_OPTIONS.USER_STATUS
                ]?.color
              }
            >
              {
                FILTER_OPTIONS.USER_STATUS[
                  record.userStatus as keyof typeof FILTER_OPTIONS.USER_STATUS
                ]?.label
              }
            </Tag>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>가입 일시 / 승인 일시</TitleWrapper>
            </div>
          ),
          key: "profile",
          width: 150,
          align: "center",
          render: (_, record) => (
            <div>
              <div>{parseDateTime(record.joinDateTime)} 가입</div>
              <div>{parseDateTime(record.approvedAt)} 승인 </div>
            </div>
          ),
        },
      ],
    },
    {
      title: "매력 정보/기본 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>프로필/외모</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    handleFilterChange({ appearanceRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "profile",
          width: 200,
          align: "center",
          render: (_, record) => (
            <div onClick={(e) => e.stopPropagation()}>
              <ProfileDisplay
                representationImages={record.userRepresentativeImages}
                profileImage={record.userProfileImages}
                videos={record.userVideos}
                videoThumbnails={record.userVideoThumbnails}
                appearanceRating={record.appearanceRating}
              />
            </div>
          ),
        },
        {
          title: () => (
            <div>
              <TitleWrapper>이름 / 성별 / 나이 / 키</TitleWrapper>
              <Space>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  value={filter.gender}
                  onChange={(value) => handleFilterChange({ gender: value })}
                >
                  {Object.values(FILTER_OPTIONS.GENDER).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "basic info",
          width: 80,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.name}({record.gender === "남자" ? "남" : "여"})
              </div>
              <div>{record.age}세</div>
              <div>{record.height}cm</div>
            </Space>
          ),
        },
      ],
    },
    {
      title: "능력 정보",
      align: "center",
      width: 80,
      children: [
        {
          title: () => (
            <div>
              <TitleWrapper>직업</TitleWrapper>
              <Space>
                <Select
                  placeholder="능력"
                  size="small"
                  value={filter.overallSocialRating}
                  onChange={(value) =>
                    handleFilterChange({ overallSocialRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "career",
          width: 80,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag
                color={getTagColor(
                  record.overallSocialRating,
                  "OVERALL_SOCIAL_RATING"
                )}
              >
                능력 {record.overallSocialRating}
              </Tag>
              <div>{record.careerDetail}</div>
              <div>{record.careerSubGroup}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>연봉</div>
            </div>
          ),
          key: "career",
          width: 80,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <div>
                {record.income != null ? formatKoreanMoney(record.income) : "-"}
              </div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>학력</div>
              <Space>
                <Select
                  placeholder="학력"
                  size="small"
                  style={{ width: 80 }}
                  value={filter.univRating}
                  onChange={(value) =>
                    handleFilterChange({ univRating: value })
                  }
                >
                  {Object.values(FILTER_OPTIONS.UNIV_RATING).map(
                    ({ value, label, description }: PreferenceOption) => (
                      <Select.Option key={value} value={value}>
                        <div className="select-option-content">
                          {description ? (
                            <Tooltip title={description} placement="right">
                              <div>{label}</div>
                            </Tooltip>
                          ) : (
                            <div>{label}</div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Space>
            </div>
          ),
          key: "career",
          width: 80,
          align: "center",
          render: (_, record) => (
            <Space direction="vertical">
              <Tag color={getTagColor(record.univRating, "UNIV_RATING")}>
                학력 {record.univRating}
              </Tag>
              <div>{record.univName ?
                  record.univName :
                  (record.finalAcademic ? record.finalAcademic : "-")}</div>
            </Space>
          ),
        },
        {
          title: () => (
            <div>
              <div>인증 정보</div>
            </div>
          ),
          key: "career",
          width: 160,
          align: "center",
          render: (_, record) => {
            // 현재 사용자의 인증 뱃지 상태를 가져옴 (없으면 빈 객체)
            const userStatuses = inspectionStatuses[record.id] || {};

            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Space direction="vertical">
                  <InspectionBadgeStatusDisplay
                    label="직업"
                    inspectionId={record.careerInspectionId}
                    status={
                      userStatuses["career"] || record.careerInspectionStatus
                    }
                    inspectionImages={record.careerInspectionImages}
                  />
                  <InspectionBadgeStatusDisplay
                    label="학력"
                    inspectionId={record.univInspectionId}
                    status={userStatuses["univ"] || record.univInspectionStatus}
                    inspectionImages={record.univInspectionImages}
                  />
                  <InspectionBadgeStatusDisplay
                    label="연봉"
                    inspectionId={record.incomeInspectionId}
                    status={
                      userStatuses["income"] || record.incomeInspectionStatus
                    }
                    inspectionImages={record.incomeInspectionImages}
                  />
                </Space>
              </div>
            );
          },
        },
      ],
    },

    {
      title: "이상형 정보",
      align: "center",
      children: [
        {
          title: () => (
            <div>
              <div>이상형 정보</div>
              <Select
                placeholder="유저 타입"
                size="small"
                style={{ width: 120 }}
                value={filter.preferenceType}
                onChange={(value) =>
                  handleFilterChange({ preferenceType: value })
                }
              >
                {Object.values(FILTER_OPTIONS.PREFERENCE_TYPE).map(
                  ({ value, label, description }: PreferenceOption) => (
                    <Select.Option key={value} value={value}>
                      <div className="select-option-content">
                        {description ? (
                          <Tooltip title={description} placement="right">
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          <div>{label}</div>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          ),
          key: "preferenceType",
          align: "center",
          width: 200,
          render: (_, record) => (
            <Space direction="vertical">
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>타입</b>
                </div>
                <Tag
                  color={getTagColor(record.preferenceType, "PREFERENCE_TYPE")}
                >
                  {record.preferenceType}
                </Tag>
              </div>
              <div style={{ display: "flex", marginBottom: "4px" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>나이</b>
                </div>
                {Number(record.age) - Number(record.partnerUnderAge)} ~{" "}
                {Number(record.age) + Number(record.partnerUpperAge)}세
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40px", marginRight: "8px" }}>
                  <b>키</b>
                </div>
                {record.partnerHeightRange[0].includes("-")
                  ? `${record.partnerHeightRange[0].replace("-", "")}`
                  : `${record.partnerHeightRange[0]}`}{" "}
                ~{" "}
                {record.partnerHeightRange[1].includes("+")
                  ? `${record.partnerHeightRange[1].replace("+", "")}cm`
                  : `${record.partnerHeightRange[1]}cm`}
              </div>
            </Space>
          ),
        },
      ],
    },
  ];
  return (
    <MatchLayout>
      <StyledCard
        title={
          <Space
            size="middle"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <Space
              size="small"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "22px" }}>전체 회원 조회</span>
              <span style={{ fontSize: "22px", color: "#618DEF" }}>
                현재 {allUsersQueryResult?.pagination.totalElements}명
              </span>
            </Space>
            <RecommendationGlobalTableFilter
              filter={filter}
              onFilterChange={handleFilterChange}
              onReset={handleReset}
            />
          </Space>
        }
      >
        <Table
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // 클릭 이벤트
            style: { cursor: "pointer" }, // 커서 스타일
          })}
          columns={columns}
          dataSource={allUsersQueryResult?.contents || []}
          scroll={{
            x: calculateColumnsTotalWidth(columns),
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            current: filter.page + 1,
            pageSize: filter.size,
            total: allUsersQueryResult?.pagination.totalElements || 0,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showSizeChanger: true,
            showQuickJumper: true,
            style: { margin: "16px 0" }, // 페이지네이션 여백 추가
          }}
          bordered={true}
          style={
            {
              "--ant-table-border-color": "#f0f0f0", // 테두리 색상 변경
            } as React.CSSProperties
          }
        />
      </StyledCard>
    </MatchLayout>
  );
};

const StyledCard = styled(Card)`
  .ant-table-thead > tr:first-child > th {
    background-color: #97b4f4 !important;
  }

  .ant-table-thead > tr:nth-child(2) > th {
    background-color: #cbdafa !important;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default UsersHome;
