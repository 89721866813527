import React from 'react';
import {Card, Space} from 'antd';
import 'dayjs/locale/ko';
import styled from "@emotion/styled";
import MatchLayout from "../../common/components/AdminLayout";
import {Link} from 'react-router-dom';

const InspectionsMain = () => {
    return (
        <MatchLayout>
            <StyledCard
                title={
                    <Space size="middle" style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '16px 24px'
                    }}>
                        <Space size="small" style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: '32px'}}>회원 승인·심사</span>
                        </Space>
                    </Space>
                }
            >
                <div style={{
                    padding: '20px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <StyledLink to="/inspections/signup">
                        가입 승인 여부 심사하기
                    </StyledLink>
                    <StyledLink to="/inspections/badges">
                        가입 후의 인증 뱃지 심사하기
                    </StyledLink>
                    <StyledLink to="/inspections/images">
                        사진 수정 요청 심사하기
                    </StyledLink>
                </div>
            </StyledCard>
        </MatchLayout>
    );
};

export default InspectionsMain;

// Card 컴포넌트가 남은 공간을 모두 차지하도록 스타일 정의
const StyledCard = styled(Card)`
    width: 100%;
    min-height: 100vh;
`;

const StyledLink = styled(Link)`
    color: #2D67C4;
    font-size: 20px;
    font-style: normal;
    line-height: 130%; /* 26px */
    letter-spacing: -0.2px;

    &:hover {
        text-decoration: underline;
    }
`;